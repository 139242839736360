import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { spidprovider } from '../comuni/funzioni';

class AutorizzazioniLogin extends Component {

    constructor(props) {

        super(props);

        this.state = {
            csspulsanteEntra: "",
            style: "",
        };
    }

    ApriChiudiBottone = () => {

        var valoreclasse = this.state.csspulsanteEntra;

        var cssContProvider = "";

        if (valoreclasse === "") {
            valoreclasse = "spid-idp-button-open";
            cssContProvider = "d-block";
        }
        else {
            valoreclasse = "";
            cssContProvider = "none";
        }

        this.setState({
            csspulsanteEntra: valoreclasse,
            cssContProvider: cssContProvider,
        });
    }

    // #region Eventi



    // #endregion


    // #region  Render
    render() {

        const handlereaprichiudibottone = () => {
            this.ApriChiudiBottone();
        };

        var provider = spidprovider();


        return (
            <div id="ArchivioContenuti" style={{ textAlign: "center" }} className="py-5">

                <div className="py-5 testoIntroduttivo">
                    <h2> ATTENZIONE </h2>
                    <p>
                        <strong>   L’iscrizione al portale:
                            <ul>
                                <li>NON sostituisce né è alternativa all’iscrizione al Registro Unico Nazionale del Terzo Settore (RUNTS)</li>
                                {/* <li>MA è condizione necessaria per l'erogazione di contributi e la concessione di spazi da parte del Comune di Pisa</li> */}
                                <li><a href="/servizi/funzioni/download.aspx?ID=2125&IDc=784">Scarica il modulo per la richiesta di iscrizione</a> e invialo a <a href="mailto:portale.cultura@comune.pisa.it">portale.cultura@comune.pisa.it</a></li>
                            </ul>
                        </strong>
                    </p>

                </div>




                <button onClick={handlereaprichiudibottone} className={"italia-it-button italia-it-button-size-m button-spid " + this.state.csspulsanteEntra}
                    spid-idp-button="#spid-idp-button-medium-get" aria-haspopup="true" aria-expanded="false"
                    title="Entra con SPID">
                    <span className="italia-it-button-icon">
                        <img src="../../immagini/spid/spid-ico-circle-bb.svg" alt="" /></span>
                    <span className="italia-it-button-text">Entra con SPID</span>
                </button>
                <div id="spid-idp-button-medium-get" style={{ textAlign: "center", margin: "0 auto", position: 'relative' }} className={"spid-idp-button spid-idp-button-tip spid-idp-button-relative " + this.state.cssContProvider}>
                    <ul id="spid-idp-list-medium-root-get" className="spid-idp-button-menu" aria-labelledby="spid-idp">
                        {
                            provider
                        }
                    </ul>
                </div>  </div >
        );
    }
    // #endregion

    // #region Ciclo di vita del container
    /// Metodi del ciclo di vita del container
    componentDidMount = () => {
    }

    componentDidUpdate = (propsPrecedenti, statePrecedente) => {
    }
    // #endregion

    setRichiedentiResultsEventiAttivi = (data, countdatidagraficareEventiAttivi, paginaCorrenteEventiAttivi) => {
        /*Positivo*/
    }



    setErrorMessageEventiAttivi = (errorMessage) => {
        /*Negativo*/
    }


}
// #endregion

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(AutorizzazioniLogin);
