import React, { Component } from 'react';
import Spinner from '../components/Spinner';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import Paginazione from '../components/Paginazione';
import { Table } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Row, Button, Col } from 'react-bootstrap';
import { Form, InputGroup } from "react-html5-form";
import { GoogleApiWrapper } from 'google-maps-react';
import { validfilemp3, validfilemp4, validfilevtt } from '../comuni/regex.js';
import { getBase64key } from '../comuni/funzioni';

class VideoAllegatiRicercaRecordAdmin extends Component {

    constructor(props) {

        super(props);

        this.state = {
            formreadonly: false,
            idContenuto: 0,
            actions: "",
            idContenutoSelezionato: 0,

            nometabellacontenuto: 'T_galleriaaudiovideo',

            risultatiRicercaContenuti: null,
            risultatiPerPaginaContenuti: 6,
            queryOffsetContenuti: 0,
            paginacorrenteAssocizioni: 0,
            ricercaEseguitaContenuti: false,
            messaggioErrore: null,
            countdatidagraficareContenuti: null,

            //#region campifile

            file_campo_immagine_video: {
                nome: null,
                base64: null,
                inviato: false,
                errore: false,
                nometestualecampo: "Video allegato",
                link: "",
                dimensione: null
            },

            file_campo_audio_desc: {
                nome: null,
                base64: null,
                inviato: false,
                errore: false,
                nometestualecampo: "Audio descrittivo dell video allegato",
                link: "",
                dimensione: null
            },

            file_campo_videodownload: {
                nome: null,
                base64: null,
                inviato: false,
                errore: false,
                nometestualecampo: "Video allegato",
                link: "",
                dimensione: null
            },

            file_campo_audio: {
                nome: null,
                base64: null,
                inviato: false,
                errore: false,
                nometestualecampo: "Video allegato",
                link: "",
                dimensione: null
            },

            file_campo_sottotitoli: {
                nome: null,
                base64: null,
                inviato: false,
                errore: false,
                nometestualecampo: "Video allegato",
                link: "",
                dimensione: null
            },

            //#endregion

            //#region campi      

            campo_Titolo: {
                nomecampo: "Titolo",
                campovalore: null,
            },

            campo_trascrizione: {
                nomecampo: "trascrizione",
                campovalore: null,
            },

            campo_Descrizione: {
                nomecampo: "Descrizione",
                campovalore: null,
            },

            campo_Ordinamento: {
                nomecampo: "ordinamento",
                campovalore: null,
            },

            //#endregion 

            //#region Gestione stato per ricerca Contenuto      
            risultatiRicercaContenuto: null,
            risultatiPerPaginaContenuto: 6,
            queryOffsetContenuto: 0,
            ricercaEseguitaContenuto: false,
            messaggioErroreContenuto: null,
            countdatidagraficareContenuto: null,
            //#endregion

            impostanuovoinserimento: false,

            messaggioAggiornamento: null,

            testopulsante: "Inserisci allegato",


        };
    }

    //#region HandlerCambiamentiAllegati


    AllegatiCambiatoHandler = (event, inputGroup, key, state) => {
        if (event.target.files[0] !== null) {
            getBase64key(event.target.files[0], this.AllegatiCambiatoBase64Handler,
                event.target.files[0].name, inputGroup, key, state, event.target.files[0].size);
        }
        else {
            state[key].nome = null;
            state[key].base64 = null;
            state[key].dimensione = null;
            inputGroup.checkValidityAndUpdate();
        }

    }

    AllegatiCambiatoBase64Handler = (base64, nome, inputGroup, key, state, size) => {
        state[key].nome = nome;
        state[key].base64 = base64;
        state[key].dimensione = size;
        inputGroup.checkValidityAndUpdate();
    }


    //#endregion

    //#region HandlerCambiamenti

    campiCambiatoHandler = (event, key, state) => {
        state[key].campovalore = event.target.value;
    }

    //#endregion

    //#region HandlerCambiamentiCheckbox

    CheckboxCambiatoHandler = (event, key, state) => {
        state[key].campovalore = event.target.checked;
    }

    //#endregion

    // #region cambiamenti di pagina o redirect

    cambiaPaginaHandlerContenuti = (numeropaginacliccata) => {
        this.setState({
            paginacorrenteAssocizioni: numeropaginacliccata,
            queryOffsetContenuti: (numeropaginacliccata - 1) * this.state.risultatiPerPaginaContenuti
        });
    }

    VaiAModificaContenuto = (id) => {
        this.setState({
            actions: "aggiornamento",
        }, function () {
            this.VaiAModificaContenutoAggiornamento(id);
        });
    }

    VaiAModificaContenutoAggiornamento = (id) => {

        var ricercaEseguitaContenuto = false;

        if (parseInt(id) === parseInt(this.state.idContenutoSelezionato)) {
            ricercaEseguitaContenuto = true;
        }

        this.setState({
            actions: "modifica",
            ricercaEseguitaContenuto: ricercaEseguitaContenuto,
            idContenutoSelezionato: id,
            impostanuovoinserimento: false,
        });
    }

    VaiAEliminaContenuto = (id) => {
        this.setState({
            actions: "elimina",
            idContenutoSelezionato: id,
            ricercaEseguitaContenuto: false,
            impostanuovoinserimento: false,
        });
    }

    VaiAInserisci = () => {
        this.setState({
            actions: "aggiornamento",
            idContenutoSelezionato: 0,
        }, function () {
            this.VaiAInserisciAggiornamento();
        });
    }

    VaiAInserisciAggiornamento = () => {
        this.setState({
            actions: "inserisci",
            idContenutoSelezionato: 0,
            impostanuovoinserimento: false,
            ricercaEseguitaContenuto: false,
            formreadonly: false,
            messaggioAggiornamento: false,
        });
    }

    ChiudiFinestra = () => {
        this.setState({
            actions: "",
            idContenutoSelezionato: 0,
        });
    }


    // #endregion


    // #region  Render
    render() {

        const handleSubmita = (form) => {

            var validataform = form.checkValidity();

            if (validataform === false) {
            }
            else {
                this.setState({
                    formreadonly: true,
                });

                this.gestiscicoordinate(this.AggiornamentoDBCorretto, this.AggiornamentoDBSbagliato,
                    this.AggiornamentoDBCorretto, this.errorDocumenti, this.AggiornamentoCompletato, this.state, this.creazioneContenuto, this.gestiscicoordinate, null, null, "");
            }
        };

        const onInput = (e, inputGroup) => {
            inputGroup.checkValidityAndUpdate();
        };

        const deleteItem = (e) => {
            e.preventDefault();
            this.gestiscicoordinate(this.AggiornamentoDBCorretto, this.AggiornamentoDBSbagliato,
                this.AggiornamentoDBCorretto, this.errorDocumenti, this.AggiornamentoCompletato, this.state, this.creazioneContenuto, this.gestiscicoordinate, null, null, "");
        };

        const deleteItemNo = (e) => {
            e.preventDefault();
        };

        const deleteFile = (e, chiave) => {
            e.preventDefault();
            this.EliminazioneFile(this.ContenutoEliminatoOK, this.ContenutoEliminatoKO,
                this.state, chiave);
        };

        const deleteFileNO = (e) => {
            e.preventDefault();
        };

        const chiudifinestraItem = (e) => {
            e.preventDefault();
            this.ChiudiFinestra();
        };

        const InserimentoContenutoClick = (e) => {
            /* call this if you want to prevent default behavior, that is transition to fire */
            e.preventDefault();
            this.VaiAInserisci();
        }


        return (
            <div>
                <div>
                    <div className="row">
                        <div className="col-lg-12 px-lg-2 py-lg-2">
                            <div className="row">
                                <div className="col">
                                    <h3 className="h2">GALLERIA AUDIO/VIDEO</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(this.state.actions !== "" && ((this.state.idContenutoSelezionato !== 0 &&
                    this.state.ricercaEseguitaContenuto === true) || (this.state.idContenutoSelezionato === 0 && this.state.actions === "inserisci" &&
                        this.state.impostanuovoinserimento === true)))
                    ?
                    <Form readOnly onSubmit={handleSubmita} >
                        {({ error, valid, pristine, submitting, form }) => (
                            <>
                                {error && (<div className="alert alert-danger mx-0" role="alert">
                                    <strong>Errori durante la compilazione del dato</strong> {error}
                                </div>)
                                }
                                <Row className="my-2"><Col>
                                    <h3 className="h1">Video/Audio</h3>
                                </Col></Row>
                                {this.state.messaggioAggiornamento}
                                <InputGroup className="pb-3 clearfix "
                                    validate={["Titolo"]}
                                    translate={{
                                        Titolo: {
                                            valueMissing: "Questo campo è obbligatorio",
                                            patternMismatch: "Questo campo è obbligatorio"
                                        }
                                    }}>
                                    {({ error, valid, inputGroup }) => (
                                        <div className="form-group pb-3 clearfix ">
                                            <label htmlFor="TitoloInputVideo">Titolo video *</label>
                                            <input
                                                readOnly={this.state.formreadonly}
                                                required={true}
                                                className={`form-control ${!valid && "is-invalid"}`}
                                                id="TitoloInputVideo"
                                                name="Titolo"
                                                defaultValue={this.state.campo_Titolo.campovalore}
                                                onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Titolo", this.state); }}
                                                onInput={(e) => onInput(e, inputGroup, form)}
                                                autoComplete="off"
                                                aria-label="Inserisci il titolo del video"
                                                placeholder="Inserisci il titolo del video" />
                                            {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                                        </div>
                                    )}
                                </InputGroup>
                                <InputGroup className="pb-3 clearfix "
                                    validate={["Descrizione"]}
                                    translate={{
                                        Descrizione: {
                                            valueMissing: "Questo campo è obbligatorio",
                                            patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                                            tooShort: "Il campo deve contenere almeno 20 caratteri"
                                        }
                                    }}>
                                    {({ error, valid, inputGroup }) => (
                                        <div className="form-group pb-3 clearfix ">
                                            <label htmlFor="DescrizioneInputVideo">Descrizione *</label>
                                            <textarea
                                                readOnly={this.state.formreadonly}
                                                required={true}
                                                className={`form-control ${!valid && "is-invalid"}`}
                                                id="DescrizioneInputVideo"
                                                defaultValue={this.state.campo_Descrizione.campovalore}
                                                name="Descrizione"
                                                onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Descrizione", this.state); }}
                                                onInput={(e) => onInput(e, inputGroup, form)}
                                                autoComplete="off"
                                                aria-label="Inserisci una breve Descrizione del video"
                                                placeholder="Inserisci una breve Descrizione del video" minLength={20} maxLength={100} />

                                            {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                                        </div>
                                    )}
                                </InputGroup>
                                <InputGroup className="pb-3 clearfix "
                                    validate={["Ordinamento"]}
                                    translate={{
                                        Ordinamento: {
                                            valueMissing: "Questo campo è obbligatorio",
                                            patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                                        }
                                    }}>
                                    {({ error, valid, inputGroup }) => (
                                        <div className="form-group pb-3 clearfix ">
                                            <label htmlFor="OrdinamentoInputVideo">Ordinamento *</label>
                                            <input
                                                readOnly={this.state.formreadonly}
                                                className={`form-control ${!valid && "is-invalid"}`}
                                                required={true}
                                                id="OrdinamentoInputVideo"
                                                defaultValue={this.state.campo_Ordinamento.campovalore}
                                                name="Ordinamento"
                                                type="number"
                                                onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Ordinamento", this.state); }}
                                                onInput={(e) => onInput(e, inputGroup, form)}
                                                autoComplete="off"
                                                aria-label="Inserisci l'ordinamento, definirà come si visualizzeranno i video lato utente"
                                                placeholder="Inserisci l'ordinamento, definirà come si visualizzeranno i video lato utente" />

                                            {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                                        </div>
                                    )}
                                </InputGroup>
                                <InputGroup className="pb-3 clearfix "
                                    validate={{
                                        "allegato_videodownload": (input) => {

                                            if (this.state.file_campo_videodownload.nome === null || this.state.file_campo_videodownload.nome === "") {
                                                return true;
                                            }
                                            else {
                                                if (!validfilemp4.test(this.state.file_campo_videodownload.nome)) {
                                                    input.setCustomValidity("Il file caricato deve essere un mp4");
                                                    return false;
                                                } else {
                                                    if (this.state.file_campo_videodownload.dimensione > 100000 * 1024) {
                                                        input.setCustomValidity("Il file caricato non deve superare i 100 MB");
                                                        return false;
                                                    }
                                                    else {
                                                        return true;
                                                    }
                                                }
                                            }

                                        }
                                    }}
                                    translate={{
                                        allegato_videodownload: {
                                            valueMissing: "Questo campo è obbligatorio",
                                            patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                                        }
                                    }}>
                                    {({ error, valid, inputGroup }) => (
                                        <div className="form-group pb-3 clearfix ">
                                            <label htmlFor="allegato_videodownloadInputVideo">Video</label>
                                            <input
                                                disabled={this.state.formreadonly}
                                                className={`form-control-file ${!valid && "is-invalid"}`}
                                                id="allegato_videodownloadInputVideo"
                                                name="allegato_videodownload"
                                                onChange={(event) => { this.AllegatiCambiatoHandler(event, inputGroup, "file_campo_videodownload", this.state); }}
                                                placeholder="Inserisci un video"
                                                aria-label="Inserisci un video"
                                                accept=".mp4"
                                                type="file" />
                                            {
                                                this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                                                && this.state.file_campo_videodownload.link !== ""
                                                && (<div className="mx-0">{"Il file precedentemente caricato risulta essere "} <a target="_blank" rel="noreferrer" title={this.state.file_campo_videodownload.nometestualecampo + " " + this.state.file_campo_videodownload.link} aria-label={this.state.file_campo_videodownload.nometestualecampo + this.state.file_campo_videodownload.link} href={this.state.file_campo_videodownload.link.toString().replace('../../', '').replaceAll('&amp;', '&')}>Scarica file</a></div>)
                                            }
                                            {
                                                this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                                                && this.state.file_campo_videodownload.link !== ""
                                                && (<Button type="submit" onClick={(e) => { if (window.confirm('Sei sicuro di voler eliminare questo record?')) { deleteFile(e, "file_campo_videodownload") } else { deleteFileNO(e) } }}>
                                                    Elimina File
                                                </Button>)
                                            }
                                            {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                                        </div>
                                    )}
                                </InputGroup>
                                <InputGroup className="pb-3 clearfix "
                                    validate={{
                                        "allegato_audio": (input) => {

                                            if (this.state.file_campo_audio.nome === null || this.state.file_campo_audio.nome === "") {
                                                return true;
                                            }
                                            else {
                                                if (!validfilemp3.test(this.state.file_campo_audio.nome)) {
                                                    input.setCustomValidity("Il file caricato deve essere un mp3");
                                                    return false;
                                                } else {
                                                    if (this.state.file_campo_audio.dimensione > 100000 * 1024) {
                                                        input.setCustomValidity("Il file caricato non deve superare i 100 MB");
                                                        return false;
                                                    }
                                                    else {
                                                        return true;
                                                    }
                                                }
                                            }

                                        }
                                    }}
                                    translate={{
                                        allegato_audio: {
                                            valueMissing: "Questo campo è obbligatorio",
                                            patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                                        }
                                    }}>
                                    {({ error, valid, inputGroup }) => (
                                        <div className="form-group pb-3 clearfix ">
                                            <label htmlFor="allegato_audioInputVideo">Audio</label>
                                            <input
                                                disabled={this.state.formreadonly}
                                                className={`form-control-file ${!valid && "is-invalid"}`}
                                                id="allegato_audioInputVideo"
                                                name="allegato_audio"
                                                onChange={(event) => { this.AllegatiCambiatoHandler(event, inputGroup, "file_campo_audio", this.state); }}
                                                placeholder="Inserisci un audio"
                                                aria-label="Inserisci un audio"
                                                accept=".mp3"
                                                type="file" />
                                            {
                                                this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                                                && this.state.file_campo_audio.link !== ""
                                                && (<div className="mx-0">{"Il file precedentemente caricato risulta essere "} <a target="_blank" rel="noreferrer" title={this.state.file_campo_audio.nometestualecampo + " " + this.state.file_campo_audio.link} aria-label={this.state.file_campo_audio.nometestualecampo + this.state.file_campo_audio.link} href={this.state.file_campo_audio.link.toString().replace('../../', '').replaceAll('&amp;', '&')}>Scarica file</a></div>)
                                            }
                                            {
                                                this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                                                && this.state.file_campo_audio.link !== ""
                                                && (<Button type="submit" onClick={(e) => { if (window.confirm('Sei sicuro di voler eliminare questo record?')) { deleteFile(e, "file_campo_audio") } else { deleteFileNO(e) } }}>
                                                    Elimina File
                                                </Button>)
                                            }
                                            {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                                        </div>
                                    )}
                                </InputGroup>
                                <InputGroup className="pb-3 clearfix "
                                    validate={{
                                        "allegato_audio_desc": (input) => {

                                            if (this.state.file_campo_audio_desc.nome === null || this.state.file_campo_audio_desc.nome === "") {
                                                return true;
                                            }
                                            else {
                                                if (!validfilemp3.test(this.state.file_campo_audio_desc.nome)) {
                                                    input.setCustomValidity("Il file caricato deve essere un mp3");
                                                    return false;
                                                } else {
                                                    if (this.state.file_campo_audio_desc.dimensione > 100000 * 1024) {
                                                        input.setCustomValidity("Il file caricato non deve superare i 100 MB");
                                                        return false;
                                                    }
                                                    else {
                                                        return true;
                                                    }
                                                }
                                            }

                                        }
                                    }}
                                    translate={{
                                        allegato_audio_desc: {
                                            valueMissing: "Questo campo è obbligatorio",
                                            patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                                        }
                                    }}>
                                    {({ error, valid, inputGroup }) => (
                                        <div className="form-group pb-3 clearfix ">
                                            <label htmlFor="allegato_audio_descInputVideo">Audio Descrittivo del video</label>
                                            <input
                                                disabled={this.state.formreadonly}
                                                className={`form-control-file ${!valid && "is-invalid"}`}
                                                id="allegato_audio_descInputVideo"
                                                name="allegato_audio_desc"
                                                onChange={(event) => { this.AllegatiCambiatoHandler(event, inputGroup, "file_campo_audio_desc", this.state); }}
                                                placeholder="Inserisci l'audio descrittivo del vide"
                                                aria-label="Inserisci l'audio descrittivo del vide"
                                                accept=".mp3"
                                                type="file" />
                                            {
                                                this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                                                && this.state.file_campo_audio_desc.link !== ""
                                                && (<div className="mx-0">{"Il file precedentemente caricato risulta essere "} <a target="_blank" rel="noreferrer" title={this.state.file_campo_audio_desc.nometestualecampo + " " + this.state.file_campo_audio_desc.link} aria-label={this.state.file_campo_audio_desc.nometestualecampo + this.state.file_campo_audio_desc.link} href={this.state.file_campo_audio_desc.link.toString().replace('../../', '').replaceAll('&amp;', '&')}>Scarica file</a></div>)
                                            }
                                            {
                                                this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                                                && this.state.file_campo_audio_desc.link !== ""
                                                && (<Button type="submit" onClick={(e) => { if (window.confirm('Sei sicuro di voler eliminare questo record?')) { deleteFile(e, "file_campo_audio_desc") } else { deleteFileNO(e) } }}>
                                                    Elimina File
                                                </Button>)
                                            }
                                            {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                                        </div>
                                    )}
                                </InputGroup>
                                <InputGroup className="pb-3 clearfix "
                                    validate={{
                                        "allegato_sottotitoli": (input) => {

                                            if (this.state.file_campo_sottotitoli.nome === null || this.state.file_campo_sottotitoli.nome === "") {
                                                return true;
                                            }
                                            else {
                                                if (!validfilevtt.test(this.state.file_campo_sottotitoli.nome)) {
                                                    input.setCustomValidity("Il file caricato deve essere un vtt");
                                                    return false;
                                                } else {
                                                    if (this.state.file_campo_sottotitoli.dimensione > 50000 * 1024) {
                                                        input.setCustomValidity("Il file caricato non deve superare i 50 MB");
                                                        return false;
                                                    }
                                                    else {
                                                        return true;
                                                    }
                                                }
                                            }

                                        }
                                    }}
                                    translate={{
                                        allegato_sottotitoli: {
                                            valueMissing: "Questo campo è obbligatorio",
                                            patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                                        }
                                    }}>
                                    {({ error, valid, inputGroup }) => (
                                        <div className="form-group pb-3 clearfix ">
                                            <label htmlFor="allegato_sottotitoliInputVideo">Sottotitoli del video</label>
                                            <input
                                                disabled={this.state.formreadonly}
                                                className={`form-control-file ${!valid && "is-invalid"}`}
                                                id="allegato_sottotitoliInputVideo"
                                                name="allegato_sottotitoli"
                                                onChange={(event) => { this.AllegatiCambiatoHandler(event, inputGroup, "file_campo_sottotitoli", this.state); }}
                                                placeholder="Inserisci i sottotitoli del video"
                                                aria-label="Inserisci i sottotitoli del video"
                                                accept=".vtt"
                                                type="file" />
                                            {
                                                this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                                                && this.state.file_campo_sottotitoli.link !== ""
                                                && (<div className="mx-0">{"Il file precedentemente caricato risulta essere "} <a target="_blank" rel="noreferrer" title={this.state.file_campo_sottotitoli.nometestualecampo + " " + this.state.file_campo_sottotitoli.link} aria-label={this.state.file_campo_sottotitoli.nometestualecampo + this.state.file_campo_sottotitoli.link} href={this.state.file_campo_sottotitoli.link.toString().replace('../../', '').replaceAll('&amp;', '&')}>Scarica file</a></div>)
                                            }
                                            {
                                                this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                                                && this.state.file_campo_sottotitoli.link !== ""
                                                && (<Button type="submit" onClick={(e) => { if (window.confirm('Sei sicuro di voler eliminare questo record?')) { deleteFile(e, "file_campo_sottotitoli") } else { deleteFileNO(e) } }}>
                                                    Elimina File
                                                </Button>)
                                            }
                                            {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                                        </div>
                                    )}
                                </InputGroup>
                                <InputGroup className="pb-3 clearfix "
                                    validate={["trascrizione"]}
                                    translate={{
                                        trascrizione: {
                                            valueMissing: "Questo campo è obbligatorio",
                                            patternMismatch: "Questo campo è obbligatorio"
                                        }
                                    }}>
                                    {({ error, valid, inputGroup }) => (
                                        <div className="form-group pb-3 clearfix ">
                                            <label htmlFor="trascrizioneInputVideo">Trascrizione video *</label>
                                            <textarea
                                                readOnly={this.state.formreadonly}
                                                required={true}
                                                className={`form-control ${!valid && "is-invalid"}`}
                                                id="trascrizioneInputVideo"
                                                name="trascrizione"
                                                defaultValue={this.state.campo_trascrizione.campovalore}
                                                onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_trascrizione", this.state); }}
                                                onInput={(e) => onInput(e, inputGroup, form)}
                                                autoComplete="off"
                                                aria-label="Inserisci la trascrizione del video"
                                                placeholder="Inserisci la trascrizione del video"
                                                minLength={20} maxLength={4000} />
                                            {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                                        </div>
                                    )}
                                </InputGroup>
                                <Row className="my-2"><Col>
                                    {this.state.actions === "elimina" ?
                                        <Button type="submit" onClick={(e) => { if (window.confirm('Sei sicuro di voler eliminare questo record?')) { deleteItem(e) } else { deleteItemNo(e) } }}>
                                            Elimina
                                        </Button>
                                        : ""}
                                    {(this.state.actions !== "elimina") ?
                                        <Button type="submit" disabled={this.state.formreadonly}>{this.state.testopulsante}</Button> : ""}
                                </Col></Row>
                                <Row className="my-2"><Col><Button type="submit" onClick={(e) => { if (window.confirm('Sei sicuro di voler chiudere la finestra senza aver salvato questo record?')) chiudifinestraItem(e) }}>
                                    Chiudi Finestra di aggiornamento
                                </Button>
                                </Col></Row>
                                {this.state.messaggioAggiornamento}

                            </>
                        )}
                    </Form>
                    : null
                }
                {this.state.ricercaEseguitaContenuti === false
                    ?
                    <Spinner />
                    :
                    <section aria-label="GestioneContenuti Video" id="GestioneContenuti Video">
                        <div className="py-3">
                            <div>
                                {this.state.risultatiRicercaContenuti === null
                                    ? null :
                                    this.renderrisultatiRicercaSlider()}
                                <Paginazione
                                    paginaCorrente={this.state.paginaCorrenteContenuti}
                                    paginaClicked={this.cambiaPaginaHandlerContenuti}
                                    risultati={this.state.countdatidagraficareContenuti}
                                    elementiPerPagina={this.state.risultatiPerPaginaContenuti}
                                    labelpaginazione='Nagivazione dei contenuti degli Contenuti scaduti'>
                                </Paginazione>
                            </div>
                            <div>
                                <Link to="InserimentoContenuto" onClick={InserimentoContenutoClick}>Nuovo Video</Link>
                            </div>
                        </div>
                    </section>
                }
            </div>
        );
    }
    // #endregion

    // #region Ciclo di vita del container
    /// Metodi del ciclo di vita del container
    componentDidMount = () => {
        this.performSearchContenuti(this.setRichiedentiResultsContenuti, this.setErrorMessageContenuti, this.performSearchContenuti);

        if (this.state.actions !== "" && this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0) {
            this.performSearchContenuto(this.setRichiedentiResultsContenuto, this.setErrorMessageContenuto, this.performSearchContenuto, this.state);
        }
    }

    componentDidUpdate = (propsPrecedenti, statePrecedente) => {
        if (statePrecedente.queryOffsetContenuti !== this.state.queryOffsetContenuti) {
            this.performSearchContenuti(this.setRichiedentiResultsContenuti, this.setErrorMessageContenuti, this.performSearchContenuti);
        }

        if (this.state.actions !== "" && this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0 && (statePrecedente.idContenutoSelezionato !== this.state.idContenutoSelezionato || statePrecedente.actions !== this.state.actions)) {
            this.performSearchContenuto(this.setRichiedentiResultsContenuto, this.setErrorMessageContenuto, this.performSearchContenuto, this.state);
        }

        if (this.state.actions === "inserisci" && this.state.idContenutoSelezionato === 0 && statePrecedente.actions !== this.state.actions) {
            this.VaiInserimentoContenuto(this.state);
        }
    }
    // #endregion

    // #region GestioneInvioDati Al Server
    /* In caso ci siano campi con indirizzi se compilati vengono tradotti in coordinate e inviati come campi al server*/
    gestiscicoordinate = (successFnDb,
        errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, successgestiscicoordinate, gestiscicoordinate, results, status, keyprec) => {

        var coordinatepresenti = false;
        var indirizzo = '';
        var coordinatakey = "";

        /*Se sono presenti lo status e la chiave, l'indirizzo è stato inviato a Google per essere tradotto in coordinate*/
        /*Mi segno che sono state tradotte e in caso lo stato sia OK imposto anche le coordinate*/
        if (keyprec !== "") {
            state[keyprec].inviato = true;
            if (status !== null) {
                state[keyprec].inviato = true;
                if (status === 'OK') {
                    state[keyprec].coordinatax = results[0].geometry.location.lat();
                    state[keyprec].coordinatay = results[0].geometry.location.lng();
                    state[keyprec].titolo = state[keyprec].campovalore;
                }
            }
        }

        /*Cerco tra i vari campi dello se ci sono degli indirizzi per inviarle a google*/
        for (const [key, value] of Object.entries(state)) {
            if (key.indexOf("campo_") === 0) {
                if (value.coordinatax !== undefined && value.coordinatay !== undefined) {
                    if (value.coordinatax === null &&
                        value.coordinatay === null && value.campovalore !== null) {
                        indirizzo = value.campovalore;
                        coordinatepresenti = true;
                        coordinatakey = key;
                        break;
                    }
                }
            }

        }

        /*Se ci sono indirizzi da trasformare in coordinate le provo a trasformare in caso contrario passo alla fase di invio*/
        if (coordinatepresenti) {
            var geocoder = new this.props.google.maps.Geocoder();

            geocoder.geocode({ 'address': indirizzo }, function (results, status) {
                if (status === 'OK') {
                    gestiscicoordinate(successFnDb,
                        errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, successgestiscicoordinate, gestiscicoordinate, results, status, coordinatakey);
                } else {
                    gestiscicoordinate(successFnDb,
                        errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, successgestiscicoordinate, gestiscicoordinate, null, status, coordinatakey);
                }
            });
        }
        else {
            successgestiscicoordinate(successFnDb,
                errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state);
        }

    }

    /*Invio contenuto al db*/
    creazioneContenuto = (successFnDb,
        errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state) => {

        var TestoAggiornamento = (<div> <strong>Aggiornamento dei dati in corso si prega di attendere</strong></div>);

        this.setState({
            messaggioAggiornamento: TestoAggiornamento,
        });

        var campidaimpostare = [];

        var method = "";

        var idcontenuto = Buffer.from(state.idContenuto.toString(), 'utf8').toString('base64');

        var nomefunzionetabellaallegata = null;

        if (state.idContenuto !== 0) {

            state["testopulsante"] = "Aggiorna allegato";

            if (this.state.actions === "" || this.state.actions === "modifica" || this.state.actions === "copia") {
                method = Buffer.from('getUpdateContenuto', 'utf8').toString('base64');
            }

            if (this.state.actions === "elimina") {
                method = Buffer.from('getDeleteContenuto', 'utf8').toString('base64');
            }
        }
        else {
            method = Buffer.from('getinsertcontenutotabellaallegata', 'utf8').toString('base64');
            nomefunzionetabellaallegata = Buffer.from(this.props.nomefunzionetabellaallegata.toString(), 'utf8').toString('base64');
            idcontenuto = Buffer.from(this.props.idContenutoCollegato.toString(), 'utf8').toString('base64');
        }

        for (const [key, value] of Object.entries(state)) {

            if (key.indexOf("campo_") === 0 && value !== null) {
                campidaimpostare.push(state[key]);
            }
        }

        var parametriserver = {
            nometabella: Buffer.from(this.state.nometabellacontenuto.toString(), 'utf8').toString('base64'),
            limiterecord: null,
            idulteriorecondizione: null,
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: null,
            elencocampi: null,
            queryoffset: null,
            querynextrows: null,
            listavaloricondizioni: null,
            contenutiindicizzati: null,
            idcamposelectcustom: null,
            elencotabellecorrelate: null,
            elencocampicorrelate: null,
            idcontenuto: idcontenuto,
            campivaloridaimpostarequery: campidaimpostare,
            nomefunzionetabellaallegata: nomefunzionetabellaallegata,
        };

        const data = {
            token: this.props.loginToken,
            method: method,
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const dati = JSON.parse(response.data.data);

                     var idinserito = dati;

                    if (globals.debug < 3) console.log(dati);

                    if (idinserito > 0) {
                        successFnDb(successFnDb,
                            errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, dati, "");
                    }

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (parseInt(response.data.result.code) === 401) {
                        loginErrorFn();
                    } else {
                        errorFnDb(successFnDb,
                            errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, response.data.result.message);
                    }


                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata video allegati admin error: " + error);
                errorFnDb(successFnDb,
                    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, error.message);
            });


        return;
    };

    /*In caso di aggiornamento corretto del DB vengono inviati al server uno alla volta i file*/
    AggiornamentoDBCorretto = (successFnDb,
        errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, keyprec) => {

        var allegatipresenti = false;
        var nomecampo = '';
        var file = "";
        var nomefile = "";

        var filekey = "";

        /*Il file che ho inviato in precedenza è stato caricato correttamente*/
        /*Segno che è stato inviato e che è stato inviato con successo*/
        if (keyprec !== "") {
            state[keyprec].inviato = true;
            state[keyprec].errore = false;
        }

        /* Ciclo per i campi dello stato che sono dei campi file e invio quelli che sono stati caricati dall'utente
        e non sono ancora stati inviati*/
        for (const [key, value] of Object.entries(state)) {
            if (key.indexOf("file_campo_") === 0) {
                if (value.base64 !== null && value.nome !== null && value.inviato === false) {
                    nomecampo = key.replace("file_campo_", '');
                    file = value.base64;
                    nomefile = value.nome;
                    allegatipresenti = true;
                    filekey = key;
                    break;
                }
            }
        }

        /* Se il nome del campo è stato compilato (ovvero ho un file da inviare)
        lo encodo in base 64*/
        if (nomecampo !== "") {
            nomecampo = Buffer.from(nomecampo, 'utf8').toString('base64')
        }

        /* Se ho degli allegati da inviare provo ad inviarli in caso contrario vado alla fase di conclusione dell'aggiornamento*/
        if (allegatipresenti) {

            var TestoAggiornamento = (<React.Fragment>{this.state.messaggioAggiornamento}
                <div>  <strong>Invio di un documento in corso si prega di attendere</strong></div></React.Fragment>);

            this.setState({
                messaggioAggiornamento: TestoAggiornamento,
            });

            var parametriserver = {
                nometabella: Buffer.from(this.state.nometabellacontenuto.toString(), 'utf8').toString('base64'),
                limiterecord: null,
                idulteriorecondizione: null,
                idordinamento: null,
                numeropaginazione: null,
                idselecustom: null,
                selectdistinct: null,
                usaselectwithnolock: null,
                elencocampi: null,
                queryoffset: null,
                querynextrows: null,
                listavaloricondizioni: null,
                contenutiindicizzati: null,
                idcamposelectcustom: null,
                elencotabellecorrelate: null,
                elencocampicorrelate: null,
                idcontenuto: Buffer.from(idDbAggiornato.toString(), 'utf8').toString('base64'),
                base64fileinputfieldname: nomecampo,
                base64fileinputfile: file,
                base64fileinputfilename: nomefile,
            };


            const data = {
                token: this.props.loginToken,
                method: Buffer.from('getinsercontenutoallegato', 'utf8').toString('base64'),
                param: parametriserver,
            };

            const loginErrorFn = () => {
                this.props.effettuaLogout();
            }

            axios.post(globals.wsURLCliente, data)
                .then(function (response) {

                    if (response.data.result.success === true) {
                        successFnDb(successFnDb,
                            errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey);

                    } else {
                        if (globals.debug < 3) console.log(response.data.result.message);

                        if (parseInt(response.data.result.code) === 401) {
                            loginErrorFn();
                        } else {
                            errorFnDb(successFnDb,
                                errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, response.data.result.message, idDbAggiornato, filekey);
                        }


                    }
                })
                .catch(function (error) {
                    if (globals.debug < 3) console.log("chiamata video allegati admin error: " + error);
                    errorFnDb(successFnDb,
                        errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, error.message, idDbAggiornato, filekey);
                });

        }
        else {
            successFNAggiornamento(successFnDb,
                errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey);

        }

        return;
    }

    /* Se uno dei file va in errore segno l'invio fallito e procedo con il resto delle operazioni*/
    errorDocumenti = (successFnDb,
        errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, messaggioErrore, idDbAggiornato, keyprec) => {

        var TestoAggiornamento = (<React.Fragment>{this.state.messaggioAggiornamento}
            <div>  <strong>Invio di un documento in corso si prega di attendere</strong></div></React.Fragment>);

        this.setState({
            messaggioAggiornamento: TestoAggiornamento,
        });


        if (keyprec !== "") {
            state[keyprec].inviato = true;
            state[keyprec].errore = true;
        }

        successFnDb(successFnDb,
            errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato);

    }

    /*Se l'utente lo desidera appena aggiornato un record lo metto in modifica*/
    AbilitaModifica = (event) => {
        this.setState({
            formreadonly: false,
            messaggioAggiornamento: false,
            testopulsante: "Aggiorna allegato",
        });
    };

    /*Ho completato l'aggiornamento e do un informazione completa all'utente sullo stato di aggiornamento degli eventuali file*/
    AggiornamentoCompletato = (successFnDb,
        errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey) => {

        if (this.state.actions === "elimina") {
            this.ChiudiFinestra();
        }
        else {

            var DocumentiAllegati = [];

            for (const [key, value] of Object.entries(state)) {
                if (key.indexOf("file_campo_") === 0) {
                    var nometestualecampo = value.nometestualecampo;
                    switch (value.base64) {
                        case null:
                            if (value.link === undefined || value.link === "" || value.link === null)
                                DocumentiAllegati.push(<p>Il documento {nometestualecampo} - è stato deciso di non caricarlo lo si potrà caricare durante l'aggiornamento dello stesso</p>);
                            break;
                        default:
                            if (value.inviato === true && value.errore === false) {
                                DocumentiAllegati.push(<p>Il documento {nometestualecampo} - è stato caricato correttamente</p>);
                            }
                            else {
                                DocumentiAllegati.push(<p>Il documento {nometestualecampo} - ha avuto errore in fase di caricamento</p>);
                            }
                            break;
                    }
                }

            }

            DocumentiAllegati.push(<Row><Button type="submit" onClick={this.AbilitaModifica}>Abilita Modifica Video</Button></Row>);

            var TestoAggiornamento = (<div> <strong>Aggiornamento avvenuto correttamente</strong>{DocumentiAllegati}</div>);

            this.setState({
                messaggioAggiornamento: TestoAggiornamento,
                idContenuto: idDbAggiornato,
            });
        }            

            this.performSearchContenuti(this.setRichiedentiResultsContenuti, this.setErrorMessageContenuti, this.performSearchContenuti);
        }

        /*Ci sono errori nell'aggiornamento del record avverto l'utente e non provo neanche ad inviare i file*/
        AggiornamentoDBSbagliato = (messaggioErrore) => {

            var TestoAggiornamento = (<div> <strong>Aggiornamento dei dati non possibile si prega di riprovare più tardi</strong></div>);

            this.setState({
                messaggioAggiornamento: TestoAggiornamento,
                formreadonly: false,
            });

        }

        //#endregion 

        //#region Modifica/Copia/Delete contenuto
        /*Per mettere in modifica un record lo carico dal db ed imposto per i vari campi del form il valore di default*/
        performSearchContenuto = (successFn, errorFn, tokenScadutoFn, state) => {

            var parametriserver = {
                nometabella: Buffer.from(this.state.nometabellacontenuto.toString(), 'utf8').toString('base64'),
                limiterecord: null,
                idulteriorecondizione: null,
                idordinamento: null,
                numeropaginazione: null,
                idselecustom: null,
                selectdistinct: null,
                usaselectwithnolock: null,
                elencocampi: Buffer.from('ID;titolo;descrizione;ordinamento;Audio;audio_desc;audio_desc_mp3;sottotitoli;trascrizione;VideoDownload', 'utf8').toString('base64'),
                queryoffset: null,
                querynextrows: null,
                listavaloricondizioni: null,
                contenutiindicizzati: Buffer.from('false', 'utf8').toString('base64'),
                idcamposelectcustom: null,
                idcontenuto: Buffer.from(this.state.idContenutoSelezionato, 'utf8').toString('base64'),
            };

            const data = {
                token: this.props.loginToken,
                method: Buffer.from('getdaticontenuto', 'utf8').toString('base64'),
                param: parametriserver,
            };

            const loginErrorFn = () => {
                this.props.effettuaLogout();
            }

            axios.post(globals.wsURLCliente, data)
                .then(function (response) {

                    if (response.data.result.success === true) {
                        // handle success
                        const dati = JSON.parse(response.data.data);

                        var countdatidagraficareContenuto = dati[0]["countelementi"];

                        var paginaCorrenteContenuto = dati[0]["numeropagina"];

                        if (globals.debug < 3) console.log(dati);

                        dati.shift();

                        successFn(dati, countdatidagraficareContenuto, paginaCorrenteContenuto, state);

                    } else {
                        // handle error
                        if (globals.debug < 3) console.log(response.data.result.message);
                        if (parseInt(response.data.result.code) === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                })
                .catch(function (error) {
                    // handle error
                    if (globals.debug < 3) console.log("chiamata video allegati admin error: " + error);
                    errorFn(error.message);
                });


            return;
        }

        setRichiedentiResultsContenuto = (data, countdatidagraficareContenuto, paginaCorrenteContenuto, state) => {

            this.setState({
                ricercaEseguitaContenuto: false,
                risultatiRicercaContenuto: null,
                formreadonly: false,
                messaggioAggiornamento: null,
            });

            for (const [key, value] of Object.entries(state)) {

                var chiave = "";

                if (key.indexOf("campo_") === 0) {

                    chiave = key.replace("campo_", "").toLocaleLowerCase();

                    if (data[0][chiave] !== undefined) {
                        state[key].campovalore = data[0][chiave];
                    }
                }

                if (key.indexOf("file_campo_") === 0) {
                    chiave = key.replace("file_campo_", "").toLocaleLowerCase();
                    if (data[0][chiave] !== undefined) {
                        state[key].nometestualecampo = value.nometestualecampo;
                        state[key].base64 = null;
                        state[key].nome = data[0][chiave];
                        if (data[0][chiave + "_link"] !== "" && data[0][chiave + "_link"] !== undefined && this.state.actions !== "copia") {
                            state[key].link = process.env.REACT_APP_PUBLIC_URL + data[0][chiave + "_link"]
                        }
                        else {
                            state[key].link = "";
                        }
                    }
                }
            }

            var idcontenuto = 0;
            var testopulsante = "Inserisci allegato"

            if (this.state.actions === "copia") {
                idcontenuto = 0;
            }
            else {
                idcontenuto = this.state.idContenutoSelezionato;
                testopulsante = "Aggiorna allegato"
            }

            this.setState({
                ricercaEseguitaContenuto: true,
                risultatiRicercaContenuto: data,
                idContenuto: idcontenuto,
                testopulsante: testopulsante,
            });


        }

        VaiInserimentoContenuto = (state) => {

            for (const [key, value] of Object.entries(state)) {

                if (key.indexOf("campo_") === 0) {
                    state[key].campovalore = null;
                }

                if (key.indexOf("file_campo_") === 0) {
                    state[key].nometestualecampo = value.nometestualecampo;
                    state[key].base64 = null;
                    state[key].nome = null;
                    state[key].link = "";
                }
            }

            var testopulsante = "Inserisci allegato"
            var idcontenuto = 0;

            this.setState({
                impostanuovoinserimento: true,
                testopulsante: testopulsante,
                idContenuto: idcontenuto,
            });
        }

        setErrorMessageContenuto = (errorMessage) => {
            this.setState({
                messaggioErroreContenuto: errorMessage,
                ricercaEseguitaContenuto: true,
            });
        }

        // #endregion    


        // #region Ricerca Risultati 
        setRichiedentiResultsContenuti = (data, countdatidagraficareContenuti, paginaCorrenteContenuti) => {
            console.log(countdatidagraficareContenuti);
            console.log(paginaCorrenteContenuti);
            this.setState({
                risultatiRicercaContenuti: data,
                messaggioErrore: null,
                paginaCorrenteContenuti: paginaCorrenteContenuti,
                ricercaEseguitaContenuti: true,
                countdatidagraficareContenuti: countdatidagraficareContenuti
            });
        }

        setErrorMessageContenuti = (errorMessage) => {
            this.setState({
                messaggioErrore: errorMessage,
                ricercaEseguitaContenuti: true,
            });
        }


        performSearchContenuti = (successFn, errorFn, tokenScadutoFn) => {

            var idContenutoCollegato = null;

            if (this.props.idContenutoCollegato !== 0) {
                idContenutoCollegato = Buffer.from(this.props.idContenutoCollegato.toString(), 'utf8').toString('base64');
            }

            var parametriserver = {
                nometabella: Buffer.from(this.state.nometabellacontenuto.toString(), 'utf8').toString('base64'),
                limiterecord: Buffer.from("1000", 'utf8').toString('base64'),
                idulteriorecondizione: null,
                idordinamento: null,
                numeropaginazione: null,
                idselecustom: null,
                selectdistinct: null,
                usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
                elencocampi: Buffer.from('ID;funzioni;IndiceContenuti;titolo;descrizione;ordinamento', 'utf8').toString('base64'),
                queryoffset: Buffer.from(this.state.queryOffsetContenuti.toString(), 'utf8').toString('base64'),
                querynextrows: Buffer.from(this.state.risultatiPerPaginaContenuti.toString(), 'utf8').toString('base64'),
                listavaloricondizioni: null,
                contenutiindicizzati: null,
                idcamposelectcustom: null,
                idcontenuto: idContenutoCollegato,
                idfunzione: null,
                nomefunzionetabellaallegata: Buffer.from('t_eventi', 'utf8').toString('base64'),
            };

            const data = {
                token: this.props.loginToken,
                method: Buffer.from('getdatiallegati', 'utf8').toString('base64'),
                param: parametriserver,
            };

            const loginErrorFn = () => {
                this.props.effettuaLogout();
            }

            axios.post(globals.wsURLCliente, data)
                .then(function (response) {

                    if (response.data.result.success === true) {
                        // handle success
                        const dati = JSON.parse(response.data.data);

                        var countdatidagraficareContenuti = dati[0]["countelementi"];

                        var paginaCorrenteContenuti = dati[0]["numeropagina"];

                        if (globals.debug < 3) console.log(dati);

                        dati.shift();

                        successFn(dati, countdatidagraficareContenuti, paginaCorrenteContenuti);

                    } else {

                        // handle error
                        if (globals.debug < 3) console.log(response.data.result.message);

                        if (data.loginToken === globals.tokenGenerico && parseInt(response.data.result.code) === 401) {
                            tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                        }
                        else {
                            if (parseInt(response.data.result.code) === 401) {
                                loginErrorFn();
                            } else {
                                errorFn(response.data.result.message);
                            }
                        }
                    }
                })
                .catch(function (error) {
                    // handle error
                    if (globals.debug < 3) console.log("chiamata video allegati admin error: " + error);
                    errorFn(error.message);
                });


            return;
        }

        renderrisultatiRicercaSlider = () => {

            var Tabella = "";
            const risultati = this.state.risultatiRicercaContenuti.map((obj, i) => {

                const handlerModificaContenuto = () => {
                    this.VaiAModificaContenuto(obj.id);
                };

                const handlereliminaContenuto = () => {
                    this.VaiAEliminaContenuto(obj.id);
                };

                return (
                    <tr key={obj.id}>
                        <td className="text-center"><button style={{ background: "transparent", border: "0" }} onClick={handlerModificaContenuto} aria-label="Modifica" title="Modifica"><i style={{ cursor: "pointer", color: "black" }} title="Modifica" className="fa fa-edit"></i></button></td>
                        <td className="text-center"><button style={{ background: "transparent", border: "0" }} onClick={handlereliminaContenuto} aria-label="Elimina" title="Elimina"><i style={{ cursor: "pointer", color: "black" }} title="Elimina" className="fa fa-trash"></i></button></td>
                        <td className="text-left">{obj.titolo}</td>
                        <td className="text-left">{obj.descrizione}</td>

                    </tr>
                );
            });

            // return risultati;

            if (this.state.risultatiRicercaContenuti.length > 0) {
                Tabella =
                    <Table responsive striped hover size="sm">
                        <caption>I video allegati</caption>
                        <thead>
                            <tr>
                                <th className="text-center">Modifica</th>
                                <th className="text-center">Elimina</th>
                                <th className="text-left">Titolo</th>
                                <th className="text-left">Descrizione</th>
                            </tr>
                        </thead>
                        <tbody>
                            {risultati}
                        </tbody></Table>;
            }

            var Risultato = Tabella;
            return (Risultato);

        }
        EliminazioneFile = (successEliminaFile,
            errorEliminaFile, state, key) => {

            var nomecampo = key.replace("file_campo_", '')
            var nomefile = state[key].nome;

            nomecampo = Buffer.from(nomecampo, 'utf8').toString('base64');

            var TestoAggiornamento = (<React.Fragment>{this.state.messaggioAggiornamento}
                <div>  <strong>Cancellazione di un documento in corso si prega di attendere</strong></div></React.Fragment>);

            this.setState({
                messaggioAggiornamento: TestoAggiornamento,
            });

            var parametriserver = {
                nometabella: Buffer.from(this.state.nometabellacontenuto.toString(), 'utf8').toString('base64'),
                limiterecord: null,
                idulteriorecondizione: null,
                idordinamento: null,
                numeropaginazione: null,
                idselecustom: null,
                selectdistinct: null,
                usaselectwithnolock: null,
                elencocampi: null,
                queryoffset: null,
                querynextrows: null,
                listavaloricondizioni: null,
                contenutiindicizzati: null,
                idcamposelectcustom: null,
                elencotabellecorrelate: null,
                elencocampicorrelate: null,
                idcontenuto: Buffer.from(this.state.idContenutoSelezionato.toString(), 'utf8').toString('base64'),
                base64fileinputfieldname: nomecampo,
                base64fileinputfile: null,
                base64fileinputfilename: nomefile,
            };


            const data = {
                token: this.props.loginToken,
                method: Buffer.from('getdeletecontenutoallegato', 'utf8').toString('base64'),
                param: parametriserver,
            };

            const loginErrorFn = () => {
                this.props.effettuaLogout();
            }

            axios.post(globals.wsURLCliente, data)
                .then(function (response) {

                    if (response.data.result.success === true) {
                        successEliminaFile(key, state);

                    } else {
                        if (globals.debug < 3) console.log(response.data.result.message);

                        if (parseInt(response.data.result.code) === 401) {
                            loginErrorFn();
                        } else {
                            errorEliminaFile();
                        }


                    }
                })
                .catch(function (error) {
                    if (globals.debug < 3) console.log("chiamata video allegati admin error: " + error);
                    errorEliminaFile();
                });

            return;
        }

        ContenutoEliminatoOK = (key, state) => {
            var TestoAggiornamento = "";

            state[key].base64 = null;
            state[key].nome = "";
            state[key].link = "";

            this.setState({
                ricercaEseguitaContenuto: false,
                messaggioAggiornamento: TestoAggiornamento,
            }, function () {
                this.ContenutoEliminatoOKAggiornamento();
            });
        }

        ContenutoEliminatoOKAggiornamento = () => {
            this.setState({
                ricercaEseguitaContenuto: true,
            });
        }

        ContenutoEliminatoKO = () => {
            var TestoAggiornamento = (<div> <strong>Aggiornamento dei dati non possibile si prega di riprovare più tardi</strong></div>);

            this.setState({
                messaggioAggiornamento: TestoAggiornamento,
                formreadonly: false,
            });
        }

    }
    // #endregion

    // #region Redux
    const mapStateToProps = state => {
        return {
            loginToken: state.loginToken,
            userProfile: state.userProfile,
        };
    };

    const mapDispatchToProps = dispatch => {
        return {
            effettuaLogout: () => dispatch(actions.effettuaLogout()),
        }
    };

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper(
        (props) => ({
            apiKey: "AIzaSyA-a0pZN7pE4naZqWMXz-alvrUgKkaJUDc",
            libraries: ['places', 'visualization'],
            language: "it",
        })
    )(VideoAllegatiRicercaRecordAdmin));
