import React, { Component } from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import HomePage from './containers/HomePage';

import EventiRicercaUtente from './containers/EventiRicercaUtente';
import EventiGestioneRecordUtente from './containers/EventiGestioneRecordUtente';
import EventiGestioneRecordAdmin from './containers/EventiGestioneRecordAdmin';
import EventiRicercaAdmin from './containers/EventiRicercaAdmin';

import AssociazioniRicercaUtente from './containers/AssociazioniRicercaUtente';
import AssociazioniGestioneRecordUtente from './containers/AssociazioniGestioneRecordUtente';
import AssociazioniGestioneRecordAdmin from './containers/AssociazioniGestioneRecordAdmin';
import AssociazioniRicercaAdmin from './containers/AssociazioniRicercaAdmin';


import Contenuto from './containers/Contenuto';
import CartinaEventi from './containers/CartinaEventi';
import CercaNelsito from './containers/CercaNelsito';

import AutorizzazioniLogin from './containers/AutorizzazioniLogin';
import AutorizzazioniSuccessLogin from './containers/AutorizzazioniSuccessLogin';
import AutorizzazioniErrorLogin from './containers/AutorizzazioniErrorLogin';
import AutorizzazioniDatiPersonaliLogin from './containers/AutorizzazioniDatiPersonaliLogin';







class App extends Component {

  render() {

    if (this.props.userProfile !== null && this.props.userProfile.username !== "") {

      return (
        <div>

          <Switch>
            <Route path="/Evento" component={EventiGestioneRecordUtente} />
            <Route path="/Eventi" component={EventiRicercaUtente} />
            <Route path="/EventiGestioneRecordAdmin" component={EventiGestioneRecordAdmin} />
            <Route path="/EventiRicercaAdmin" component={EventiRicercaAdmin} />

            <Route path="/Associazione" component={AssociazioniGestioneRecordUtente} />
            <Route path="/Associazioni" component={AssociazioniRicercaUtente} />
            <Route path="/AssociazioniGestioneRecordAdmin" component={AssociazioniGestioneRecordAdmin} />
            <Route path="/AssociazioniRicercaAdmin" component={AssociazioniRicercaAdmin} />

            <Route path="/contenutoecm" component={Contenuto} />
            
            <Route path="/AutorizzazioniLogin" component={AutorizzazioniLogin} />
            <Route path="/AutorizzazioniSuccessLogin" component={AutorizzazioniSuccessLogin} />
            <Route path="/AutorizzazioniErrorLogin" component={AutorizzazioniErrorLogin} />
            <Route path="/AutorizzazioniDatiPersonaliLogin" component={AutorizzazioniDatiPersonaliLogin} />

            <Route path="/CartinaEventi" component={CartinaEventi} />
            <Route path="/CercaNelsito" component={CercaNelsito} />
            <Route path="/" component={HomePage} />
          </Switch>

        </div>);
    } else {

      return (
        <Switch>
          <Route path="/Evento" component={EventiGestioneRecordUtente} />
          <Route path="/Eventi" component={EventiRicercaUtente} />
          <Route path="/EventiGestioneRecordAdmin" component={EventiGestioneRecordAdmin} />
          <Route path="/EventiRicercaAdmin" component={EventiRicercaAdmin} />

          <Route path="/Associazione" component={AssociazioniGestioneRecordUtente} />
          <Route path="/Associazioni" component={AssociazioniRicercaUtente} />
          <Route path="/AssociazioniGestioneRecordAdmin" component={AssociazioniGestioneRecordAdmin} />
          <Route path="/AssociazioniRicercaAdmin" component={AssociazioniRicercaAdmin} />

          <Route path="/contenutoecm" component={Contenuto} />
          <Route path="/CartinaEventi" component={CartinaEventi} />
          <Route path="/CercaNelsito" component={CercaNelsito} />

          <Route path="/AutorizzazioniSuccessLogin" component={AutorizzazioniSuccessLogin} />
          <Route path="/AutorizzazioniErrorLogin" component={AutorizzazioniErrorLogin} />
          <Route path="/AutorizzazioniLogin" component={AutorizzazioniLogin} />
          <Route path="/AutorizzazioniDatiPersonaliLogin" component={AutorizzazioniDatiPersonaliLogin} />

          <Route path="/" component={HomePage} />
        </Switch>);

    }

  }
}


// #region Redux
const mapStateToProps = state => {
  return {
    userProfile: state.userProfile,
  };
};

// #endregion

export default withRouter(connect(mapStateToProps)(App))

