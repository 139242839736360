import React, { Component } from 'react';
import { Row, Button, Col } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import "../css/datepicker.css";
import "../css/form.css";
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import Spinner from '../components/Spinner';
import EmailTemplate from '../components/EmailTemplate';
import ReactDOMServer from 'react-dom/server';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Form, InputGroup } from "react-html5-form";
import { GoogleApiWrapper } from 'google-maps-react';
import { validfileimage, validfilepdf } from '../comuni/regex';
import { getBase64key } from '../comuni/funzioni';

class AssociazioniGestioneRecordAdmin extends Component {

  state = {
    formreadonly: false,
    idContenuto: 0,
    actions: this.props.location.state === undefined ? "" : (this.props.location.state.actions === undefined
      ? "" : this.props.location.state.actions),
    idContenutoSelezionato: this.props.location.state === undefined ? 0 : (this.props.location.state.idContenutoSelezionato === undefined
      ? 0 : this.props.location.state.idContenutoSelezionato),

    nometabellacontenuto: 't_associazioni',
    nometabellacontenutotipologia: 't_associazioni_tipologia',

    //#region campifile

    file_campo_allegato_Attivita_Anno_Prec: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Attivita anno precedente",
      link: "",
      dimensione: null
    },

    file_campo_allegato_Atto_Costitutivo: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Atto Costitutivo",
      link: "",
      dimensione: null
    },

    // file_campo_allegato_Libro_Soci: {
    //   nome: null,
    //   base64: null,
    //   inviato: false,
    //   errore: false,
    //   nometestualecampo: "Libro soci",
    //   link: "",
    //   dimensione: null
    // },

    file_campo_allegato_Statuto: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Statuto",
      link: "",
      dimensione: null
    },

    file_campo_fotopiccola: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Immagine Anteprima",
      link: "",
      dimensione: null
    },

    file_campo_immaginecopertina: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Immagine Dettaglio",
      link: "",
      dimensione: null
    },

    //#endregion

    //#region campicoordinate

    campo_Sede_Legale: {
      nomecampo: "Sede_Legale",
      campovalore: "",
      coordinatax: null,
      coordinatay: null,
      inviato: false,
      titolo: null,
    },
    campo_Sede_Operativa: {
      nomecampo: "Sede_Operativa",
      campovalore: "",
      coordinatax: null,
      coordinatay: null,
      inviato: false,
      titolo: null,
    },

    //#endregion

    //#region campi      

    campo_associazioni: {
      nomecampo: "associazioni",
      campovalore: "",
    },
    campo_Codice_Fiscale: {
      nomecampo: "Codice_Fiscale",
      campovalore: "",
    },
    campo_Email: {
      nomecampo: "Email",
      campovalore: "",
    },
    campo_Partita_IVA: {
      nomecampo: "Partita_IVA",
      campovalore: "",
    },
    campo_PEC: {
      nomecampo: "PEC",
      campovalore: "",
    },
    campo_Recapito_Telefonico: {
      nomecampo: "Recapito_Telefonico",
      campovalore: "",
    },

    runtsCheck: false,

    campo_NumeroIscrizioneRUNTS: {
      nomecampo: "NumeroIscrizioneRUNTS",
      campovalore: null,
    },

    campo_associazioni_tipologia: {
      nomecampo: "associazioni_tipologia",
      campovalore: null,
    },
    campo_descrizione: {
      nomecampo: "descrizione",
      campovalore: "",
    },
    campo_cfprincipale: {
      nomecampo: "cfprincipale",
      campovalore: this.props.userProfile.cf,
    },
    campo_codici_fiscaliabilitati: {
      nomecampo: "codici_fiscaliabilitati",
      campovalore: this.props.userProfile.cf + ";",
    },

    campo_Disattivata: {
      nomecampo: "Disattivata",
      campovalore: true,
    },

    campo_validata: {
      nomecampo: "validata",
      campovalore: false,
    },


    





    //#endregion 

    //#region Gestione stato per ricerca TipologiaAssociazione      
    risultatiRicercaTipologiaAssociazioni: null,
    risultatiPerPaginaTipologiaAssociazioni: 6,
    queryOffsetTipologiaAssociazioni: 0,
    ricercaEseguitaTipologiaAssociazioni: false,
    messaggioErroreTipologiaAssociazioni: null,
    countdatidagraficareTipologiaAssociazioni: null,
    //#endregion

    //#region Gestione stato per ricerca Contenuto      
    risultatiRicercaContenuto: null,
    risultatiPerPaginaContenuto: 6,
    queryOffsetContenuto: 0,
    ricercaEseguitaContenuto: false,
    messaggioErroreContenuto: null,
    countdatidagraficareContenuto: null,
    //#endregion

    messaggioAggiornamento: null,

    testopulsante: "Inserisci associazione",
  };

  //#region HandlerCambiamentiAllegati


  AllegatiCambiatoHandler = (event, inputGroup, key, state) => {
    if (event.target.files[0] != null) {
      getBase64key(event.target.files[0], this.AllegatiCambiatoBase64Handler,
        event.target.files[0].name, inputGroup, key, state, event.target.files[0].size);
    }
    else {
      state[key].nome = null;
      state[key].base64 = null;
      state[key].dimensione = null;
      inputGroup.checkValidityAndUpdate();
    }

  }

  AllegatiCambiatoBase64Handler = (base64, nome, inputGroup, key, state, size) => {
    state[key].nome = nome;
    state[key].base64 = base64;
    state[key].dimensione = size;
    inputGroup.checkValidityAndUpdate();
  }


  //#endregion


  //#region HandlerCambiamenti Campi

  campiCambiatoHandler = (event, key, state) => {
    state[key].campovalore = event.target.value;
  }

  associazioni_tipologiaCambiatoHandler = (event) => {

    var checkboxes = document.getElementsByName("tipologia_associazione");
    var checkboxvalue = "";

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        checkboxvalue += checkboxes[i].value + ","
      }
    }

    this.setState({
      campo_associazioni_tipologia: {
        nomecampo: "associazioni_tipologia",
        campovalore: checkboxvalue,
      },
    });
  }




  runtsCheckCambiatoHandler = (event) => {
    console.log("event");

    this.setState({
      runtsCheck: !this.state.runtsCheck
    });
    


  }



  //#endregion

  // #region Render
  render() {

    console.log("runtsc", this.state.runtsCheck);

    if (this.props.loginToken === globals.tokenGenerico) {
      //Non sono loggato nel sistema ma sto accedendo come utente generico faccio logout
      //  this.props.effettuaLogout();
    }

    if (this.props.userProfile.cf === "") {
      //Non sono loggato nel sistema ma sto accedendo come utente generico faccio logout
      //  this.props.effettuaLogout();
    }

    const handleSubmita = (form) => {

      var validataform = form.checkValidity();

      if (validataform === false) {
      }
      else {
        this.setState({
          formreadonly: true,
        });

        this.gestiscicoordinate(this.AggiornamentoDBCorretto, this.AggiornamentoDBSbagliato,
          this.AggiornamentoDBCorretto, this.errorDocumenti, this.AggiornamentoCompletato, this.state, this.creazioneContenuto, this.gestiscicoordinate, null, null, "");
      }
    };

    const onInput = (e, inputGroup) => {
      inputGroup.checkValidityAndUpdate();
    };

    const deleteItem = (e) => {
      e.preventDefault();
      this.gestiscicoordinate(this.AggiornamentoDBCorretto, this.AggiornamentoDBSbagliato,
        this.AggiornamentoDBCorretto, this.errorDocumenti, this.AggiornamentoCompletato, this.state, this.creazioneContenuto, this.gestiscicoordinate, null, null, "");
    };

    const deleteItemNo = (e) => {
      e.preventDefault();
    };

    const deleteFile = (e, chiave) => {
      e.preventDefault();
      this.EliminazioneFile(this.ContenutoEliminatoOK, this.ContenutoEliminatoKO,
        this.state, chiave);
    };

    const deleteFileNO = (e) => {
      e.preventDefault();
    };

    return (
      <div className="container px-4">
        {this.state.idContenutoSelezionato === 0 || (this.state.idContenutoSelezionato !== 0 &&
          this.state.ricercaEseguitaContenuto === true)
          ?
          <Form onSubmit={handleSubmita} >
            {({ error, valid, pristine, submitting, form }) => (
              <>
                <Row className="my-2"><Col>
                  <h3 className="h1" id="ArchivioContenutiTitolo">Dati associazione</h3>
                </Col></Row>
                {this.state.messaggioAggiornamento}
                <InputGroup className="pb-3"
                  validate={["associazioni"]}
                  translate={{
                    associazioni: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="associazioniInput">Denominazione Associazione *</label>
                      <input
                        readOnly={this.state.formreadonly}
                        required={true}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="associazioniInput"
                        defaultValue={this.state.campo_associazioni.campovalore}
                        name="associazioni"
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_associazioni", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Inserisci il nome dell'associazione"
                        placeholder="Inserisci il nome dell'associazione" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3"
                  validate={["Sede_Legale"]}
                  translate={{
                    Sede_Legale: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="Sede_LegaleInput">Sede Legale *</label>
                      <input
                        readOnly={this.state.formreadonly}
                        required={true}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="Sede_LegaleInput"
                        name="Sede_Legale"
                        defaultValue={this.state.campo_Sede_Legale.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Sede_Legale", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Inserisci l'indirizzo della sede legale"
                        placeholder="Inserisci l'indirizzo della sede legale" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3"
                  validate={["Sede_Operativa"]}
                  translate={{
                    Sede_Operativa: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="Sede_OperativaInput">Sede Operativa Associazione *</label>
                      <input
                        readOnly={this.state.formreadonly}
                        required={true}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="Sede_OperativaInput"
                        name="Sede_Operativa"
                        defaultValue={this.state.campo_Sede_Operativa.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Sede_Operativa", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Inserisci l'indirizzo della sede operativa"
                        placeholder="Inserisci l'indirizzo della sede operativa" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3"
                  validate={["Codice_Fiscale"]}
                  translate={{
                    Codice_Fiscale: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="Codice_FiscaleInput">Codice Fiscale Associazione *</label>
                      <input
                        readOnly={this.state.formreadonly}
                        required={false}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="Codice_FiscaleInput"
                        name="Codice_Fiscale"
                        defaultValue={this.state.campo_Codice_Fiscale.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Codice_Fiscale", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Inserisci il codice fiscale dell'associazione"
                        placeholder="Inserisci il codice fiscale dell'associazione" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3"
                  validate={["Partita_IVA"]}
                  translate={{
                    Partita_IVA: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="Partita_IVAInput">Partita iva *</label>
                      <input
                        readOnly={this.state.formreadonly}
                        required={true}
                        pattern="[0-9]{11,11}"
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="Partita_IVAInput"
                        name="Partita_IVA"
                        defaultValue={this.state.campo_Partita_IVA.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Partita_IVA", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Inserisci la partita iva dell'associazione"
                        placeholder="Inserisci la partita iva dell'associazione" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3"
                  validate={["Recapito_Telefonico"]}
                  translate={{
                    Recapito_Telefonico: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="Recapito_TelefonicoInput">Recapito telefonico *</label>
                      <input
                        readOnly={this.state.formreadonly}
                        required={true}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="Recapito_TelefonicoInput"
                        name="Recapito_Telefonico"
                        defaultValue={this.state.campo_Recapito_Telefonico.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Recapito_Telefonico", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Inserisci il recapito telfonico dell'associazione"
                        placeholder="Inserisci il recapito telfonico dell'associazione" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3"
                  validate={["PEC"]}
                  translate={{
                    PEC: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                      typeMismatch: "Quello inserito non è un indirizzo PEC valido"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="PECInput">PEC *</label>
                      <input
                        readOnly={this.state.formreadonly}
                        required={true}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="PECInput"
                        name="PEC"
                        defaultValue={this.state.campo_PEC.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_PEC", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Inserisci la PEC dell'associazione"
                        placeholder="Inserisci la PEC dell'associazione" type="email" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3"
                  validate={["Email"]}
                  translate={{
                    Email: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                      typeMismatch: "Quello inserito non è un indirizzo mail valido"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="EmailInput">Email *</label>
                      <input
                        readOnly={this.state.formreadonly}
                        required={true}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="EmailInput"
                        name="Email"
                        defaultValue={this.state.campo_Email.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Email", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Inserisci l'email dell'associazione"
                        placeholder="Inserisci l'email dell'associazione" type="email" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>



                <InputGroup className="pb-3"
                  validate={["Codici_fiscaliabilitati"]}
                  translate={{
                    Email: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                      typeMismatch: "Quello inserito non è un indirizzo mail valido"
                    }
                  }}>

                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="Codici_fiscaliabilitatiInput">Altri codici fiscali abilitati a gestire l'associazione</label>
                      <input
                        readOnly={this.state.formreadonly}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="Codici_fiscaliabilitatiInput"
                        name="Codici_fiscaliabilitati"
                        defaultValue={this.state.campo_codici_fiscaliabilitati.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_codici_fiscaliabilitati", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Inserisci gli eventuali codici fiscali degli utenti abilitati a gestire l'associazione"
                        placeholder="Inserisci gli eventuali codici fiscali degli utenti abilitati a gestire l'associazione" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>



                {/* Codice Runts */}


                <div role="group" className="form-group" key={'divinline-checkbox-runtscheck'}>
                  <input
                    onChange={(event) => { this.runtsCheckCambiatoHandler(event); }}
                    name="runtsCheck"
                    type="checkbox"
                    className={`form-check-input`}
                    id="runtsCheck"
                    
                    value={0} />
                  <label className="form-check-label" htmlFor={'divinline-checkbox-runtscheck'}>L'Associazione è iscritta al Registro Unico Nazionale del Terzo Settore?</label>
                </div>


                <br />    

                <InputGroup  className={"pb-3 " + (this.state.runtsCheck ? "" : "d-none")}
                  validate={["NumeroIscrizioneRUNTS"]}
                  translate={{
                    NumeroIscrizioneRUNTS: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                      typeMismatch: "--"
                    }
                  }}>

                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      {/* <label htmlFor="NumeroIscrizioneRUNTSInput">Numero di iscrizione RUNTS (Registro Unico Nazionale del Terzo Settore)</label> */}
                      <input
                        readOnly={this.state.formreadonly}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="NumeroIscrizioneRUNTSInput"
                        name="NumeroIscrizioneRUNTS"
                        defaultValue={this.state.campo_NumeroIscrizioneRUNTS.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_NumeroIscrizioneRUNTS", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        required={this.state.runtsCheck}
                        aria-label="Numero di iscrizione RUNTS (Registro Unico Nazionale del Terzo Settore)"
                        placeholder="Inserisci il Numero di iscrizione RUNTS" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                {/* Codice Runts */}


















                {this.state.risultatiRicercaTipologiaAssociazioni === null
                  ?
                  <Spinner />
                  :
                  <InputGroup className="pb-3" validate={{
                    "tipologia_associazione": () => {
                      var checkboxes = document.getElementsByName("tipologia_associazione");
                      var numberOfCheckedItems = 0;

                      for (var i = 0; i < checkboxes.length; i++) {
                        if (checkboxes[i].checked)
                          numberOfCheckedItems++;
                      }

                      if (numberOfCheckedItems === 0) {
                        return false;
                      }
                      else {
                        return true;
                      }
                    }
                  }}>
                    {({ error, valid }) => (
                      <div className="form-group">
                        <fieldset>
                          <legend>Tipologia Associazione</legend>
                          {this.renderRisultatiRicercaTipologiaAssociazioni(valid)} </fieldset>
                        {valid ? "" : (<div className="invalid-feedback alert alert-danger mx-0" style={{ display: 'block' }}>Devi selezionare almeno un valore tra quello presente</div>)}

                      </div>
                    )}
                  </InputGroup>
                }
                <div className="pb-3" style={{ width: "100%" }}>
                  <Row className="my-2"><Col>
                    <label>Descrizione</label>
                    <CKEditor
                      editor={ClassicEditor}
                      aria-label="Descrizione"
                      disabled={this.state.formreadonly}
                      data={this.state.campo_descrizione.campovalore}
                      config={{
                        toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        this.setState({
                          campo_descrizione: {
                            nomecampo: "descrizione",
                            campovalore: data,
                          }
                        });
                      }}
                    />
                  </Col></Row></div>
                <Row className="my-2"><Col>
                  <h4>Allegati</h4>
                </Col></Row>
                <InputGroup className="pb-3"
                  validate={{
                    "allegato_Statuto": (input) => {

                      if (this.state.file_campo_allegato_Statuto.nome === null || this.state.file_campo_allegato_Statuto.nome === "") {
                        input.setCustomValidity("Questo campo è obbligatorio");
                        return false;
                      }
                      else {
                        if (!validfilepdf.test(this.state.file_campo_allegato_Statuto.nome)) {
                          input.setCustomValidity("Il file caricato deve essere un pdf");
                          return false;
                        } else {
                          if (this.state.file_campo_allegato_Statuto.dimensione > 50000 * 1024) {
                            input.setCustomValidity("Il file caricato non deve superare i 50 MB");
                            return false;
                          }
                          else {
                            return true;
                          }
                        }
                      }

                    }
                  }}
                  translate={{
                    allegato_Statuto: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il file caricato deve essere un pdf"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="allegato_StatutoInput">Statuto *</label>
                      <input
                        disabled={this.state.formreadonly}
                        required={this.state.file_campo_allegato_Statuto.link === ""}
                        className={`form-control-file ${!valid && "is-invalid"}`}
                        id="allegato_StatutoInput"
                        name="allegato_Statuto"
                        onChange={(event) => { this.AllegatiCambiatoHandler(event, inputGroup, "file_campo_allegato_Statuto", this.state); }}
                        placeholder="Inserisci lo statuto dell'associazione"
                        aria-label="Inserisci lo statuto dell'associazione"
                        accept=".pdf"
                        type="file" />
                      {
                        this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                        && this.state.file_campo_allegato_Statuto.link !== ""
                        && (<div className="mx-0">{"Il file precedentemente caricato risulta essere "}
                          <a target="_blank" rel="noreferrer"
                            href={this.state.file_campo_allegato_Statuto.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                            aria-label={this.state.file_campo_allegato_Statuto.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                            title={this.state.file_campo_allegato_Statuto.link.toString().replace('../../', '').replaceAll('&amp;', '&')}>
                            Scarica file</a>
                          - Per aggiornare il file caricarne uno nuovo e cliccare su Aggiorna</div>)
                      }
                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3"
                  validate={{
                    "allegato_Atto_Costitutivo": (input) => {

                      if (this.state.file_campo_allegato_Atto_Costitutivo.nome === null || this.state.file_campo_allegato_Atto_Costitutivo.nome === "") {
                        input.setCustomValidity("Questo campo è obbligatorio");
                        return false;
                      }
                      else {
                        if (!validfilepdf.test(this.state.file_campo_allegato_Atto_Costitutivo.nome)) {
                          input.setCustomValidity("Il file caricato deve essere un pdf");
                          return false;
                        } else {
                          if (this.state.file_campo_allegato_Atto_Costitutivo.dimensione > 50000 * 1024) {
                            input.setCustomValidity("Il file caricato non deve superare i 50 MB");
                            return false;
                          }
                          else {
                            return true;
                          }
                        }
                      }

                    }
                  }}
                  translate={{
                    allegato_Atto_Costitutivo: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="allegato_Atto_CostitutivoInput">Atto costituitivo *</label>
                      <input
                        disabled={this.state.formreadonly}
                        required={this.state.file_campo_allegato_Atto_Costitutivo.link === ""}
                        className={`form-control-file ${!valid && "is-invalid"}`}
                        id="allegato_Atto_CostitutivoInput"
                        name="allegato_Atto_Costitutivo"
                        onChange={(event) => { this.AllegatiCambiatoHandler(event, inputGroup, "file_campo_allegato_Atto_Costitutivo", this.state); }}
                        placeholder="Inserisci l'atto costitutivo"
                        aria-label="Inserisci l'atto costitutivo"
                        accept=".pdf"
                        type="file" />
                      {
                        this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                        && this.state.file_campo_allegato_Atto_Costitutivo.link !== ""
                        && (<div className="mx-0">{"Il file precedentemente caricato risulta essere "}
                          <a target="_blank" rel="noreferrer"
                            href={this.state.file_campo_allegato_Atto_Costitutivo.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                            aria-label={this.state.file_campo_allegato_Atto_Costitutivo.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                            title={this.state.file_campo_allegato_Atto_Costitutivo.link.toString().replace('../../', '').replaceAll('&amp;', '&')}>
                            Scarica file</a> - Per aggiornare il file caricarne uno nuovo e cliccare su Aggiorna</div>)
                      }
                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                
                {/* <InputGroup className="pb-3"
                  validate={{
                    "allegato_Libro_Soci": (input) => {

                      if (this.state.file_campo_allegato_Libro_Soci.nome === null || this.state.file_campo_allegato_Libro_Soci.nome === "") {
                        input.setCustomValidity("Questo campo è obbligatorio");
                        return false;
                      }
                      else {
                        if (!validfilepdf.test(this.state.file_campo_allegato_Libro_Soci.nome)) {
                          input.setCustomValidity("Il file caricato deve essere un pdf");
                          return false;
                        } else {
                          if (this.state.file_campo_allegato_Libro_Soci.dimensione > 50000 * 1024) {
                            input.setCustomValidity("Il file caricato non deve superare i 50 MB");
                            return false;
                          }
                          else {
                            return true;
                          }
                        }
                      }

                    }
                  }}
                  translate={{
                    allegato_Libro_Soci: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="allegato_Libro_SociInput">Libro dei soci *</label>
                      <input
                        disabled={this.state.formreadonly}
                        required={this.state.file_campo_allegato_Libro_Soci.link === ""}
                        className={`form-control-file ${!valid && "is-invalid"}`}
                        id="allegato_Libro_SociInput"
                        name="allegato_Libro_Soci"
                        onChange={(event) => { this.AllegatiCambiatoHandler(event, inputGroup, "file_campo_allegato_Libro_Soci", this.state); }}
                        placeholder="Inserisci il libro dei soci"
                        aria-label="Inserisci il libro dei soci"
                        accept=".pdf"
                        type="file" />
                      {
                        this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                        && this.state.file_campo_allegato_Libro_Soci.link !== ""
                        && (<div className="mx-0">{"Il file precedentemente caricato risulta essere "}
                          <a target="_blank" rel="noreferrer"
                            href={this.state.file_campo_allegato_Libro_Soci.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                            aria-label={this.state.file_campo_allegato_Libro_Soci.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                            title={this.state.file_campo_allegato_Libro_Soci.link.toString().replace('../../', '').replaceAll('&amp;', '&')}>
                            Scarica file</a> - Per aggiornare il file caricarne uno nuovo e cliccare su Aggiorna</div>)
                      }
                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup> */}


                <InputGroup className="pb-3"
                  validate={{
                    "allegato_Attivita_Anno_Prec": (input) => {

                      if (this.state.file_campo_allegato_Attivita_Anno_Prec.nome === null || this.state.file_campo_allegato_Attivita_Anno_Prec.nome === "") {
                        input.setCustomValidity("Questo campo è obbligatorio");
                        return false;
                      }
                      else {
                        if (!validfilepdf.test(this.state.file_campo_allegato_Attivita_Anno_Prec.nome)) {
                          input.setCustomValidity("Il file caricato deve essere un pdf");
                          return false;
                        } else {
                          if (this.state.file_campo_allegato_Attivita_Anno_Prec.dimensione > 50000 * 1024) {
                            input.setCustomValidity("Il file caricato non deve superare i 50 MB");
                            return false;
                          }
                          else {
                            return true;
                          }
                        }
                      }

                    }
                  }}
                  translate={{
                    allegato_Attivita_Anno_Prec: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="allegato_Attivita_Anno_PrecInput">Attività anno precedente *</label>
                      <input
                        disabled={this.state.formreadonly}
                        required={this.state.file_campo_allegato_Attivita_Anno_Prec.link === ""}
                        className={`form-control-file ${!valid && "is-invalid"}`}
                        id="allegato_Attivita_Anno_PrecInput"
                        name="allegato_Attivita_Anno_Prec"
                        onChange={(event) => { this.AllegatiCambiatoHandler(event, inputGroup, "file_campo_allegato_Attivita_Anno_Prec", this.state); }}
                        placeholder="Inserisci l'elenco delle attività dell'anno precedente"
                        aria-label="Inserisci l'elenco delle attività dell'anno precedente"
                        accept=".pdf"
                        type="file" />
                      {
                        this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                        && this.state.file_campo_allegato_Attivita_Anno_Prec.link !== ""
                        && (<div className="mx-0">{"Il file precedentemente caricato risulta essere "}
                          <a target="_blank" rel="noreferrer"
                            href={this.state.file_campo_allegato_Attivita_Anno_Prec.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                            aria-label={this.state.file_campo_allegato_Attivita_Anno_Prec.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                            title={this.state.file_campo_allegato_Attivita_Anno_Prec.link.toString().replace('../../', '').replaceAll('&amp;', '&')}>
                            Scarica file</a> - Per aggiornare il file caricarne uno nuovo e cliccare su Aggiorna</div>)
                      }
                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <Row className="my-2"><Col>
                  <h4>Immagini</h4>
                </Col></Row>
                <InputGroup className="pb-3"
                  validate={{
                    "allegato_fotopiccola": (input) => {

                      if (this.state.file_campo_fotopiccola.nome === null || this.state.file_campo_fotopiccola.nome === "") {
                        return true;
                      }
                      else {
                        if (!validfileimage.test(this.state.file_campo_fotopiccola.nome)) {
                          input.setCustomValidity("Il file caricato deve essere un'immagine");
                          return false;
                        } else {
                          if (this.state.file_campo_allegato_Attivita_Anno_Prec.dimensione > 10000 * 1024) {
                            input.setCustomValidity("Il file caricato non deve superare i 10 MB");
                            return false;
                          }
                          else {
                            return true;
                          }
                        }
                      }

                    }
                  }}
                  translate={{
                    allegato_fotopiccola: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="allegato_fotopiccolaInput">Foto di anteprima dell'associazione</label>
                      <input
                        disabled={this.state.formreadonly}
                        className={`form-control-file ${!valid && "is-invalid"}`}
                        id="allegato_fotopiccolaInput"
                        name="allegato_fotopiccola"
                        onChange={(event) => { this.AllegatiCambiatoHandler(event, inputGroup, "file_campo_fotopiccola", this.state); }}
                        placeholder="Inserisci una foto di anteprima per l'associazione"
                        aria-label="Inserisci una foto di anteprima per l'associazione"
                        accept="image/*"
                        type="file" />
                      {
                        this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true &&
                        this.state.file_campo_fotopiccola.link !== "" &&
                        (<div className="mx-0">{"Il file precedentemente caricato risulta essere "}
                          <a target="_blank" rel="noreferrer"
                            href={this.state.file_campo_fotopiccola.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                            aria-label={this.state.file_campo_fotopiccola.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                            title={this.state.file_campo_fotopiccola.link.toString().replace('../../', '').replaceAll('&amp;', '&')}>
                            Scarica file</a></div>)
                      }
                      {
                        this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                        && this.state.file_campo_fotopiccola.link !== ""
                        && (<Button className="btnlink" aria-label={"Elimina il file " + this.state.file_campo_fotopiccola.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                          title={"Elimina il file " + this.state.file_campo_fotopiccola.link.toString().replace('../../', '').replaceAll('&amp;', '&')} type="submit" onClick={(e) => { if (window.confirm('Sei sicuro di voler eliminare questo record?')) { deleteFile(e, "file_campo_fotopiccola") } else { deleteFileNO(e) } }}>
                          Elimina File
                        </Button>)
                      }
                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3"
                  validate={{
                    "allegato_immaginecopertina": (input) => {

                      if (this.state.file_campo_immaginecopertina.nome === null || this.state.file_campo_immaginecopertina.nome === "") {
                        return true;
                      }
                      else {
                        if (!validfileimage.test(this.state.file_campo_immaginecopertina.nome)) {
                          input.setCustomValidity("Il file caricato deve essere un'immagine");
                          return false;
                        } else {
                          if (this.state.file_campo_allegato_Attivita_Anno_Prec.dimensione > 10000 * 1024) {
                            input.setCustomValidity("Il file caricato non deve superare i 10 MB");
                            return false;
                          }
                          else {
                            return true;
                          }
                        }
                      }

                    }
                  }}
                  translate={{
                    allegato_immaginecopertina: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3">
                      <label htmlFor="allegato_immaginecopertinaInput">Foto di anteprima dell'associazione</label>
                      <input
                        disabled={this.state.formreadonly}
                        className={`form-control-file ${!valid && "is-invalid"}`}
                        id="allegato_immaginecopertinaInput"
                        name="allegato_immaginecopertina"
                        onChange={(event) => { this.AllegatiCambiatoHandler(event, inputGroup, "file_campo_immaginecopertina", this.state); }}
                        placeholder="Inserisci una foto di anteprima per l'associazione"
                        aria-label="Inserisci una foto di anteprima per l'associazione"
                        accept="image/*"
                        type="file" />
                      {
                        this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                        && this.state.file_campo_immaginecopertina.link !== ""
                        && (<div className="mx-0">{"Il file precedentemente caricato risulta essere "}
                          <a target="_blank" rel="noreferrer"
                            href={this.state.file_campo_immaginecopertina.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                            aria-label={this.state.file_campo_immaginecopertina.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                            title={this.state.file_campo_immaginecopertina.link.toString().replace('../../', '').replaceAll('&amp;', '&')}>
                            Scarica file</a></div>)
                      }
                      {
                        this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                        && this.state.file_campo_immaginecopertina.link !== ""
                        && (<Button className="btnlink" aria-label={"Elimina il file " + this.state.file_campo_immaginecopertina.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                          title={"Elimina il file " + this.state.file_campo_immaginecopertina.link.toString().replace('../../', '').replaceAll('&amp;', '&')} type="submit" onClick={(e) => { if (window.confirm('Sei sicuro di voler eliminare questo record?')) { deleteFile(e, "file_campo_immaginecopertina") } else { deleteFileNO(e) } }}>
                          Elimina File
                        </Button>)
                      }
                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <Row className="my-2"><Col>
                  {this.state.actions === "elimina" ?
                    <Button title="Elimina" aria-label="Elimina" type="submit" onClick={(e) => { if (window.confirm('Sei sicuro di voler eliminare questa associazione? Eliminerai l\'associazione e tutti gli eventi collegati ')) { deleteItem(e) } else { deleteItemNo(e) } }}>
                      Elimina
                    </Button>
                    : ""}
                  {this.state.actions === "" || this.state.actions !== "elimina" ?
                    <Button title={this.state.testopulsante} aria-label={this.state.testopulsante} type="submit" disabled={this.state.formreadonly}>{this.state.testopulsante}</Button> : ""}
                </Col></Row>
                {this.state.messaggioAggiornamento}
              </>
            )}
          </Form>
          : <Spinner />
        }
      </div>
    );
  }

  componentDidMount = () => {

    if (this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0) {
      this.performSearchContenuto(this.setRichiedentiResultsContenuto, this.setErrorMessageContenuto, this.performSearchContenuto, this.state);
    }

    this.performSearchTipologiaAssociazioni(this.setRichiedentiResultsTipologiaAssociazioni, this.setErrorMessageTipologiaAssociazioni, this.performSearchTipologiaAssociazioni);

  }
  //#endregion 

  // #region GestioneInvioDati Al Server
  /* In caso ci siano campi con indirizzi se compilati vengono tradotti in coordinate e inviati come campi al server*/
  gestiscicoordinate = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, successgestiscicoordinate, gestiscicoordinate, results, status, keyprec) => {

    var coordinatepresenti = false;
    var indirizzo = '';
    var coordinatakey = "";

    /*Se sono presenti lo status e la chiave, l'indirizzo è stato inviato a Google per essere tradotto in coordinate*/
    /*Mi segno che sono state tradotte e in caso lo stato sia OK imposto anche le coordinate*/
    if (keyprec !== "") {
      state[keyprec].inviato = true;
      if (status !== null) {
        state[keyprec].inviato = true;
        if (status === 'OK') {
          state[keyprec].coordinatax = results[0].geometry.location.lat();
          state[keyprec].coordinatay = results[0].geometry.location.lng();
          state[keyprec].titolo = state[keyprec].campovalore;
        }
      }
    }

    if (this.state.actions !== "elimina") {

      /*Cerco tra i vari campi dello se ci sono degli indirizzi per inviarle a google*/
      for (const [key, value] of Object.entries(state)) {
        if (key.indexOf("campo_") === 0) {
          if (value.coordinatax !== undefined && value.coordinatay !== undefined) {
            if (value.coordinatax === null &&
              value.coordinatay === null && value.campovalore !== null) {
              indirizzo = value.campovalore;
              coordinatepresenti = true;
              coordinatakey = key;
              break;
            }
          }
        }

      }
    }

    /*Se ci sono indirizzi da trasformare in coordinate le provo a trasformare in caso contrario passo alla fase di invio*/
    if (coordinatepresenti) {
      var geocoder = new this.props.google.maps.Geocoder();

      geocoder.geocode({ 'address': indirizzo }, function (results, status) {
        if (status === 'OK') {
          gestiscicoordinate(successFnDb,
            errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, successgestiscicoordinate, gestiscicoordinate, results, status, coordinatakey);
        } else {
          gestiscicoordinate(successFnDb,
            errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, successgestiscicoordinate, gestiscicoordinate, null, status, coordinatakey);
        }
      });
    }
    else {
      successgestiscicoordinate(successFnDb,
        errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state);
    }

  }

  /*Invio contenuto al db*/
  creazioneContenuto = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state) => {

    var TestoAggiornamento = (<div> <strong>Aggiornamento dei dati in corso si prega di attendere</strong></div>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
    });

    var campidaimpostare = [];

    var method = "";
    var eliminarecordacascata = null;

    if (state.idContenuto !== 0) {

      state["testopulsante"] = "Aggiorna Contenuto";

      if (this.state.actions === "" || this.state.actions === "modifica" || this.state.actions === "copia") {
        method = Buffer.from('getUpdateContenuto', 'utf8').toString('base64');
      }

      if (this.state.actions === "elimina") {
        method = Buffer.from('getDeleteContenuto', 'utf8').toString('base64');
        eliminarecordacascata = Buffer.from('true', 'utf8').toString('base64');
      }
    }
    else {
      method = Buffer.from('getinsertcontenuto', 'utf8').toString('base64');
    }

    for (const [key, value] of Object.entries(state)) {

      if (key.indexOf("campo_") === 0 && value !== null) {
        campidaimpostare.push(state[key]);
      }
    }

    var parametriserver = {
      nometabella: Buffer.from('t_associazioni', 'utf8').toString('base64'),
      limiterecord: null,
      idulteriorecondizione: null,
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: null,
      elencocampi: null,
      queryoffset: null,
      querynextrows: null,
      listavaloricondizioni: null,
      contenutiindicizzati: null,
      idcamposelectcustom: null,
      elencotabellecorrelate: null,
      elencocampicorrelate: null,
      idcontenuto: null,
      campivaloridaimpostarequery: campidaimpostare,
      eliminarecordacascata: eliminarecordacascata,
    };

    if (state.idContenuto !== 0) {
      parametriserver.idcontenuto = Buffer.from(this.state.idContenuto.toString(), 'utf8').toString('base64');
    }

    const data = {
      token: this.props.loginToken,
      method: method,
      param: parametriserver,
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const dati = JSON.parse(response.data.data);

          var idinserito = dati;

          if (globals.debug < 3) console.log(dati);

          if (idinserito > 0) {
            successFnDb(successFnDb,
              errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, dati, "");
          }

        } else {

          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);

          if (parseInt(response.data.result.code) === 401) {
            loginErrorFn();
          } else {
            errorFnDb(successFnDb,
              errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, response.data.result.message);
          }


        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata associazioni gestione record admin error: " + error);
        errorFnDb(successFnDb,
          errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, error.message);
      });


    return;
  };

  /*In caso di aggiornamento corretto del DB vengono inviati al server uno alla volta i file*/
  AggiornamentoDBCorretto = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, keyprec) => {

    var allegatipresenti = false;
    var nomecampo = '';
    var file = "";
    var nomefile = "";

    var filekey = "";

    /*Il file che ho inviato in precedenza è stato caricato correttamente*/
    /*Segno che è stato inviato e che è stato inviato con successo*/
    if (keyprec !== "") {
      state[keyprec].inviato = true;
      state[keyprec].errore = false;
    }

    /* Ciclo per i campi dello stato che sono dei campi file e invio quelli che sono stati caricati dall'utente
    e non sono ancora stati inviati*/
    for (const [key, value] of Object.entries(state)) {
      if (key.indexOf("file_campo_") === 0) {
        if (value.base64 !== null && value.nome !== null && value.inviato === false) {
          nomecampo = key.replace("file_campo_", '');
          file = value.base64;
          nomefile = value.nome;
          allegatipresenti = true;
          filekey = key;
          break;
        }
      }
    }

    /* Se il nome del campo è stato compilato (ovvero ho un file da inviare)
    lo encodo in base 64*/
    if (nomecampo !== '') {
      nomecampo = Buffer.from(nomecampo, 'utf8').toString('base64')
    }

    /* Se ho degli allegati da inviare provo ad inviarli in caso contrario vado alla fase di conclusione dell'aggiornamento*/
    if (allegatipresenti) {

      var TestoAggiornamento = (<React.Fragment>{this.state.messaggioAggiornamento}
        <div>  <strong>Invio di un documento in corso si prega di attendere</strong></div></React.Fragment>);

      this.setState({
        messaggioAggiornamento: TestoAggiornamento,
      });

      var parametriserver = {
        nometabella: Buffer.from('t_associazioni', 'utf8').toString('base64'),
        limiterecord: null,
        idulteriorecondizione: null,
        idordinamento: null,
        numeropaginazione: null,
        idselecustom: null,
        selectdistinct: null,
        usaselectwithnolock: null,
        elencocampi: null,
        queryoffset: null,
        querynextrows: null,
        listavaloricondizioni: null,
        contenutiindicizzati: null,
        idcamposelectcustom: null,
        elencotabellecorrelate: null,
        elencocampicorrelate: null,
        idcontenuto: Buffer.from(idDbAggiornato.toString(), 'utf8').toString('base64'),
        base64fileinputfieldname: nomecampo,
        base64fileinputfile: file,
        base64fileinputfilename: nomefile,
      };


      const data = {
        token: this.props.loginToken,
        method: Buffer.from('getinsercontenutoallegato', 'utf8').toString('base64'),
        param: parametriserver,
      };

      const loginErrorFn = () => {
        this.props.effettuaLogout();
      }

      axios.post(globals.wsURLCliente, data)
        .then(function (response) {

          if (response.data.result.success === true) {
            successFnDb(successFnDb,
              errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey);

          } else {
            if (globals.debug < 3) console.log(response.data.result.message);

            if (parseInt(response.data.result.code) === 401) {
              loginErrorFn();
            } else {
              errorFnDb(successFnDb,
                errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, response.data.result.message, idDbAggiornato, filekey);
            }


          }
        })
        .catch(function (error) {
          if (globals.debug < 3) console.log("chiamata associazioni gestione record admin error: " + error);
          errorFnDb(successFnDb,
            errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, error.message, idDbAggiornato, filekey);
        });

    }
    else {
      successFNAggiornamento(successFnDb,
        errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey);

    }

    return;
  }

  /* Se uno dei file va in errore segno l'invio fallito e procedo con il resto delle operazioni*/
  errorDocumenti = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, messaggioErrore, idDbAggiornato, keyprec) => {

    var TestoAggiornamento = (<React.Fragment>{this.state.messaggioAggiornamento}
      <div>  <strong>Invio di un documento in corso si prega di attendere</strong></div></React.Fragment>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
    });


    if (keyprec !== "") {
      state[keyprec].inviato = true;
      state[keyprec].errore = true;
    }

    successFnDb(successFnDb,
      errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato);

  }

  /*Se l'utente lo desidera appena aggiornato un record lo metto in modifica*/
  AbilitaModifica = (event) => {
    this.setState({
      formreadonly: false,
      messaggioAggiornamento: false,
      testopulsante: "Aggiorna Contenuto",
    });
  };

  /*Ho completato l'aggiornamento e do un informazione completa all'utente sullo stato di aggiornamento degli eventuali file*/
  AggiornamentoCompletato = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey) => {

    if (this.state.actions === "elimina") {
      window.history.go(-1);
    }
    else {
      var DocumentiAllegati = [];

      for (const [key, value] of Object.entries(state)) {
        if (key.indexOf("file_campo_") === 0) {
          var nometestualecampo = value.nometestualecampo;
          switch (value.base64) {
            case null:
              if (value.link === undefined || value.link === "" || value.link === null)
                DocumentiAllegati.push(<p>Il documento {nometestualecampo} - è stato deciso di non caricarlo lo si potrà caricare durante l'aggiornamento dello stesso</p>);
              break;
            default:
              if (value.inviato === true && value.errore === false) {
                DocumentiAllegati.push(<p>Il documento {nometestualecampo} - è stato caricato correttamente</p>);
              }
              else {
                DocumentiAllegati.push(<p>Il documento {nometestualecampo} - ha avuto errore in fase di caricamento</p>);
              }
              break;
          }
        }

      }

      DocumentiAllegati.push(<Row><Button type="submit" onClick={this.AbilitaModifica}>Abilita Modifica</Button></Row>);

      var TestoAggiornamento = (<div> <strong>Aggiornamento avvenuto correttamente</strong>{DocumentiAllegati}</div>);

      this.setState({
        messaggioAggiornamento: TestoAggiornamento,
        idContenuto: idDbAggiornato,
      });

      if (this.state.actions === "") {

        this.InviaMail();
      }

      /*A questo punto in caso di insrerimento dovrei mandare una mail*/
    }
  }

  InviaMail = () => {

    this.setState({
      actions: "modifica",
    });

    var message = 'Una nuova associazione è stata inserita - la nuova associazione ha nome ' + this.state.campo_associazioni.campovalore +
      " collegata all'utente con codice fiscale " + this.state.campo_cfprincipale.campovalore + " si prega di accedere al backoffice per abilitare l'associazione" +
      " a gestire gli eventi o cancellarla";

    var subject = 'Nuova associazione inserita';

    var BodyMail = ReactDOMServer.renderToStaticMarkup(<EmailTemplate
      subject={subject}
      message={message}>
    </EmailTemplate>);

    var parametriserver = {
      nometabella: Buffer.from('t_associazioni', 'utf8').toString('base64'),
      limiterecord: null,
      idulteriorecondizione: null,
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: null,
      elencocampi: null,
      queryoffset: null,
      querynextrows: null,
      listavaloricondizioni: null,
      contenutiindicizzati: null,
      idcamposelectcustom: null,
      elencotabellecorrelate: null,
      elencocampicorrelate: null,
      idcontenuto: null,
      base64fileinputfieldname: null,
      base64fileinputfile: null,
      base64fileinputfilename: null,
      mailtemplate: Buffer.from(BodyMail, 'utf8').toString('base64'),
      mailsubject: Buffer.from(subject, 'utf8').toString('base64'),
      mailto: Buffer.from(process.env.REACT_APP_EMAILNUOVAASSOCIAZIONETO.toString(), 'utf8').toString('base64'),
      mailda: Buffer.from(process.env.REACT_APP_EMAILNUOVAASSOCIAZIONEDA.toString(), 'utf8').toString('base64'),
    };


    const data = {
      token: this.props.loginToken,
      method: Buffer.from('sendmail', 'utf8').toString('base64'),
      param: parametriserver,
    };

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          if (globals.debug < 3) console.log(response.data);

        } else {
          if (globals.debug < 3) console.log(response.data.result.message);

        }
      })
      .catch(function (error) {
        if (globals.debug < 3) console.log("chiamata associazioni gestione record admin error: " + error);
      });


  }

  /*Ci sono errori nell'aggiornamento del record avverto l'utente e non provo neanche ad inviare i file*/
  AggiornamentoDBSbagliato = (messaggioErrore) => {

    var TestoAggiornamento = (<div> <strong>Aggiornamento dei dati non possibile si prega di riprovare più tardi</strong></div>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
      formreadonly: false,
    });

  }

  //#endregion 

  //#region Modifica/Copia/Delete contenuto
  /*Per mettere in modifica un record lo carico dal db ed imposto per i vari campi del form il valore di default*/
  performSearchContenuto = (successFn, errorFn, tokenScadutoFn, state) => {

    var parametriserver = {
      nometabella: Buffer.from('t_associazioni', 'utf8').toString('base64'),
      limiterecord: null,
      idulteriorecondizione: null,
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: null,
      // elencocampi: Buffer.from('id;associazioni;associazioni_tipologia;codice_fiscale;Sede_Legale;Sede_Operativa;Partita_IVA;Recapito_Telefonico;PEC;Email;allegato_Statuto;allegato_Atto_Costitutivo;allegato_Libro_Soci;allegato_Attivita_Anno_Prec;fotopiccola;immaginecopertina;Descrizione;codici_fiscaliabilitati;cfprincipale', 'utf8').toString('base64'),
      elencocampi: Buffer.from('id;associazioni;associazioni_tipologia;codice_fiscale;Sede_Legale;Sede_Operativa;Partita_IVA;Recapito_Telefonico;PEC;Email;allegato_Statuto;allegato_Atto_Costitutivo;allegato_Attivita_Anno_Prec;fotopiccola;immaginecopertina;Descrizione;codici_fiscaliabilitati;cfprincipale', 'utf8').toString('base64'),
      queryoffset: null,
      querynextrows: null,
      listavaloricondizioni: null,
      contenutiindicizzati: null,
      idcamposelectcustom: null,
      idcontenuto: Buffer.from(this.props.location.state.idContenutoSelezionato, 'utf8').toString('base64'),
    };

    const data = {
      token: this.props.loginToken,
      method: Buffer.from('getdaticontenuto', 'utf8').toString('base64'),
      param: parametriserver,
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const dati = JSON.parse(response.data.data);

          var countdatidagraficareContenuto = dati[0]["countelementi"];

          var paginaCorrenteContenuto = dati[0]["numeropagina"];

          if (globals.debug < 3) console.log(dati);

          dati.shift();

          successFn(dati, countdatidagraficareContenuto, paginaCorrenteContenuto, state);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          if (parseInt(response.data.result.code) === 401) {
            loginErrorFn();
          } else {
            errorFn(response.data.result.message);
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata associazioni gestione record admin error: " + error);
        errorFn(error.message);
      });


    return;
  }

  setRichiedentiResultsContenuto = (data, countdatidagraficareContenuto, paginaCorrenteContenuto, state) => {

    for (const [key, value] of Object.entries(state)) {

      var chiave = "";

      if (key.indexOf("campo_") === 0) {

        chiave = key.replace("campo_", "").toLocaleLowerCase();

        if (data[0][chiave] !== undefined) {
          state[key].nomecampo = value.nomecampo;
          state[key].campovalore = data[0][chiave];
        }

        if (key.toLowerCase() === "campo_associazioni_tipologia".toLowerCase()) {
          if (data[0]["associazioni_tipologia_nn1"] !== undefined) {
            state[key].nomecampo = value.nomecampo;
            state[key].campovalore = data[0]["associazioni_tipologia_nn1"];
          }
        }


        if (key.toLowerCase() === "campo_Sede_Legale".toLowerCase() || key.toLowerCase() === "campo_sede_operativa".toLowerCase()) {
          state[key].coordinatax = null;
          state[key].coordinatay = null;
        }
      }

      if (key.indexOf("file_campo_") === 0) {
        chiave = key.replace("file_campo_", "").toLocaleLowerCase();
        if (data[0][chiave] !== undefined) {
          state[key].nometestualecampo = value.nometestualecampo;
          state[key].base64 = null;
          state[key].nome = data[0][chiave];
          if (data[0][chiave + "_link"] !== "" && data[0][chiave + "_link"] !== undefined && this.state.actions !== "copia") {
            state[key].link = process.env.REACT_APP_PUBLIC_URL + data[0][chiave + "_link"]
          }
          else {
            state[key].link = "";
          }
        }
      }
    }

    var idcontenuto = 0;
    var testopulsante = "Inserisci contenuto"

    if (this.state.actions === "copia") {
      idcontenuto = 0;
    }
    else {
      idcontenuto = this.state.idContenutoSelezionato;
      testopulsante = "Aggiorna contenuto"
    }

    this.setState({
      ricercaEseguitaContenuto: true,
      risultatiRicercaContenuto: data,
      idContenuto: idcontenuto,
      testopulsante: testopulsante,
    });


  }

  setErrorMessageContenuto = (errorMessage) => {
    this.setState({
      messaggioErroreContenuto: errorMessage,
      ricercaEseguitaContenuto: true,
    });
  }

  // #endregion    

  //#region Ricerca tipologia associazione
  performSearchTipologiaAssociazioni = (successFn, errorFn, tokenScadutoFn) => {

    var parametriserver = {
      nometabella: Buffer.from('t_associazioni_tipologia', 'utf8').toString('base64'),
      limiterecord: Buffer.from(this.state.risultatiPerPaginaTipologiaAssociazioni.toString(), 'utf8').toString('base64'),
      idulteriorecondizione: Buffer.from('5', 'utf8').toString('base64'),
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
      elencocampi: Buffer.from('id;associazioni_tipologia;', 'utf8').toString('base64'),
      queryoffset: Buffer.from(this.state.queryOffsetTipologiaAssociazioni.toString(), 'utf8').toString('base64'),
      querynextrows: Buffer.from(this.state.risultatiPerPaginaTipologiaAssociazioni.toString(), 'utf8').toString('base64'),
      listavaloricondizioni: null,
      contenutiindicizzati: Buffer.from("false", 'utf8').toString('base64'),
      idcamposelectcustom: null
    };

    const data = {
      token: this.props.loginToken,
      method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
      param: parametriserver,
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const dati = JSON.parse(response.data.data);

          var countdatidagraficareTipologiaAssociazioni = dati[0]["countelementi"];

          var paginaCorrenteTipologiaAssociazioni = dati[0]["numeropagina"];

          if (globals.debug < 3) console.log(dati);

          dati.shift();

          successFn(dati, countdatidagraficareTipologiaAssociazioni, paginaCorrenteTipologiaAssociazioni);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);

          if (globals.debug < 3) console.log(response.data.result);

          if (parseInt(response.data.result.code) === 401) {
            loginErrorFn();
          } else {
            errorFn(response.data.result.message);
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata associazioni gestione record admin error: " + error);
        errorFn(error.message);
      });


    return;
  }

  setRichiedentiResultsTipologiaAssociazioni = (data, countdatidagraficareTipologiaAssociazioni, paginaCorrenteTipologiaAssociazioni) => {

    this.setState({
      risultatiRicercaTipologiaAssociazioni: data,
      messaggioErroreTipologiaAssociazione: null,
      paginaCorrenteTipologiaAssociazioni: paginaCorrenteTipologiaAssociazioni,
      ricercaEseguitaTipologiaAssociazioni: true,
      countdatidagraficareTipologiaAssociazioni: countdatidagraficareTipologiaAssociazioni
    });
  }

  setErrorMessageTipologiaAssociazioni = (errorMessage) => {
    this.setState({
      messaggioErroreTipologiaAssociazione: errorMessage,
      ricercaEseguitaTipologiaAssociazioni: true,
    });
  }

  renderRisultatiRicercaTipologiaAssociazioni = (valid) => {

    var listaselezionati = [];

    if (this.state.campo_associazioni_tipologia.campovalore !== null) {
      listaselezionati = this.state.campo_associazioni_tipologia.campovalore.split(',');
    }

    listaselezionati.forEach(element => {
      element = parseInt(element.trim());
    });

    const risultati = this.state.risultatiRicercaTipologiaAssociazioni.map((obj, i) => {

      var checked = false;

      listaselezionati.forEach(element => {
        if (parseInt(obj.id) === parseInt(element)) {
          checked = true;
        }
      });

      return (
        <div role="group" aria-label={obj.eventi_tipologia + obj.id} className="form-group" key={'divinline-checkbox-' + obj.id}>
          <input
            onChange={(event) => { this.associazioni_tipologiaCambiatoHandler(event); }}
            name="tipologia_associazione"
            type="checkbox"
            className={`form-check-input ${!valid && "is-invalid"}`}
            id={'inline-checkbox-' + obj.id}
            checked={checked}
            disabled={this.state.formreadonly}
            value={obj.id} />
          <label className="form-check-label" htmlFor={'inline-checkbox-' + obj.id}>{obj.associazioni_tipologia}</label>
        </div>
      );
    });

    return risultati;
  }

  EliminazioneFile = (successEliminaFile,
    errorEliminaFile, state, key) => {

    var nomecampo = key.replace("file_campo_", '')
    var nomefile = state[key].nome;

    nomecampo = Buffer.from(nomecampo, 'utf8').toString('base64');

    var TestoAggiornamento = (<React.Fragment>{this.state.messaggioAggiornamento}
      <div>  <strong>Cancellazione di un documento in corso si prega di attendere</strong></div></React.Fragment>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
    });

    var parametriserver = {
      nometabella: Buffer.from(this.state.nometabellacontenuto.toString(), 'utf8').toString('base64'),
      limiterecord: null,
      idulteriorecondizione: null,
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: null,
      elencocampi: null,
      queryoffset: null,
      querynextrows: null,
      listavaloricondizioni: null,
      contenutiindicizzati: null,
      idcamposelectcustom: null,
      elencotabellecorrelate: null,
      elencocampicorrelate: null,
      idcontenuto: Buffer.from(this.state.idContenutoSelezionato.toString(), 'utf8').toString('base64'),
      base64fileinputfieldname: nomecampo,
      base64fileinputfile: null,
      base64fileinputfilename: nomefile,
    };


    const data = {
      token: this.props.loginToken,
      method: Buffer.from('getdeletecontenutoallegato', 'utf8').toString('base64'),
      param: parametriserver,
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          successEliminaFile(key, state);

        } else {
          if (globals.debug < 3) console.log(response.data.result.message);

          if (parseInt(response.data.result.code) === 401) {
            loginErrorFn();
          } else {
            errorEliminaFile();
          }


        }
      })
      .catch(function (error) {
        if (globals.debug < 3) console.log("chiamata associazioni gestione record admin error: " + error);
        errorEliminaFile();
      });

    return;
  }

  ContenutoEliminatoOK = (key, state) => {
    var TestoAggiornamento = "";

    state[key].base64 = null;
    state[key].nome = "";
    state[key].link = "";

    this.setState({
      ricercaEseguitaContenuto: false,
      messaggioAggiornamento: TestoAggiornamento,
    }, function () {
      this.ContenutoEliminatoOKAggiornamento();
    });
  }

  ContenutoEliminatoOKAggiornamento = () => {
    this.setState({
      ricercaEseguitaContenuto: true,
    });
  }

  ContenutoEliminatoKO = () => {
    var TestoAggiornamento = (<div> <strong>Aggiornamento dei dati non possibile si prega di riprovare più tardi</strong></div>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
      formreadonly: false,
    });
  }

}
// #endregion    

// #region Redux
const mapStateToProps = state => {
  return {
    loginToken: state.loginToken,
    userProfile: state.userProfile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    effettuaLogout: () => dispatch(actions.effettuaLogout()),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper(
  (props) => ({
    apiKey: "AIzaSyA-a0pZN7pE4naZqWMXz-alvrUgKkaJUDc",
    libraries: ['places', 'visualization'],
    language: "it",
  })
)(AssociazioniGestioneRecordAdmin));
