import * as actionTypes from './actionTypes';
import axios from 'axios';
import * as globals from '../comuni/constants';


// #region login
export const effettuaLogoutSuccesso = () => {

  var loginData = {
    token: globals.tokenGenerico,
    username: '',
    nome: null,
    cognome: null,
    email: null,
    cf: null
  };

  return {
    type: actionTypes.EFFETTUA_LOGOUT,
    loginData: loginData,
  };
}

export const effettuaLogout = () => {
  setTimeout(() => { window.location = '/AutorizzazioniLogin' }, 100);
  return (dispatch, getState) => {
    dispatch(effettuaLogoutSuccesso());
  }
}

export const impostatokengenericoSuccesso = () => {

  var loginData = {
    token: globals.tokenGenerico,
    username: '',
    nome: null,
    cognome: null,
    email: null,
    cf: null
  };

  return {
    type: actionTypes.IMPOSTA_TOKENGENERICO,
    loginData: loginData,
  };
}

export const impostatokengenerico = (path) => {
  setTimeout(() => { window.location = '/' + path }, 100);
  return (dispatch, getState) => {
    dispatch(impostatokengenericoSuccesso());
  }
}

export const effettuaLoginSuccesso = (loginData) => {
  console.log("Login data" + loginData.cf);
  setTimeout(() => { window.location = '/AssociazioniRicercaAdmin' }, 500);
  return {
    type: actionTypes.EFFETTUA_LOGIN_SUCCESSO,
    loginData: loginData
  };
}

export const effettuaLoginErrore = (codiceErrore, messaggioErroreLogin) => {
  return {
    type: actionTypes.EFFETTUA_LOGIN_ERRORE,
    messaggioErroreLogin: messaggioErroreLogin,
    codiceErroreLogin: codiceErrore,
  };
}

export const effettuaLogin = (userprofile) => {

  effettuaLoginSuccesso(userprofile);
};
// #endregion login

export const CaricaMenuSuccesso = (MenuData) => {
  return {
    type: actionTypes.CARICA_MENU_SUCCESSO,
    menu: MenuData
  };
}

export const CaricaMenuErrore = (messaggioErroreCaricaMenu) => {
  return {
    type: actionTypes.CARICA_MENU_ERRORE,
    messaggioErroreCaricaMenu: messaggioErroreCaricaMenu,
  };
}

export const caricaMenu = (logintoken) => {

  return (dispatch, getState) => {

    var parametriserver = {
      nometabella: Buffer.from('t_strutturapag', 'utf8').toString('base64'),
      limiterecord: Buffer.from("1000", 'utf8').toString('base64'),
      idulteriorecondizione: null,
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
      elencocampi: Buffer.from('id;nome;StrutturaPag_frame;StrutturaPag_Sezione;strutturapag_SeqVisualizz;strutturapag_Area;strutturapag_Categoria;strutturapag_Pagina;strutturapag_Sottopagina;visibile;linkesterno', 'utf8').toString('base64'),
      queryoffset: Buffer.from("0", 'utf8').toString('base64'),
      querynextrows: Buffer.from("1000", 'utf8').toString('base64'),
      listavaloricondizioni: null,
      contenutiindicizzati: null,
      idcamposelectcustom: null
    };

    const data = {
      token: logintoken,
      method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
      param: parametriserver,
    };




    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const data = JSON.parse(response.data.data);

          if (globals.debug < 3) console.log(data);

          data.shift();

          dispatch(CaricaMenuSuccesso(data));

        } else {

          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);

          if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
            dispatch(caricaMenu(logintoken));
          }
          else {
            if (response.data.result.code === 401) {
              // loginErrorFn();
            } else {
              // errorFn(response.data.result.message);
              dispatch(CaricaMenuErrore(response.data.result.message));
            }
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata actions error: " + error);
        dispatch(CaricaMenuErrore(error.message));
      });

  }


};
// #endregion login



