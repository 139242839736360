import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Form, InputGroup } from "react-html5-form";
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import axios from 'axios';
import { Redirect } from "react-router-dom";


import {readCookie} from '../utility/webUtilities';

class AutorizzazioniDatiPersonaliLogin extends Component {

  constructor(props) {

    super(props);

    this.state = {
      redirectToSuccess: false,
      redirectToErrore: false,
    };
  }


  // #region Eventi



  // #endregion


  // #region  Render
  render() {

    const handlerEffettuaRicerca = (form) => {

      var validataform = form.checkValidity();

      if (validataform === false) {
      }
      else {

        this.effettuaLogin(this.LoginOK, this.LoginOK);
      }
    };

    if (this.state.redirectToSuccess === true) {
      return <Redirect push to={{ pathname: "AutorizzazioniSuccessLogin" }} />
    }

    if (this.state.redirectToErrore === true) {
      return <Redirect push to={{ pathname: "AutorizzazioniErrorLogin", state: { errore: "erroregenericodatipersonali" } }} />
    }


    return (

      <div className="px-5">
        <Form onSubmit={handlerEffettuaRicerca} >
          {({ error, valid, pristine, submitting, form }) => (
            <>
              {error && (<div className="alert alert-danger mx-0" role="alert">
                <strong>Errori durante la compilazione del dato</strong> {error}
              </div>)
              }
              <div className="form-check">
                <InputGroup className="pb-3 clearfix "
                  validate={["ChkAccettoLettura"]}
                  translate={{
                    ChkAccettoLettura: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                      typeMismatch: "Quello inserito non è un indirizzo mail valido"
                    }
                  }}>
                  {({ error, valid }) => (
                    <div className="form-group pb-3 clearfix ">
                      <input
                        className={`form-check-input ${!valid && "is-invalid"}`}
                        required={true}
                        id="ChkAccettoLetturaDatiPersonali"
                        name="ChkAccettoLettura"
                        type="checkbox"
                        aria-label="Dichiaro di aver preso visione dell'informativa sul trattamento di dati
                          personali."
                        placeholder="Dichiaro di aver preso visione dell'informativa sul trattamento di dati
                          personali." />
                      <label htmlFor="ChkAccettoLetturaDatiPersonali">(*) Dichiaro di aver preso visione dell'informativa sul trattamento di dati
                        personali.</label>
                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3 clearfix "
                  validate={["ChkAccettoConsenso"]}
                  translate={{
                    ChkAccettoConsenso: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                      typeMismatch: "Quello inserito non è un indirizzo mail valido"
                    }
                  }}>
                  {({ error, valid, }) => (
                    <div className="form-group pb-3 clearfix ">
                      <input
                        className={`form-check-input ${!valid && "is-invalid"}`}
                        required={true}
                        id="ChkAccettoConsensoDatiPersonali"
                        name="ChkAccettoConsenso"
                        type="checkbox"
                        aria-label="Consento al trattamento dei miei dati personali."
                        placeholder="Consento al trattamento dei miei dati personali." />
                      <label htmlFor="ChkAccettoConsensoDatiPersonali">(*) Consento al trattamento dei miei dati personali.</label>
                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>

                <div className="form-group">
                  <div className="form-check">
                    <a rel="noreferrer" href={process.env.REACT_APP_PAGINAPRIVACY} id="formid_LinkPrivacy" target="_blank" title="1">Pagina della privacy</a>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <Button title="Cerca nel sito" aria-label="Cerca nel sito" name="BtnDatiPersonali" id="BtnDatiPersonali" className="btn btn-primary my-1"
                      type="submit">Invia
                    </Button>
                  </div>

                </div>
              </div>
            </>
          )}
        </Form>
      </div>
    );
  }
  // #endregion

  // #region Ciclo di vita del container
  /// Metodi del ciclo di vita del container
  componentDidMount = () => {

    
  }



  getUserIdFromCookie = () => {
    var userTokenInfo = readCookie("userTokenInfo");
    
    const userTokenInfoParams = new URLSearchParams(userTokenInfo);
    
    const userTokenInfoUserId = userTokenInfoParams.get('UserId');
    console.log("userTokenInfoUserId",userTokenInfoUserId);
    return userTokenInfoUserId;

  }



  componentDidUpdate = (propsPrecedenti, statePrecedente) => {
  }
  // #endregion


  LoginOK = () => {
    this.setState({
      redirectToSuccess: true,
      redirectToErrore: false,
    });
  }

  LoginKO = () => {

    this.setState({
      redirectToSuccess: false,
      redirectToErrore: true,
    });
  }

  effettuaLogin = (successFn,
    errorFn) => {


      


    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    var userid = params["userid"];
    //var userid = this.getUserIdFromCookie();


    if (userid !== undefined) {
      userid = Buffer.from(userid, 'utf8').toString('base64');
    }
    else {
      userid = null;
    }

    var parametriserver = {
      nometabella: Buffer.from('T_eventi', 'utf8').toString('base64'),
      limiterecord: null,
      idulteriorecondizione: null,
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: null,
      elencocampi: null,
      queryoffset: null,
      querynextrows: null,
      listavaloricondizioni: null,
      contenutiindicizzati: null,
      idcamposelectcustom: null,
      elencotabellecorrelate: null,
      elencocampicorrelate: null,
      idcontenuto: null,
      campivaloridaimpostarequery: null,
      tipologin: Buffer.from('SPID', 'utf8').toString('base64'),
      userid: userid,
    };

    const data = {
      token: globals.tokenGenerico,
      method: Buffer.from("datipersonali", 'utf8').toString('base64'),
      param: parametriserver,
    };

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success

          successFn();

        } else {

          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);

          errorFn();


        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata dati personali error: " + error);
        errorFn();
      });


    return;
  };


}
// #endregion

// #region Redux
const mapStateToProps = state => {
  return {
    loginToken: state.loginToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    effettuaLogout: () => dispatch(actions.effettuaLogout()),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(AutorizzazioniDatiPersonaliLogin);
