import * as actionTypes from './actionTypes';
import * as globals from '../comuni/constants';
import addMinutes from 'date-fns/addMinutes';

const initialState = {
    messaggioErroreCaricamentoMessaggiSezione: null,
    loginToken: globals.tokenGenerico,
    userProfile: {
        token: globals.tokenGenerico,
        username: '',
        nome: null,
        cognome: null,
        email: null,
        cf: null,
    },
    messaggioErroreLogin: null,
    menu: null,
    dataoracreazionemenu: null,
    messaggioErroreCaricaMenu: null,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CARICA_MENU_SUCCESSO:
            var datamenusuccesso = new Date();
            console.log(datamenusuccesso);
            var datamenusuccessominuti =  addMinutes(datamenusuccesso, 30);
            console.log(datamenusuccessominuti);
            return {
                ...state,
                menu: action.menu,
                dataoracreazionemenu: datamenusuccessominuti,
                messaggioErroreCaricaMenu: null,
            }

        case actionTypes.CARICA_MENU_ERRORE:
            return {
                ...state,
                menu: null,
                messaggioErroreCaricaMenu: action.messaggioErroreCaricaMenu,
            }

        case actionTypes.EFFETTUA_LOGOUT:
            return {
                ...state,
                loginToken: action.loginData.token,
                userProfile: action.loginData,
                messaggioErroreLogin: null,
                codiceErroreLogin: null,
            }

        case actionTypes.IMPOSTA_TOKENGENERICO:
            return {
                ...state,
                loginToken: action.loginData.token,
                userProfile: action.loginData,
                messaggioErroreLogin: null,
                codiceErroreLogin: null,
            }


        case actionTypes.EFFETTUA_LOGIN_SUCCESSO:
            return {
                ...state,
                loginToken: action.loginData.token,
                userProfile: action.loginData,
                messaggioErroreLogin: null,
                codiceErroreLogin: null,
            }

        case actionTypes.EFFETTUA_LOGIN_ERRORE:
            let messaggioErroreLogin = action.messaggioErroreLogin;
            if (state.messaggioErroreLogin === action.messaggioErroreLogin) {
                messaggioErroreLogin = action.messaggioErroreLogin + " ";
            }
            return {
                ...state,
                loginToken: null,
                userProfile: null,
                messaggioErroreLogin: messaggioErroreLogin,
                codiceErroreLogin: action.codiceErroreLogin,
            }

        case actionTypes.SCARICA_MESSAGGI_SEZIONE_SUCCESSO:
            return {
                ...state,
                cacheMessaggiASSO: [...action.messaggiSezione, ...state.cacheMessaggiASSO],
                messaggioErroreCaricamentoMessaggiSezione: null,
            }

        case actionTypes.SCARICA_MESSAGGI_SEZIONE_ERRORE:
            return {
                ...state,
                cacheMessaggiASSO: null,
                messaggioErroreCaricamentoMessaggiSezione: action.messaggio
            }



        case "@@INIT":
            break;

        default:
            console.log("Action sconosciuta: " + action.type);
    }
    return state;
};

export default reducer;