import React, { Component } from 'react';
import Spinner from '../components/Spinner';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import ContenutoBlocco from '../components/ContenutoBlocco';


// install Swiper modules
SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

class AssociazioniGestioneRecordUtente extends Component {


    constructor(props) {

        super(props);

        this.state = {
            risultatiRicercaAssociazione: null,
            risultatiRicercaAllegati: null,
            risultatiRicercaCoordinate: null,
            risultatiPerPaginaAssociazione: 3,
            queryOffsetAssociazione: 0,
            ricercaEseguitaAssociazione: false,
            ricercaEseguitaAllegati: false,
            ricercaEseguitaCoordinate: false,
            messaggioErrore: null,
            countdatidagraficareAssociazione: null,
            numerogiornisaltati: null,
            idrecord:0,
        };
    }

    // #region Contenuto

    chiudiModaleErroreClicked = () => {
        this.setState({ redirectToHome: true });
    }

    cambiaPaginaHandlerAssociazione = (numeropaginacliccata) => {
        this.setState({
            queryOffsetAssociazione: (numeropaginacliccata - 1) * this.state.risultatiPerPaginaAssociazione
        });
    }

    // #endregion


    // #region  Render
    render() {

        return (
            <div className="clearfix" id="dettaglio">
                {this.state.ricercaEseguitaAllegati === false ||   this.state.ricercaEseguitaCoordinate === false ||
                this.state.ricercaEseguitaAssociazione === false 
                    ?
                    <Spinner />
                    :
                    <ContenutoBlocco
                    idelemento='t_associazione_attivo'
                    nomecampoTitoloCopertina="titolocopertina"
                    nomecampoSottotitoloCopertina="sottotitolocopertina"
                    nomecampoImmagineCopertina="immaginecopertina"
                    nomecampoTestoPulsanteCopertina="testopulsantecopertina"
                    nomecampoLinkCopertina="linkcopertina"
                    nomecampoTitolo="associazioni"
                    nomecampoFotoGrande="fotogrande"
                    nomecampoDescrizione="descrizione"
                    testonessuncontenuto='Nessun contenuto presente in questa sezione'
                    nomecampoEmail='email'
                    nomecampoDatainizio='datainizio'
                    nomecampoDatafine='datafine'
                    nomecampoOrario='orario'
                    nomecampoLuogo='luogo'
                    nomecampoTelefono='recapito_telefonico'
                    nomecampoLink='link'
                    nomecampoSede_Legale='sede_legale'
                    nomecampoSede_Operativa='sede_operativa'
                    nomecampoCodice_Fiscale='codice_fiscale'
                    nomecampoPartita_IVA='partita_iva'
                    nomecampopec='pec'
                    // nomecampifile='allegato_Statuto,Statuto;allegato_Atto_Costitutivo,Atto Costitutivo;allegato_Libro_Soci,Libro Soci;allegato_Attivita_Anno_Prec,Attività precedenti'
                    nomecampifile=''
                    risultati={this.state.risultatiRicercaAssociazione}
                    risultatiallegati={this.state.risultatiRicercaAllegati}
                    risultaticoordinate={this.state.risultatiRicercaCoordinate}>
                </ContenutoBlocco>
                }

            </div>
        );
    }
    // #endregion

    // #region Ciclo di vita del container
    /// Metodi del ciclo di vita del container
    componentDidMount = () => {
        if (window.location.href.indexOf("ID=") > 0) {
            var idContenuto = window.location.href.substring(window.location.href.indexOf("ID=") + ("ID=").length);

            if (idContenuto.indexOf("#") > 0) {
                idContenuto = idContenuto.substring(0, idContenuto.indexOf("#"));
            }

            this.setState({
                idrecord: idContenuto
            });
        }

        this.performSearchAssociazione(this.setRichiedentiResultsAssociazione, this.setErrorMessageAssociazione, this.performSearchAssociazione);
        this.performSearchAllegati(this.setRichiedentiResultsAllegati, this.setErrorMessageAllegati, this.performSearchAllegati);
        this.performSearchCoordinate(this.setRichiedentiResultsCoordinate, this.setErrorMessageCoordinate, this.performSearchCoordinate);
    }

    // #endregion

    setRichiedentiResultsAssociazione = (data, countdatidagraficareAssociazione, paginaCorrenteAssociazione) => {
        this.setState({
            risultatiRicercaAssociazione: data,
            messaggioErrore: null,
            paginaCorrenteAssociazione: paginaCorrenteAssociazione,
            ricercaEseguitaAssociazione: true,
            countdatidagraficareAssociazione: countdatidagraficareAssociazione
        });
    }

    setRichiedentiResultsAllegati = (data, countdatidagraficareAssociazione, paginaCorrenteAssociazione) => {
        this.setState({
            risultatiRicercaAllegati: data,
            messaggioErrore: null,
            ricercaEseguitaAllegati: true,
        });
    }

    setRichiedentiResultsCoordinate = (data, countdatidagraficareAssociazione, paginaCorrenteAssociazione) => {
        this.setState({
            risultatiRicercaCoordinate: data,
            messaggioErrore: null,
            ricercaEseguitaCoordinate: true,
        });
    }    

    setErrorMessageAssociazione = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaAssociazione: true,
        });
    }

    setErrorMessageAllegati = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaAllegati: true,
        });
    }

    setErrorMessageCoordinate = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaCoordinate: true,
        });
    }


    //#region Logica applicativa )con Rendering
    performSearchAssociazione = (successFn, errorFn, tokenScadutoFn) => {

        var idContenuto = 0;

        if (window.location.href.indexOf("ID=") > 0) {
            idContenuto = window.location.href.substring(window.location.href.indexOf("ID=") + ("ID=").length);

            if (idContenuto.indexOf("#") > 0) {
                idContenuto = idContenuto.substring(0, idContenuto.indexOf("#"));
            }
        }

        var parametriserver = {
            nometabella: Buffer.from('t_associazioni', 'utf8').toString('base64'),
            limiterecord: null,
            idulteriorecondizione: null,
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;associazioni;Sede_Legale;Sede_Operativa;Codice_Fiscale;Partita_IVA;Recapito_Telefonico;PEC;Email;allegato_Statuto;allegato_Atto_Costitutivo;allegato_Libro_Soci;allegato_Attivita_Anno_Prec;associazioni_tipologia;fotopiccola;fotogrande;titolocopertina;sottotitolocopertina;immaginecopertina;testopulsantecopertina;linkcopertina;descrizione', 'utf8').toString('base64'),
            queryoffset: null,
            querynextrows: null,
            listavaloricondizioni: null,
            contenutiindicizzati: null,
            idcamposelectcustom: null,
            idcontenuto: Buffer.from(idContenuto, 'utf8').toString('base64')
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdaticontenuto', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.impostatokengenerico();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const dati = JSON.parse(response.data.data);

                    var countdatidagraficareAssociazione = dati[0]["countelementi"];

                    var paginaCorrenteAssociazione = dati[0]["numeropagina"];

                    if (globals.debug < 3) console.log(data);

                    dati.shift();

                    successFn(dati, countdatidagraficareAssociazione, paginaCorrenteAssociazione);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata associazioni gestione record utente error: " + error);
                errorFn(error.message);
            });


        return;
    }

    performSearchAllegati = (successFn, errorFn, tokenScadutoFn) => {

        var idContenuto = 0;

        if (window.location.href.indexOf("ID=") > 0) {
            idContenuto = window.location.href.substring(window.location.href.indexOf("ID=") + ("ID=").length);

            if (idContenuto.indexOf("#") > 0) {
                idContenuto = idContenuto.substring(0, idContenuto.indexOf("#"));
            }
        }

        var parametriserver = {
            nometabella: Buffer.from('t_archiviallegati', 'utf8').toString('base64'),
            limiterecord: null,
            idulteriorecondizione: null,
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('ID;funzioni;IndiceContenuti;filtroDati;filtroDatiLeggibile;nome_tabella_Leggibile;archiviallegati_template;TitoloMenu;TestoMenu;titolovisibilemenu;select_completa;nome_tabella;codiceordinamento', 'utf8').toString('base64'),
            queryoffset: null,
            querynextrows: null,
            listavaloricondizioni: null,
            contenutiindicizzati: null,
            idcamposelectcustom: null,
            idcontenuto: Buffer.from(idContenuto, 'utf8').toString('base64'),
            idfunzione: null,
            nomefunzionetabellaallegata: Buffer.from('t_associazioni', 'utf8').toString('base64'),
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatiallegati', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.impostatokengenerico();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const dati = JSON.parse(response.data.data);

                    if (globals.debug < 3) console.log(dati);

                    dati.shift();

                    successFn(dati);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata associazioni gestione record utente error: " + error);
                errorFn(error.message);
            });


        return;
    }

    performSearchCoordinate = (successFn, errorFn, tokenScadutoFn) => {

        var idContenuto = 0;

        if (window.location.href.indexOf("ID=") > 0) {
            idContenuto = window.location.href.substring(window.location.href.indexOf("ID=") + ("ID=").length);

            if (idContenuto.indexOf("#") > 0) {
                idContenuto = idContenuto.substring(0, idContenuto.indexOf("#"));
            }
        }

        var parametriserver = {
            nometabella: Buffer.from('t_coordinatecontenuti', 'utf8').toString('base64'),
            limiterecord: null,
            idulteriorecondizione: null,
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('ID;funzioni;IndiceContenuti;nome;coordinatax;coordinatay', 'utf8').toString('base64'),
            queryoffset: null,
            querynextrows: null,
            listavaloricondizioni: null,
            contenutiindicizzati: null,
            idcamposelectcustom: null,
            idcontenuto: Buffer.from(idContenuto, 'utf8').toString('base64'),
            idfunzione: null,
            nomefunzionetabellaallegata: Buffer.from('t_associazioni', 'utf8').toString('base64'),
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatiallegati', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.impostatokengenerico();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const dati = JSON.parse(response.data.data);

                    if (globals.debug < 3) console.log(dati);

                    dati.shift();

                    successFn(dati);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata associazioni gestione record utente error: " + error);
                errorFn(error.message);
            });


        return;
    }

}
// #endregion

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        impostatokengenerico: () => dispatch(actions.impostatokengenerico('/Associazione/id=' + this.setState.idrecord)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(AssociazioniGestioneRecordUtente);
