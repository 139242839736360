import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { spidprovider, spidError } from '../comuni/funzioni';

class AutorizzazioniErrorLogin extends Component {

    constructor(props) {

        super(props);

        this.state = {
            csspulsanteEntra: "",
            style: "",
            errorMessage: "",
        };
    }

    ApriChiudiBottone = () => {

        var valoreclasse = this.state.csspulsanteEntra;

        var cssContProvider = "";

        if (valoreclasse === "") {
            valoreclasse = "spid-idp-button-open";
            cssContProvider = "d-block";
        }
        else {
            valoreclasse = "";
            cssContProvider = "none";
        }

        this.setState({
            csspulsanteEntra: valoreclasse,
            cssContProvider: cssContProvider,
        });
    }

    // #region Eventi



    // #endregion


    // #region  Render
    render() {

        const handlereaprichiudibottone = () => {
            this.ApriChiudiBottone();
        };

        var provider = spidprovider();


        return (
            <div id="ArchivioContenuti" style={{ textAlign: "center" }} className="py-5">
                {this.state.errorMessage === "" ? "" : (
                    <div className="feedback_box">
                        <div className="feedback_box_errore_forte_text">
                            <strong><span id="formid_BottoneSPID_lblTitoloErrore">ACCESSO NEGATO: </span></strong>
                            <span id="formid_BottoneSPID_lblMessaggioErrore">{this.state.errorMessage}</span>
                        </div>
                    </div>
                )}
                <button onClick={handlereaprichiudibottone} className={"italia-it-button italia-it-button-size-m button-spid " + this.state.csspulsanteEntra}
                    spid-idp-button="#spid-idp-button-medium-get" aria-haspopup="true" aria-expanded="false"
                    title="Entra con SPID">
                    <span className="italia-it-button-icon">
                        <img src="../../immagini/spid/spid-ico-circle-bb.svg" alt="" /></span>
                    <span className="italia-it-button-text">Entra con SPID</span>
                </button>
                <div id="spid-idp-button-medium-get" style={{ textAlign: "center", margin: "0 auto", position: 'relative' }} className={"spid-idp-button spid-idp-button-tip spid-idp-button-relative " + this.state.cssContProvider}>
                    <ul id="spid-idp-list-medium-root-get" className="spid-idp-button-menu" aria-labelledby="spid-idp">
                        {
                            provider
                        }
                    </ul>
                </div>  </div>
        );
    }
    // #endregion

    // #region Ciclo di vita del container
    /// Metodi del ciclo di vita del container
    componentDidMount = () => {

        this.setErroreMessaggi();

    }

    componentDidUpdate = (propsPrecedenti, statePrecedente) => {
        if (statePrecedente.errorMessage !== this.state.errorMessage) {
            this.setErroreMessaggi();
        }
    }
    // #endregion

    setRichiedentiResultsEventiAttivi = (data, countdatidagraficareEventiAttivi, paginaCorrenteEventiAttivi) => {
        /*Positivo*/
    }



    setErroreMessaggi = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        var errorMessage = params["errorText"];

        var codeError = params["statusMessage"];
        if (codeError !== undefined && codeError.toString().toLowerCase().startsWith("errorcode")) {
            console.log(codeError);
            errorMessage = spidError(parseInt(codeError.substring(codeError.indexOf("nr") + 2)));;
        }

        this.setState({
            errorMessage: errorMessage,
        });


    }


}
// #endregion

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(AutorizzazioniErrorLogin);
