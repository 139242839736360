import React from 'react';
import 'swiper/swiper-bundle.min.css';
import GoogleMapsComponent from '../components/GoogleMapsComponent';

class CoordinateContenuti {

    constructor(tabelleAllegate) {
        this.tabelleAllegate = tabelleAllegate;
    }

    getRender() {

        var Risultato = (<div className="mappaGoogle" aria-hidden="true">
            <GoogleMapsComponent
                risultati={Object.values(this.tabelleAllegate)}></GoogleMapsComponent>

        </div>);

        return Risultato;
    }



}


export default CoordinateContenuti;
