import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

class ImmaginiAllegate {

    constructor(tabelleAllegate) {
        this.tabelleAllegate = tabelleAllegate;
    }

    getRender() {

        var htmlTabellaAllegata = [];

        var numitems = Object.values(this.tabelleAllegate).length;

        var classslid = "col-lg-4";

        var slidep = 3;

        switch (numitems) {
            case 1:
                classslid = "col-sm-12 col-lg-12";
                slidep = "1";
                break
            case 2:
                classslid = "col-sm-6 col-lg-6";
                slidep = "2";
                break
            default:
                classslid = "col-sm-6 col-lg-4";
                slidep = "3";
                break

        }

        Object.values(this.tabelleAllegate).map((item, i) => {

            var link = "";
            var titolo = item.titolo;

            if (item.foto_grande_allegatopresente) {
                link = process.env.REACT_APP_PUBLIC_URL + item.foto_grande_link.toString().replace('../../', '').replaceAll('&amp;', '&');
            }



            htmlTabellaAllegata.push(
                <SwiperSlide key={item.tabellafiltro + i} id={item.tabellafiltro + i} className={"col-12 " + classslid + " SwiperSlideInterno contenutoallegato"}>
                    <article className="card-wrapper">
                        <div className="card card-teaser shadow rounded border">
                            <img src={link} alt={titolo} title={titolo}></img>
                        </div>
                    </article>
                </SwiperSlide>);

            return "";
        });

        const pagination = {
            "clickable": true,
            "renderBullet": function (index, className) {
                return '<span class="' + className + '" title="Vai a pagina ' + (index - 1) + '" arialabe="Immagini allegate Vai a pagina ' + (index - 1) + '"></span>';
            }
        }

        var Risultato =
            <Swiper style={{ paddingTop: "500px" }} slidesPerView={slidep} effect={'fade'} navigation={false} pagination={pagination} className="mySwiper">
                {htmlTabellaAllegata}
            </Swiper>;

        return Risultato;
    }





}


export default ImmaginiAllegate;
