import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import Cookies from "js-cookie";

class AutorizzazioniSuccessLogin extends Component {

    constructor(props) {

        super(props);

        this.state = {
            Redirectassociazioni: false,
        };
    }

    // #region Eventi



    // #endregion


    // #region  Render
    render() {

        return (
            <div id="ArchivioContenuti">Login successo
            </div>
        );
    }
    // #endregion

    // #region Ciclo di vita del container
    /// Metodi del ciclo di vita del container
    componentDidMount = () => {

        console.log('entro qui');


        var userProfile = {
            token: globals.tokenGenerico,
            username: '',
            nome: null,
            cognome: null,
            email: null,
            cf: null,
        }

        if (Cookies.get('userToken') === undefined) {
            console.log('no user token');
            /*Il login non è andato a buon fine*/
            this.props.effettuaLogout();
        }
        else {
            console.log('si user token');
            console.log(Cookies.get('userToken'));

            userProfile.token = Cookies.get('userToken');
        
            if (Cookies.get('userTokenInfo') !== undefined) {

                var userTokenInfo = Cookies.get('userTokenInfo').split('&');

                console.log(Cookies.get('userTokenInfo'));

                userTokenInfo.forEach(element => {

                    if (element !== "") {

                        console.log(element);


                        var userTokenInfoElement = element.split('=');

                        console.log(userTokenInfoElement[0].toLocaleLowerCase());
                        console.log(userTokenInfoElement[1].toLocaleLowerCase());

                        switch (userTokenInfoElement[0].toLocaleLowerCase()) {
                            case "nome": userProfile.nome = userTokenInfoElement[1];
                                break;
                            case "cognome": userProfile.cognome = userTokenInfoElement[1];
                                break;
                            case "email": userProfile.email = userTokenInfoElement[1];
                                break;
                            case "cf": userProfile.cf = userTokenInfoElement[1];
                                break;
                            default: break;
                        }

                    }


                });

                console.log(userProfile.token);

                // this.props.caricaMenu(userProfile.token);
                this.props.effettuaLoginSuccesso(userProfile);


            }
            else 
            {
                // this.props.effettuaLogout();
            }
        }

    }

    componentDidUpdate = (propsPrecedenti, statePrecedente) => {
    }
    // #endregion

    setRichiedentiResultsEventiAttivi = (data, countdatidagraficareEventiAttivi, paginaCorrenteEventiAttivi) => {
        /*Positivo*/
    }


}
// #endregion

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
        menu: state.menu
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
        caricaMenu: (token) => dispatch(actions.caricaMenu(token)),
        effettuaLoginSuccesso: (userProfile) => dispatch(actions.effettuaLoginSuccesso(userProfile)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(AutorizzazioniSuccessLogin);
