import React from 'react';
import { Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { scaleDown as Menu } from 'react-burger-menu';


const MenuVerticale = (props) => {
    if (props.risultati !== null && props.risultati !== undefined ) {

        var htmlContenuto = [];

        if (props.risultati.length > 0) {

            var menuorizzontale = props.risultati.filter(function (obj) {
                return (parseInt(obj.strutturapag_sezione1) === parseInt(props.StrutturaPag_Sezione)
                    && parseInt(obj.strutturapag_area1) === 1 && parseInt(obj.id) !== parseInt(props.StrutturaPag_Sezione));
            });

            menuorizzontale = menuorizzontale.sort(function (a, b) {
                return parseInt(a.strutturapag_seqvisualizz) - parseInt(b.strutturapag_seqvisualizz)
            });

            var link = "";

            for (var y = 0; y < menuorizzontale.length; y++) {

                if (menuorizzontale[y]["linkesterno"] === "" || menuorizzontale[y]["linkesterno"] === undefined) {
                    link = menuorizzontale[y]["linkservizio"] + menuorizzontale[y]["id"];
                }
                else {
                    link = menuorizzontale[y]["linkesterno"];
                }

                var target = "_self"

                if (menuorizzontale[y]["strutturapag_frame"] !== "" && menuorizzontale[y]["strutturapag_frame"] !== undefined &&
                    menuorizzontale[y]["strutturapag_frame"].toLocaleLowerCase() === "Nuova Finestra".toLocaleLowerCase()) {
                    target = "_blank"
                }

                htmlContenuto.push(
                    <Nav.Item id={props.idelemento + "_" + y} key={props.idelemento + "_" + y} className={props.classecssitem + " nav-item"}>
                        <a id={props.idelemento + "_link" + y} key={props.idelemento + "_link" + y} href={link} target={target} className="nav-link" title={menuorizzontale[y]["nome"]} aria-label={menuorizzontale[y]["nome"] + y}>{menuorizzontale[y]["nome"]}</a>
                    </Nav.Item>
                );

            }
        }
    }

    return (
        <Menu id="burger-wrapper">
            {htmlContenuto}
        </Menu>
    )
}

/// Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
        menu: state.menu
    };
};

// #endregion
export default withRouter(connect(mapStateToProps)(MenuVerticale));
