import React, { Component, } from 'react';
import HeaderPortaleReact from './HeaderPortaleReact';
import MenuOrizzontaleTop from './MenuOrizzontaleTop';
import FooterPortaleReact from './FooterPortaleReact';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import { withRouter } from 'react-router-dom';
import './burgermenu.css';
import { jsPDF } from "jspdf";
import { SocialIcon } from 'react-social-icons';
import BackButton from '../components/BackButton';

class HomePortale extends Component {

    state = {

    };

    render() {

        //const nomeUtente = (this.state.usernameUtente === "" ? "Accedi" : "Logout " + this.state.nomeUtente);
        const nomeUtente = ((this.props.userProfile && this.props.userProfile.username !== "") ? "Logout " + this.props.userProfile.nome + " " + this.props.userProfile.cognome : "Accedi");

        let centrale = null;

        const pdfDownload = (e, iddastampare) => {
            e.preventDefault()
            let doc = new jsPDF("landscape", 'pt', 'A4', true);
            doc.html(document.getElementById(iddastampare), {
                callback: () => {
                    doc.save('PisaCultura.pdf');
                },
                width: 850,
                windowWidth: window.innerWidth
            });
        }

        return (
            <div id="outer-container">
                <div id="page-wrap">
                    <div id="overlay" className="hidden"></div>
                    <div id="boxTotale" className="home">
                        <div role="navigation" aria-label="Navigazione Rapida" className="TestoNascosto">
                            <a href="#corpo" title="vai al contenuto principale" className="sr-only sr-only-focusable">vai al contenuto principale</a>
                            <a href="#burger-wrapper" title="vai al menù principale" className="sr-only sr-only-focusable">vai al menu principale.</a>
                        </div>
                        <HeaderPortaleReact nomeUtente={nomeUtente} accediClicked={this.accediClicked}></HeaderPortaleReact>
                        <MenuOrizzontaleTop nomeUtente={nomeUtente} accediClicked={this.accediClicked}></MenuOrizzontaleTop>
                        <div className="main_container clearfix" id="corpo"> <div id="pdf-view">{centrale === null ? this.props.children : centrale}</div></div>
                        
                        <div className="social-icons my-4" style={{ textAlign: "center" }}>
                            <SocialIcon aria-label={"twitter" + document.title} title={"twitter" + document.title} target="_blank" network="twitter" url={"https://twitter.com/intent/tweet?text=" + document.title + "&url=" + window.location.href} />
                            <SocialIcon aria-label={"facebook" + document.title} title={"facebook" + document.title} target="_blank" network="facebook" url={"http://www.facebook.com/sharer.php?u=" + window.location.href + "&t=" + document.title} />
                            <SocialIcon aria-label={"whatsapp" + document.title} title={"whatsapp" + document.title} target="_blank" network="whatsapp" url={"https://wa.me/?text" + document.title} />
                            <button style={{
                                border: "0", backgroundColor: "transparent", display: "inline-block", width: "50px",
                                height: "50px", position: "relative", overflow: "hidden", verticalAlign: "middle"
                            }} className="social-icon" aria-label={"print" + document.title} title={"print" + document.title} onClick={window.print}>
                                <span style={{
                                    position: "relative",
                                    overflow: "hidden",
                                    verticalAlign: "middle",
                                    fontSize: "145%",
                                    color: "white",
                                    backgroundColor: "red",
                                    padding: "25%",
                                    borderRadius: "50%"
                                }}
                                    className="fa fa-print" aria-hidden="true"></span><span className="sr-only">Stampa</span></button>
                            {/* <button style={{
                                border: "0", backgroundColor: "transparent", display: "inline-block", width: "50px",
                                height: "50px", position: "relative", overflow: "hidden", verticalAlign: "middle"
                            }} className="social-icon" aria-label={"Crea pdf pagina intera  " + document.title} title={"Crea pdf pagina intera " + document.title} onClick={(e) => pdfDownload(e, "outer-container")}>
                                <span style={{
                                    position: "relative",
                                    overflow: "hidden",
                                    verticalAlign: "middle",
                                    fontSize: "145%",
                                    color: "white",
                                    backgroundColor: "red",
                                    padding: "25%",
                                    borderRadius: "50%"
                                }}
                                    className="fa fa-file-pdf" aria-hidden="true"></span><span className="sr-only">Crea pdf pagina intera</span></button> */}
                            <button style={{
                                border: "0", backgroundColor: "transparent", display: "inline-block", width: "50px",
                                height: "50px", position: "relative", overflow: "hidden", verticalAlign: "middle"
                            }} className="social-icon" aria-label={"Crea pdf contenuto interno  " + document.title} title={"Crea pdf contenuto interno " + document.title} onClick={(e) => pdfDownload(e, "pdf-view")}> <span style={{
                                position: "relative",
                                overflow: "hidden",
                                verticalAlign: "middle",
                                fontSize: "145%",
                                color: "white",
                                backgroundColor: "red",
                                padding: "25%",
                                borderRadius: "50%"
                            }}
                                className="fa fa-file-pdf" aria-hidden="true"></span><span className="sr-only">Crea pdf contenuto</span></button>
                            <div className="px-4">
                                <BackButton />
                            </div>
                        </div>
                        <FooterPortaleReact></FooterPortaleReact>
                    </div>
                </div>
            </div>);
    }


}


// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
        messaggioErroreLogin: state.messaggioErroreLogin,
        codiceErroreLogin: state.codiceErroreLogin,
        userProfile: state.userProfile,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};
// #endregion


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePortale));
