import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Spinner from '../components/Spinner';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import MenuVerticale from '../components/MenuVerticale';
import { Form, InputGroup } from "react-html5-form";
import { Redirect } from "react-router-dom";
import { format } from "date-fns";

function getTarget(url, target) {
    if (url == null) return;
    url.target = (target == null || target === '') ? '_self' : target;

}
class HeaderPortaleReact extends Component {

    constructor(props) {

        super(props);

        this.state = {
            apertoinizio: "d-block",
            chiusoinizio: "d-none",

            campo_Testo: {
                nomecampo: "Testo",
                campovalore: null,
            },
        };
    }

    ApriChiudiBottone = () => {

        var apertoinizio = this.state.apertoinizio;
        var chiusoinizio = this.state.chiusoinizio;

        if (apertoinizio === "d-block") {
            apertoinizio = "d-none";
            chiusoinizio = "d-block";
        }
        else {
            apertoinizio = "d-block";
            chiusoinizio = "d-none";
        }

        this.setState({
            apertoinizio: apertoinizio,
            chiusoinizio: chiusoinizio,
        });
    }

    campiCambiatoHandler = (event, key, state) => {
        state[key].campovalore = event.target.value;
    }

    render() {


        var MenuVerticaleSinistra = "";

        if (!this.props.menu) {
            MenuVerticaleSinistra = (<div><Spinner /></div>);
        } else {

            MenuVerticaleSinistra = (
                <MenuVerticale
                    idelemento='menu_sx'
                    StrutturaPag_Sezione='22107'
                    classecssitem=' '
                    classecssnav=' '
                    risultati={this.props.menu}>
                </MenuVerticale>

            );
        }

        const handlereaprichiudibottone = () => {
            this.ApriChiudiBottone();
        };

        const handlerEffettuaRicerca = (form) => {

            this.setState({
                apertoinizio: "d-block",
                chiusoinizio: "d-none",
    
            }, handlerEffettuaRicercaok());
        };

        const handlerEffettuaRicercaok = (form) => {

            this.setState({
              redirectToRicerca:true,
    
            });
        };

        const onInput = (e, inputGroup) => {
            inputGroup.checkValidityAndUpdate();
        };

        if (this.state.redirectToRicerca === true) {
            return <Redirect push to={{ pathname: "CercaNelsito", state: { testoricerca: this.state.campo_Testo.campovalore} }} />
        }



        return (
            <div id="formid_intestazione1" className="header-top clearfix">

                <Row>
                    <Col xs={2} className={this.state.apertoinizio}>
                        {MenuVerticaleSinistra}
                    </Col>

                    <Col xs={8} className={"logo-nome-ente " + this.state.apertoinizio}>

                        <a href="/index.asp" title={process.env.REACT_APP_TITLE + " - torna alla homepage"} onClick={getTarget(this, '_self')}>

                            <span className="logo-wrapper">
                                <img
                                    src={process.env.PUBLIC_URL + "/img/logo/logo-ente.png"}
                                    className="logo d-inline-block"
                                    alt="Pisa - Portale eventi culturali"
                                />
                            </span>
                            <span className="nome-wrapper">{process.env.REACT_APP_TITLE}</span>


                        </a>

                    </Col>

                    <Col xs={2} className={"BtnRicercaMobile " + this.state.apertoinizio}>
                        <div className="BtnRicercaMobile text-right">
                            <button onClick={handlereaprichiudibottone} className="pulsanteRicerca" type="button" >
                                <span className="fa fa-search" aria-hidden="true"></span>
                                <span className="sr-only">Ricerca</span>
                            </button>
                        </div>
                    </Col>

                    <Col xs={2} className={this.state.chiusoinizio}>
                        <div className="text-left">
                            <button onClick={handlereaprichiudibottone} className="pulsanteRicerca" type="button" style={{
                                position: "absolute", left: "0px",
                                top: "0px", width: "100%", height: "100%", margin: "0px", padding: "0px", border: "none", background: "transparent", cursor: "pointer"
                            }}>
                                <span className="fa fa-arrow-left" aria-hidden="true"></span>
                                <span className="sr-only">Cerca</span>
                            </button>
                        </div>
                    </Col>

                    <Col xs={10} className={this.state.chiusoinizio}>
                        <Form onSubmit={handlerEffettuaRicerca} >
                            {({ error, valid, pristine, submitting, form }) => (
                                <>
                                    {error && (<div className="alert alert-danger mx-0" role="alert">
                                        <strong>Errori durante la compilazione del dato</strong> {error}
                                    </div>)
                                    }
                                    <Row>
                                        <Col xs={9} >
                                            <InputGroup
                                                validate={["Testo"]}
                                                translate={{
                                                    Email: {
                                                        valueMissing: "Questo campo è obbligatorio",
                                                        patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                                                        typeMismatch: "Quello inserito non è un indirizzo mail valido"
                                                    }
                                                }}>
                                                {({ error, valid, inputGroup }) => (
                                                    <div className="">
                                                        <label htmlFor="TestoInput" className="sr-only">Email</label>
                                                        <input
                                                            required={true}
                                                            className={`form-control ${!valid && "is-invalid"}`}
                                                            id="TestoInput"
                                                            name="Testo"
                                                            onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Testo", this.state); }}
                                                            onInput={(e) => onInput(e, inputGroup, form)}
                                                            autoComplete="off"
                                                            aria-label="Cerca..."
                                                            placeholder="Cerca..." minLength={5} />
                                                        {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                                                    </div>
                                                )}
                                            </InputGroup></Col>
                                        <Col xs={1} >
                                            <Button title="Cerca nel sito" aria-label="Cerca nel sito"
                                                type="submit">  <span className="fa fa-search" aria-hidden="true"></span>
                                                <span className="sr-only">Ricerca</span></Button>
                                        </Col>  </Row>
                                </>
                            )}
                        </Form>

                    </Col>

                </Row>

            </div>
        );


    }

    componentDidMount = () => {
        if (this.props.menu == null) {
            this.props.caricaMenu(this.props.loginToken);
        }
        else {
            var dataattuale = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
            
            if (this.props.dataoracreazionemenu < dataattuale) {
                this.props.caricaMenu(this.props.loginToken);
            }
        }
    }

}

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
        menu: state.menu,
        dataoracreazionemenu: state.dataoracreazionemenu
    };
};

const mapDispatchToProps = dispatch => {
    return {
        caricaMenu: (token) => dispatch(actions.caricaMenu(token)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPortaleReact);

