import reactElementToJSXString from 'react-element-to-jsx-string';

export var reacthtmlparserIframe = function (html) {
   let parser = new DOMParser();

   let el = parser.parseFromString(html, 'text/html');

   el.querySelectorAll('iframe').forEach(iframe => {
      let src = iframe.getAttribute('src');
      if (src.indexOf("https://www.youtube.com/") >= 0) {
         var iframe1 = iframe;
         iframe1.setAttribute("src", src.replace("https://www.youtube.com/", "https://www.youtube-nocookie.com/"));
         iframe.replaceWith(iframe1);
      }
   });

   return el.body.innerHTML;
}

export var reacthtmlparserGoogle = function (jsxelement) {
   var html = reactElementToJSXString(jsxelement);

   let parser = new DOMParser();

   let el = parser.parseFromString(html, 'text/html');

   el.querySelectorAll('img').forEach(img => {

      let alt = img.getAttribute('alt');

      if(alt === null || alt === undefined)
      {
         var img1 = img;
         img1.setAttribute("alt", "immagine di grafica" );
         img.replaceWith(img1);
      }
   });

   return el.body.innerHTML;
}



