import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Spinner from '../components/Spinner';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import MenuOrizzontale from '../components/MenuOrizzontale';
import { format } from "date-fns";
class FooterPortaleReact extends Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {

        var MenuOrizzontaleFooter = "";

        if (!this.props.menu) {
            MenuOrizzontaleFooter = (<div><Spinner /></div>);
        } else {

            MenuOrizzontaleFooter = (
                <MenuOrizzontale
                    idelemento='menu_footer'
                    StrutturaPag_Sezione='22053'
                    classecssitem=' '
                    classecssnav=' '
                    risultati={this.props.menu}>
                </MenuOrizzontale>

            );
        }


        return (

            <footer className="footer_container" id="FooterContainer">
                <div id="formid_fondo2">

                    <Container fluid={true} className="px-4">

                        <Row>

                            <Col>
                                <div className="logo-nome-ente col-xs-12">
                                    <a href="/index.asp" title="Comune di Pisa - Portale delle Associazioni e degli Eventi della Città di Pisa - Torna alla homepage">
                                        <h3>
                                            <span className="logo-wrapper">
                                                <img className="logo" src={process.env.PUBLIC_URL + "/img/logo/logo-ente.png"} alt="Comune di Pisa - Portale delle Associazioni e degli Eventi della Città di Pisa" />
                                            </span>
                                            <span className="nome-wrapper">Comune di Pisa - Portale delle Associazioni e degli Eventi della Città di Pisa</span>
                                        </h3>
                                    </a>
                                </div>

                            </Col>



                        </Row>

                    </Container>
                </div>

                <div id="formid_fondo3">

                    <Container fluid={true} className="px-4">


                        <Row>

                            <Col md={6} xs={12} className="recapiti">

                                <span className="h3">Contatti</span>
                                <p>Indirizzo: Via degli Uffizi, 1 56100 Pisa<br />
                                    Centralino: +39 050910111<br />
                                    P.I./C.F.: 00341620508<br />
                                    Posta Elettronica Certificata: <a href="mailto:comune.pisa@postacert.toscana.it  " title="comune.pisa@postacert.toscana.it   - Questo link aprirà il software impostato sul tuo dispositivo per la scrittura delle email">comune.pisa@postacert.toscana.it</a>
                                    <br></br><a target="_blank" rel="noopener noreferrer" href="https://www.comune.pisa.it/it/pagina/privacy" title="Privacy">
                                        Privacy
                                    </a>
                                </p>
                            </Col>

                            <Col md={3} xs={12} className="social">

                                <div className="nav-social col-xs-12">
                                    <span className="h3">Seguici su</span>
                                    <ul className="list-inline social-list">
                                        <li>
                                            <a target="_blank" rel="noopener noreferrer" title="Seguici su Youtube - Questo link si aprirà in una nuova finestra" href="https://www.youtube.com/comunedipisa">
                                                <span className="fa fa-youtube" aria-hidden="true"></span>
                                                <span className="hidden">Youtube</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                            </Col>

                            {/* <Col md={3} xs={12}>
                                <span className="h3">Credits</span>
                                <p>Sito web realizzato da <a rel="noreferrer" href="https://www.ai4smartcity.ai/servizi/notizie/notizie_homepage.aspx" title="Ai4SMARTCITY S.r.l. - Il sito web si aprirà in una nuova finestra" target="_blank">Ai4SMARTCITY S.r.l.</a> © 2021</p>
                            </Col> */}


                        </Row>



                    </Container>

                </div>

                {MenuOrizzontaleFooter}
            </footer>

        );
    }

    componentDidMount = () => {
        if (this.props.menu == null) {
            this.props.caricaMenu(this.props.loginToken);
        }
        else {
            var dataattuale = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
            
            if (this.props.dataoracreazionemenu < dataattuale) {
                this.props.caricaMenu(this.props.loginToken);
            }

        }
    }

}

const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
        menu: state.menu,
        dataoracreazionemenu: state.dataoracreazionemenu
    };
};

const mapDispatchToProps = dispatch => {
    return {
        caricaMenu: (token) => dispatch(actions.caricaMenu(token)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterPortaleReact);
