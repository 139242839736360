import React, { Component } from 'react';
import Spinner from '../components/Spinner';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import Paginazione from '../components/Paginazione';
import { Table } from 'react-bootstrap';
import { Redirect, Link } from "react-router-dom";


class AssociazioniRicercaAdmin extends Component {

    constructor(props) {

        super(props);

        this.state = {
            risultatiRicercaContenuti: null,
            risultatiPerPaginaContenuti: 6,
            queryOffsetContenuti: 0,
            paginacorrenteAssocizioni: 0,
            ricercaEseguitaContenuti: false,
            messaggioErrore: null,
            countdatidagraficareContenuti: null,
        };
    }

    // #region cambiamenti di pagina o redirect

    cambiaPaginaHandlerContenuti = (numeropaginacliccata) => {
        this.setState({
            paginacorrenteAssocizioni: numeropaginacliccata,
            queryOffsetContenuti: (numeropaginacliccata - 1) * this.state.risultatiPerPaginaContenuti
        });
    }

    VaiAModificaContenuto = (id) => {
        this.setState({
            redirectToModifica: true,
            idContenutoSelezionata: id
        });
    }

    VaiAEliminaContenuto = (id) => {
        this.setState({
            redirectToEliminazione: true,
            idContenutoSelezionata: id
        });
    }

    VaiACopiaContenuto = (id) => {
        this.setState({
            redirectToCopia: true,
            idContenutoSelezionata: id
        });
    }

    VaiAGestioneEventi = (id) => {
        this.setState({
            redirectToEventi: true,
            idContenutoSelezionata: id
        });
    }

    // #endregion


    // #region  Render
    render() {

        if (this.props.loginToken === globals.tokenGenerico) {
            //Non sono loggato nel sistema ma sto accedendo come utente generico faccio logout
            this.props.effettuaLogout();
        }

        if (this.props.userProfile.cf === "") {
            //Non sono loggato nel sistema ma sto accedendo come utente generico faccio logout
            this.props.effettuaLogout();
        }

        if (this.state.redirectToModifica === true) {
            return <Redirect push to={{ pathname: "AssociazioniGestioneRecordAdmin", state: { idContenutoSelezionato: this.state.idContenutoSelezionata, actions: "modifica" } }} />
        }

        if (this.state.redirectToEliminazione === true) {
            return <Redirect push to={{ pathname: "AssociazioniGestioneRecordAdmin", state: { idContenutoSelezionato: this.state.idContenutoSelezionata, actions: "elimina" } }} />
        }

        if (this.state.redirectToCopia === true) {
            return <Redirect push to={{ pathname: "AssociazioniGestioneRecordAdmin", state: { idContenutoSelezionato: this.state.idContenutoSelezionata, actions: "copia" } }} />
        }

        if (this.state.redirectToEventi === true) {
            return <Redirect push to={{ pathname: "/EventiRicercaAdmin", state: { idAssociazione: this.state.idContenutoSelezionata } }} />
        }

        return (
            <div id="ArchivioContenuti">
                <div className="px-4">
                    <div className="row">
                        <div className="col-lg-12 px-lg-2 py-lg-2">
                            <div className="row">
                                <div className="col">
                                    <h3 className="h2" id="ArchivioContenutiTitolo">LE ASSOCIAZIONI CHE GESTISCI</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.ricercaEseguitaContenuti === false
                    ?
                    <Spinner />
                    :
                    <section aria-label="GestioneContenuti" id="GestioneContenuti">
                        <div className="py-3">
                            <div className="px-4">
                                {this.state.risultatiRicercaContenuti === null
                                    ? null :
                                    this.renderrisultatiRicercaSlider()}
                                <Paginazione
                                    paginaCorrente={this.state.paginacorrenteAssocizioni}
                                    paginaClicked={this.cambiaPaginaHandlerContenuti}
                                    risultati={this.state.countdatidagraficareContenuti}
                                    elementiPerPagina={this.state.risultatiPerPaginaContenuti}
                                    labelpaginazione='Nagivazione dei contenuti degli Contenuti scaduti'>
                                </Paginazione>
                            </div>
                            <div className="px-4">
                                <Link title="Nuova Associazione" aria-label="Nuova Associazione" to={{ pathname: "/AssociazioniGestioneRecordAdmin" }}> Nuova Associazione </Link>
                            </div>

                        </div>
                    </section>
                }
            </div>
        );
    }
    // #endregion

    // #region Ciclo di vita del container
    /// Metodi del ciclo di vita del container
    componentDidMount = () => {
        this.performSearchContenuti(this.setRichiedentiResultsContenuti, this.setErrorMessageContenuti, this.performSearchContenuti);
    }

    componentDidUpdate = (propsPrecedenti, statePrecedente) => {
        if (statePrecedente.queryOffsetContenuti !== this.state.queryOffsetContenuti) {
            this.performSearchContenuti(this.setRichiedentiResultsContenuti, this.setErrorMessageContenuti, this.performSearchContenuti);
        }
    }
    // #endregion

    // #region Ricerca Risultati 
    setRichiedentiResultsContenuti = (data, countdatidagraficareContenuti, paginaCorrenteContenuti) => {
        this.setState({
            risultatiRicercaContenuti: data,
            messaggioErrore: null,
            paginaCorrenteContenuti: paginaCorrenteContenuti,
            ricercaEseguitaContenuti: true,
            countdatidagraficareContenuti: countdatidagraficareContenuti
        });
    }

    setErrorMessageContenuti = (errorMessage) => {
        this.setState({
            messaggioErrore: errorMessage,
            ricercaEseguitaContenuti: true,
        });
    }


    performSearchContenuti = (successFn, errorFn, tokenScadutoFn) => {

        var valoricondizioni = null;

        var filtri = '';

        var cf = this.props.userProfile.cf;

        if (cf !== null && cf !== undefined) {

            filtri = "codicefiscaleçççvaloreççç" + cf + ";";
        }

        if (filtri !== '') {
            valoricondizioni = Buffer.from(filtri.toString(), 'utf8').toString('base64');
        }

        var parametriserver = {
            nometabella: Buffer.from('t_associazioni', 'utf8').toString('base64'),
            limiterecord: Buffer.from(this.state.risultatiPerPaginaContenuti.toString(), 'utf8').toString('base64'),
            idulteriorecondizione: Buffer.from('7', 'utf8').toString('base64'),
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
            elencocampi: Buffer.from('id;associazioni;email;partita_iva;validata;disattivata', 'utf8').toString('base64'),
            queryoffset: Buffer.from(this.state.queryOffsetContenuti.toString(), 'utf8').toString('base64'),
            querynextrows: Buffer.from(this.state.risultatiPerPaginaContenuti.toString(), 'utf8').toString('base64'),
            listavaloricondizioni: valoricondizioni,
            contenutiindicizzati: null,
            idcamposelectcustom: null
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const dati = JSON.parse(response.data.data);

                    var countdatidagraficareContenuti = dati[0]["countelementi"];

                    var paginaCorrenteContenuti = dati[0]["numeropagina"];

                    if (globals.debug < 3) console.log(dati);

                    dati.shift();

                    successFn(dati, countdatidagraficareContenuti, paginaCorrenteContenuti);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }
                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata associazioni gestione ricerca admin error: " + error);
                errorFn(error.message);
            });


        return;
    }

    renderrisultatiRicercaSlider = () => {

        var Tabella = "";

        const risultati = this.state.risultatiRicercaContenuti.map((obj, i) => {

            const handlerModificaContenuto = () => {
                this.VaiAModificaContenuto(obj.id);
            };

            const handlereliminaContenuto = () => {
                this.VaiAEliminaContenuto(obj.id);
            };

            const handlercopiaContenuto = () => {
                this.VaiACopiaContenuto(obj.id);
            };

            const handlergestioneEventi = () => {
                this.VaiAGestioneEventi(obj.id);
            };

            var visualizzaeventi = "";

            if ((obj.validata.toString().toLowerCase() === "false" || obj.validata === "") || (
                obj.disattivata.toString().toLowerCase() === "true")) {
                visualizzaeventi = "Inserimento eventi non attivo";
            }
            else {
                visualizzaeventi = <button style={{ background: "transparent", border: "0" }} onClick={handlergestioneEventi} aria-label="Eventi" title="Eventi"><i style={{ cursor: "pointer", color: "black" }} title="Eventi" className="fa fa-calendar-plus"></i></button>;
            }


            return (
                <tr key={obj.id}>
                    <td className="text-center"><button style={{ background: "transparent", border: "0" }} onClick={handlerModificaContenuto} aria-label="Modifica" title="Modifica"><i style={{ cursor: "pointer", color: "black" }} title="Modifica" className="fa fa-edit"></i></button></td>
                    <td className="text-center"><button style={{ background: "transparent", border: "0" }} onClick={handlereliminaContenuto} aria-label="Elimina" title="Elimina"><i style={{ cursor: "pointer", color: "black" }} title="Elimina" className="fa fa-trash"></i></button></td>
                    <td className="text-center"><button style={{ background: "transparent", border: "0" }} onClick={handlercopiaContenuto} aria-label="Copia" title="Copia" ><i style={{ cursor: "pointer", color: "black" }} title="Copia" className="fa fa-copy"></i></button></td>
                    <td className="text-center">{visualizzaeventi}</td>
                    <td className="text-left">{obj.associazioni}</td>
                    <td className="text-left">{obj.email}</td>
                    <td className="text-left">{obj.partita_iva}</td>
                </tr>
            );
        });

        if (this.state.risultatiRicercaContenuti.length > 0) {
            Tabella =
                <Table responsive striped hover size="sm">
                    <caption>Le associazioni che gestisci</caption>
                    <thead>
                        <tr>
                            <th className="text-center">Modifica</th>
                            <th className="text-center">Elimina</th>
                            <th className="text-center">Copia</th>
                            <th className="text-center">Eventi</th>
                            <th className="text-left">Nome</th>
                            <th className="text-left">Email</th>
                            <th className="text-left">Partita iva</th>
                        </tr>
                    </thead>
                    <tbody>
                        {risultati}
                    </tbody></Table>;
        }

        var Risultato = Tabella;

        return (Risultato);

    }



}
// #endregion

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
        userProfile: state.userProfile,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(AssociazioniRicercaAdmin);
