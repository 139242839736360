export var getBase64 = function (file, handler, nome) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    handler(reader.result, nome);
  };
  reader.onerror = function (error) {
    return "";
  };
}

export var getBase64key = function (file, handler, nome, inputGroup, key, state, size) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    handler(reader.result, nome, inputGroup, key, state, size);
  };
  reader.onerror = function (error) {
    return "";
  };
}

export var spidprovider = function () {
  var provider = new Array();

  // provider.push(
 //   <li className="spid-idp-button-link" data-idp="intesaid" key="intesaid">
 //     <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("https://spid.intesa.it") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + //process.env.REACT_APP_LOGINSPIDURL)} title="Intesa ID"><span className="spid-sr-only">Intesa ID</span><img src="../../immagini/spid/spid-idp-intesaid.svg" alt="Intesa ID" /></a>
 //   </li>);
	
  provider.push(<li className="spid-idp-button-link" data-idp="arubaid" key="arubaid">
    <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("https://loginspid.aruba.it") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_LOGINSPIDURL)} title="Aruba ID"><span className="spid-sr-only">Aruba ID</span><img src="../../immagini/spid/spid-idp-arubaid.svg" alt="Aruba ID" /></a>
  </li>);
  provider.push(<li className="spid-idp-button-link" data-idp="lepidaid" key="lepidaid">
    <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("https://id.lepida.it/idp/shibboleth") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_LOGINSPIDURL)} title="Lepida ID"><span className="spid-sr-only">Lepida ID</span><img src="../../immagini/spid/spid-idp-lepidaid.svg" alt="Lepida ID" /></a>
  </li>);
  provider.push(<li className="spid-idp-button-link" data-idp="posteid" key="posteid">
    <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("https://posteid.poste.it") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_LOGINSPIDURL)} title="Poste ID"><span className="spid-sr-only">Poste ID</span><img src="../../immagini/spid/spid-idp-posteid.svg" alt="Poste ID" /></a>
  </li>);
  // provider.push(<li className="spid-idp-button-link" data-idp="testenv3id" key="testenv3id">
  //   <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("http://idp.progettidiimpresa.it:8080") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_LOGINSPIDURL)} title="Test ENV3"><span className="spid-sr-only">Test ENV3</span><img src="../../immagini/spid/intestazione.png" alt="SPID TEST ENV2" /></a>
  // </li>);
  provider.push(<li className="spid-idp-button-link" data-idp="infocertid" key="infocertid">
    <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("https://identity.infocert.it") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_LOGINSPIDURL)} title="Infocert ID"><span className="spid-sr-only">Infocert ID</span><img src="../../immagini/spid/spid-idp-infocertid.svg" alt="Infocert ID" /></a>
  </li>);
  provider.push(<li className="spid-idp-button-link" data-idp="namirialid" key="namirialid">
    <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("https://idp.namirialtsp.com/idp") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_LOGINSPIDURL)} title="Namirial ID"><span className="spid-sr-only">Namirial ID</span><img src="../../immagini/spid/spid-idp-namirialid.svg" alt="Namirial ID" /></a>
  </li>);
  provider.push(<li className="spid-idp-button-link" data-idp="sielteid" key="sielteid">
    <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("https://identity.sieltecloud.it") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_LOGINSPIDURL)} title="Sielte ID"><span className="spid-sr-only">Sielte ID</span><img src="../../immagini/spid/spid-idp-sielteid.svg" alt="Sielte ID" /></a>
  </li>);
  provider.push(<li className="spid-idp-button-link" data-idp="timid" key="timid">
    <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("https://login.id.tim.it/affwebservices/public/saml2sso") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_LOGINSPIDURL)} title="Tim ID"><span className="spid-sr-only">Tim ID</span><img src="../../immagini/spid/spid-idp-timid.svg" alt="Tim ID" /></a>
  </li>);

  // provider.push(<li className="spid-idp-button-link" data-idp="metadata-validatorspid" key="metadata-validatorspid">
  //   <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("https://validator.spid.gov.it") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_LOGINSPIDURL)} title="Spid Validator"><span className="spid-sr-only">Spid Validator</span><img src="../../immagini/spid/spid-validator.svg" alt="Spid Validator" /></a>
  // </li>);


  provider.push(<li className="spid-idp-button-link" data-idp="spiditalia" key="spiditalia">
    <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("https://spid.register.it") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_LOGINSPIDURL)} title="SPIDItalia Register.it"><span className="spid-sr-only">SPIDItalia Register.it</span><img src="../../immagini/spid/spid-idp-spiditalia.svg" alt="SpidItalia" /></a>
  </li>);

  provider.push(<li className="spid-idp-button-link" data-idp="teamsystem" key="teamsystem">
    <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("https://spid.teamsystem.com/idp") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_LOGINSPIDURL)} title="TeamSystem ID"><span className="spid-sr-only">TeamSystem ID</span><img src="../../immagini/spid/spid-idp-teamsystemid.svg" alt="TeamSystem ID" /></a>
  </li>);


  provider.push(<li className="spid-idp-button-link" data-idp="etnaid" key="etnaid">
    <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("https://id.eht.eu") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_LOGINSPIDURL)} title="Etna ID"><span className="spid-sr-only">Etna ID</span><img src="../../immagini/spid/spid-idp-etnaid.svg" alt="Etna ID" /></a>
  </li>);

provider.push(<li className="spid-idp-button-link" data-idp="intesigroup" key="intesigroup">
    <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("https://idp.intesigroup.com") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_LOGINSPIDURL)} title="Intesi Group"><span className="spid-sr-only">Intesi Group</span><img src="../../immagini/spid/spid-idp-intesigroup.svg" alt="Intesi Group" /></a>
  </li>);

provider.push(<li className="spid-idp-button-link" data-idp="infocamere" key="infocamere">
    <a href={process.env.REACT_APP_PUBLIC_URL + "Shibboleth.sso/Login?entityID=" + encodeURIComponent("https://loginspid.infocamere.it") + "&target=" + encodeURIComponent(process.env.REACT_APP_PUBLIC_URL + process.env.REACT_APP_LOGINSPIDURL)} title="Infocamere ID"><span className="spid-sr-only">Infocamere ID</span><img src="../../immagini/spid/spid-idp-infocamereid.svg" alt="Infocamere ID" /></a>
  </li>);



  function random_sort(a, b) {
    return Math.random() - 0.5;
  }

  provider.sort(random_sort);
  return provider
}

export var spidError = function (code) {

  var error = "";

  switch (parseInt(code)) {
    case 8:
      error = "Problema di accesso (Codice errore: 08). Contattare l'assistenza tecnica.";
      break;
    case 9:
      error = "Problema di accesso (Codice errore: 09). Contattare l'assistenza tecnica."
      break;
    case 11:
      error = "Problema di accesso (Codice errore: 11). Contattare l'assistenza tecnica."
      break;
    case 12:
      error = "Problema di accesso (Codice errore: 12). Contattare l'assistenza tecnica."
      break;
    case 13:
      error = "Problema di accesso (Codice errore: 13). Contattare l'assistenza tecnica."
      break;
    case 14:
      error = "Problema di accesso (Codice errore: 14). Contattare l'assistenza tecnica."
      break;
    case 15:
      error = "Problema di accesso (Codice errore: 15). Contattare l'assistenza tecnica."
      break;
    case 16:
      error = "Problema di accesso (Codice errore: 16). Contattare l'assistenza tecnica."
      break;
    case 17:
      error = "Problema di accesso (Codice errore: 17). Contattare l'assistenza tecnica."
      break;
    case 18:
      error = "Problema di accesso (Codice errore: 18). Contattare l'assistenza tecnica."
      break;
    case 19:
      error = "autenticazione fallita per inserimento ripetuto di credenziali errate"
      break;
    case 20:
      error = "autenticazione fallita (Codice Errore: 20)"
      break;
    case 21:
      error = "timeout scaduto"
      break;
    case 22:
      error = "Problema di accesso (Codice Errore: 22)"
      break;
    case 23:
      error = "identita sospesa/revocata o con credenziali bloccate"
      break;
    case 25:
      error = "annullato dall'utente"
      break;
    default:
      error = "Errore generico"
      break;
  }


  return error
}









