import React, { Component } from 'react';
import Spinner from '../components/Spinner';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import MenuOrizzontale from '../components/MenuOrizzontale';
import { format } from "date-fns";


class MenuOrizzontaleTop extends Component {

    constructor(props) {

        super(props);

        this.state = {
        };
    }

    render() {

        if (!this.props.menu) {
            return <div><Spinner /></div>;
        } else
            return (

                <MenuOrizzontale
                    fill='fill'
                    navbarScroll='navbarScroll'
                    idelemento='formid_intestazione3'
                    StrutturaPag_Sezione='17227'
                    classecssitem=' col-lg-4 text-center'
                    classecssnav='col-lg-12'
                    risultati={this.props.menu}>
                </MenuOrizzontale>

            );


    }

    componentDidMount = () => {

        if (this.props.menu == null) {
            this.props.caricaMenu(this.props.loginToken);
        }
        else {
            var dataattuale = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
            
            if (this.props.dataoracreazionemenu < dataattuale) {
                this.props.caricaMenu(this.props.loginToken);
            }

        }
    }
}

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
        menu: state.menu,
        dataoracreazionemenu: state.dataoracreazionemenu
    };
};

const mapDispatchToProps = dispatch => {
    return {
        caricaMenu: (token) => dispatch(actions.caricaMenu(token)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(MenuOrizzontaleTop);
