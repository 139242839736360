
import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import $ from 'jquery';


class GoogleMapsComponent extends Component {


  constructor(props) {

    super(props);

    this.state = {
    };
  }

  onMarkerClick(link, target) {
    window.open(link, target);
  }

  render() {
    const style = {
      width: '100%',
      margin: '10px auto',
      clear: 'both',
      position: 'relative',
      height: '550px'
    };

    if (this.props.risultati != null) {

      var MarkerCartina = [];

      var LatLngList = [];

      var pointxiniziale;
      var pointyiniziale;

      //#region commentato
      if (this.props.risultati.length > 0) {

        pointxiniziale = parseFloat(this.props.risultati[0]["coordinatax"].replace(",", "."));
        pointyiniziale = parseFloat(this.props.risultati[0]["coordinatay"].replace(",", "."));

        for (var y = 0; y < this.props.risultati.length; y++) {
          var titolo = this.props.risultati[y]["nome"];
          var coordinatax = this.props.risultati[y]["coordinatax"].replace(",", ".");
          var coordinatay = this.props.risultati[y]["coordinatay"].replace(",", ".");

          let target = "";

          let link = "";
          if (this.props.risultati[y]["link"] === undefined) {
            link = "http://www.google.com/maps/place/" + coordinatax + "," + coordinatay + "";
            target = "_blank";
          }
          else {
            link = this.props.risultati[y]["link"] + this.props.risultati[y]["id_record"];
            target = "_self";
          }


          var pointg = new this.props.google.maps.LatLng(parseFloat(coordinatax), parseFloat(coordinatay));

          LatLngList.push(pointg);

          MarkerCartina.push(<Marker key={"MarkerCartina" + y} id={"MarkerCartina" + y}
            title={titolo}
            name={titolo}
            onClick={() => this.onMarkerClick(link, target)}
            position={{ lat: coordinatax, lng: coordinatay }} />);

        }
      }

      var latlngbounds = new this.props.google.maps.LatLngBounds();

      var centerpoint = {
        lat: 43.708134,
        lng: 10.3770512
      };

      if (this.props.risultati.length === 1) {

        centerpoint = {
          lat: pointxiniziale,
          lng: pointyiniziale
        };

      }
      else {

        for (var i = 0; i < LatLngList.length; i++) {
          latlngbounds.extend(LatLngList[i]);
        }

        var valore = latlngbounds.getCenter();

        centerpoint = {
          lat: valore.lat(),
          lng: valore.lng(),
        };
      }

      /*Esempi */
      /*Emtrambi i parser con DomParser e html-react-parser eliminano i comandi js di Google non si possono quindi usare*/
      /*html-react-parser ha anche bisogno di react-dom/server per trasformare i jsx in stringa e ripassarla come html vero*/
      //       import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
      // import parse from 'html-react-parser';
      // import ReactDOMServer from 'react-dom/server';
      // var divGoogle = map.getDiv();
      // var htmldivGoogle = divGoogle.innerHTML;
      // var elementparsed = parse(htmldivGoogle.toString(), {
      //   replace: domNode => {
      //     domNode.childNodes.forEach(element => {
      //       if (element.name === 'img' && element.attribs.alt === '') {
      //         element.attribs.alt = 'Immagine di Google';
      //         return element;
      //       }
      //     })
      //   }
      // });
      //ReactDOMServer.renderToStaticMarkup(elementparsed);
      // let parser = new DOMParser();
      // let el = parser.parseFromString(divGoogle.innerHTML, 'text/html');
      // el.querySelectorAll('img').forEach(img => {
      //   let alt = img.getAttribute('alt');
      //   if (alt === null || alt === undefined || alt === "") {
      //     var img1 = img;
      //     img1.setAttribute("alt", "immagine di google");
      //     img.replaceWith(img1);
      //   }
      // });
      //divGoogle.innerHTML = el.body.innerHTML

      const Tilesloaded = (mapProps, map) => {

        setTimeout(() => {
          WCAGGoogle(mapProps, map);
        }, 3000);

      }

      const WCAGGoogle = (mapProps, map) => {

        $(map.getDiv()).find("img").each(function (i, eimg) {
          if (!eimg.alt || eimg.alt === "") {
            eimg.alt = "Google Maps Image";
          }
        });

        $(map.getDiv()).find("area").each(function (i, earea) {
          if (!earea.alt || earea.alt === "") {
            earea.alt = "Google Maps Area";
          }
        });

        $(map.getDiv()).find("iframe").each(function (i, eiframe) {
          if (!eiframe.title || eiframe.title === "") {
            eiframe.title = "Google Maps Iframe";
          }
          if (!eiframe.frameborder !== null || eiframe.frameborder !== undefined) {
            eiframe.removeAttribute('frameborder');
          }
        });

        $(map.getDiv()).find("button").each(function (i, ebutton) {
          if (ebutton.role !== null && ebutton.role !== undefined && ebutton.role === 'menuitemradio') {
            ebutton.removeAttribute('role');
          }
        });

      }

      //#endregion

      return (
        <div aria-hidden="true" >
          <Map aria-hidden="true" google={this.props.google}
            style={style}
            zoomControl={true}
            streetViewControl={true}
            scaleControl={true}
            className={'map'}
            initialCenter={centerpoint}
            bounds={latlngbounds}
            onTilesloaded={Tilesloaded}
          >
            {MarkerCartina}
          </Map></div >
      );
    }
    else {
      return null;
    }
  }
}

export default (GoogleApiWrapper(
  (props) => ({
    apiKey: "AIzaSyA-a0pZN7pE4naZqWMXz-alvrUgKkaJUDc",
    libraries: ['places', 'visualization'],
    language: "it",
  })
)(GoogleMapsComponent));