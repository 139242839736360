import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import Paginazione from '../components/Paginazione';
import { Table, Row, Col, Button } from 'react-bootstrap';
import { Form, InputGroup } from "react-html5-form";



class CercaNelsito extends Component {

    constructor(props) {

        super(props);

        this.state = {
            risultatiRicercaContenuti: null,
            risultatiPerPaginaContenuti: 6,
            queryOffsetContenuti: 0,
            paginacorrenteAssocizioni: 0,
            ricercaEseguitaContenuti: false,
            messaggioErrore: null,
            countdatidagraficareContenuti: null,
            effettuaricercachiamata: false,

            campo_Testo: {
                nomecampo: "Testo",
                campovalore: null,
            },
        };
    }

    // #region cambiamenti di pagina o redirect

    cambiaPaginaHandlerContenuti = (numeropaginacliccata) => {
        this.setState({
            paginacorrenteAssocizioni: numeropaginacliccata,
            queryOffsetContenuti: (numeropaginacliccata - 1) * this.state.risultatiPerPaginaContenuti
        });
    }

    campiCambiatoHandler = (event, key, state) => {
        state[key].campovalore = event.target.value;
    }

    // #endregion


    // #region  Render
    render() {

        if (this.props.location.state !== undefined && this.props.location.state.testoricerca !== undefined && this.state.effettuaricercachiamata === false) {
            this.setState({
                effettuaricercachiamata: true,
                campo_Testo: {
                    nomecampo: "Testo",
                    campovalore: this.props.location.state.testoricerca
                },
            }, function () {
                this.effettuaRicerca(this.setResult, this.setErrorMessageContenuto, this.setResult);
            });

            console.log(this.props.location.state.testoricerca);
            // this.effettuaRicerca(this.setResult, this.setErrorMessageContenuto, this.setResult);
        }

        const handlerEffettuaRicerca = (form) => {

            var validataform = form.checkValidity();

            if (validataform === false) {
            }
            else {

                this.effettuaRicerca(this.setResult, this.setErrorMessageContenuto, this.setResult);
            }
        };

        const onInput = (e, inputGroup) => {
            inputGroup.checkValidityAndUpdate();
        };

        return (
            <div className="px-4">
                <Form onSubmit={handlerEffettuaRicerca} >
                    {({ error, valid, pristine, submitting, form }) => (
                        <>
                            {error && (<div className="alert alert-danger mx-0" role="alert">
                                <strong>Errori durante la compilazione del dato</strong> {error}
                            </div>)
                            }
                            <Row className="py-4">
                                <Col xs={10} >
                                    <InputGroup
                                        validate={["TestoInt"]}
                                        translate={{
                                            Email: {
                                                valueMissing: "Questo campo è obbligatorio",
                                                patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                                                typeMismatch: "Quello inserito non è un indirizzo mail valido"
                                            }
                                        }}>
                                        {({ error, valid, inputGroup }) => (
                                            <div className="">
                                                <label htmlFor="TestoInputInt" className="sr-only">Email</label>
                                                <input
                                                    required={true}
                                                    className={`form-control ${!valid && "is-invalid"}`}
                                                    defaultValue={this.state.campo_Testo.campovalore}
                                                    id="TestoInputInt"
                                                    name="TestoInt"
                                                    onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Testo", this.state); }}
                                                    onInput={(e) => onInput(e, inputGroup, form)}
                                                    autoComplete="off"
                                                    aria-label="Inserisci il testo che vuoi cercare"
                                                    placeholder="Inserisci il testo che vuoi cercare" minLength={5} />
                                                {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                                            </div>
                                        )}
                                    </InputGroup></Col>
                                <Col xs={1} >
                                    <Button title="Cerca nel sito" aria-label="Cerca nel sito"
                                        type="submit">  <span className="fa fa-search" aria-hidden="true"></span>
                                        <span className="sr-only">Ricerca</span></Button>
                                </Col>  </Row>
                        </>
                    )}
                </Form>
                {this.state.ricercaEseguitaContenuti === false
                    ?
                    null
                    :
                    <section aria-label="GestioneContenuti" id="GestioneContenuti">
                        <div className="py-3">
                            <div>
                                {this.state.risultatiRicercaContenuti === null
                                    ? null :
                                    this.renderrisultatiRicercaSlider()}
                                <Paginazione
                                    paginaCorrente={this.state.paginacorrenteAssocizioni}
                                    paginaClicked={this.cambiaPaginaHandlerContenuti}
                                    risultati={this.state.countdatidagraficareContenuti}
                                    elementiPerPagina={this.state.risultatiPerPaginaContenuti}
                                    labelpaginazione='Nagivazione dei contenuti degli Contenuti scaduti'>
                                </Paginazione>
                            </div>

                        </div>
                    </section>
                }
            </div>
        );
    }
    // #endregion

    setErrorMessageContenuto = (errorMessage) => {
        this.setState({
            messaggioerrorericerca: errorMessage,
            risultatiRicercaContenuti: null,
            ricercaEseguitaContenuti: true,
            redirectToRicerca: false
        });
    }

    setResult = (data, countdatidagraficareTipologiaEventi, paginaCorrenteTipologiaEventi) => {

        this.setState({
            risultatiRicercaContenuti: data,
            messaggioerrorericerca: null,
            ricercaEseguitaContenuti: true,
            redirectToRicerca: true,
        });
    }

    effettuaRicerca = (successFn,
        errorFn, tokenScadutoFn) => {

        var valoricondizioni = null;

        if (this.state.campo_Testo.campovalore !== "") {
            valoricondizioni = Buffer.from(this.state.campo_Testo.campovalore, 'utf8').toString('base64');
        }

        var parametriserver = {
            nometabella: Buffer.from('T_eventi', 'utf8').toString('base64'),
            limiterecord: null,
            idulteriorecondizione: null,
            idordinamento: null,
            numeropaginazione: null,
            idselecustom: null,
            selectdistinct: null,
            usaselectwithnolock: null,
            elencocampi: null,
            queryoffset: null,
            querynextrows: null,
            listavaloricondizioni: valoricondizioni,
            contenutiindicizzati: null,
            idcamposelectcustom: null,
            elencotabellecorrelate: null,
            elencocampicorrelate: null,
            idcontenuto: null,
            campivaloridaimpostarequery: null,
        };

        const data = {
            token: this.props.loginToken,
            method: Buffer.from("getricercasito", 'utf8').toString('base64'),
            param: parametriserver,
        };

        const loginErrorFn = () => {
            this.props.effettuaLogout();
        }

        axios.post(globals.wsURLCliente, data)
            .then(function (response) {

                if (response.data.result.success === true) {
                    // handle success
                    const dati = JSON.parse(response.data.data);

                    if (globals.debug < 3) console.log(dati);

                    var countdatidagraficareEvento = dati[0]["countelementi"];

                    var paginaCorrenteEvento = dati[0]["numeropagina"];

                    if (globals.debug < 3) console.log(dati);

                    dati.shift();

                    successFn(dati, countdatidagraficareEvento, paginaCorrenteEvento);

                } else {

                    // handle error
                    if (globals.debug < 3) console.log(response.data.result.message);

                    if (data.loginToken === globals.tokenGenerico && response.data.result.code === 401) {
                        tokenScadutoFn(successFn, errorFn, tokenScadutoFn);
                    }
                    else {
                        if (response.data.result.code === 401) {
                            loginErrorFn();
                        } else {
                            errorFn(response.data.result.message);
                        }
                    }


                }
            })
            .catch(function (error) {
                // handle error
                if (globals.debug < 3) console.log("chiamata cerca nel sito error: " + error);
                errorFn(error.message);
            });


        return;
    };

    // #region Ricerca Risultati 

    renderrisultatiRicercaSlider = () => {

        var TabellaTitoli = "";

        var risultatiTitoli = [];

        var TabellaPreferiti = "";

        var risultatiPreferiti = [];

        var TabellaArchivi = "";

        var risultatiArchivi = [];

        var TabellaTag = "";

        var risultatiTag = [];

        Object.values(this.state.risultatiRicercaContenuti).map((objtipoarchivio, i) => {

            Object.values(objtipoarchivio).map((obj, i) => {

                Object.values(obj).map((obj1, i) => {

                    var link = process.env.REACT_APP_PUBLIC_URL + obj1.link.toString().substring("1").replaceAll('&amp;', '&');

                    var openlinkasblankpage = obj1.openlinkasblankpage;

                    var target = "";

                    var rel = "";

                    if (openlinkasblankpage === "true") {
                        target = "_blank";

                        rel = ("noreferrer");
                    }
                    else {
                        target = "_self";
                    }

                    var htmlriga = <tr key={obj1.tipoarchivio}>
                        <td className="text-left"><a target={target} rel={rel} href={link} title={obj1.description} aria-label={obj1.description}>
                            {obj1.description}</a></td>
                        <td className="text-left">{obj1.tipoarchivio}</td>
                    </tr>;

                    if (obj1.tipoarchivio.indexOf("titoli") === 0) {
                        console.log(obj1);
                        console.log("livello b");

                        risultatiTitoli.push(
                            htmlriga
                        );
                    }

                    if (obj1.tipoarchivio.indexOf("preferiti") === 0) {
                        risultatiPreferiti.push(
                            htmlriga
                        );
                    }

                    if (obj1.tipoarchivio.indexOf("archivi")) {
                        risultatiArchivi.push(
                            htmlriga
                        );
                    }

                    if (obj1.tipoarchivio.indexOf("tag")) {
                        risultatiTag.push(
                            htmlriga
                        );
                    }

                    return obj1;

                });

                return obj;

            });

            return objtipoarchivio;

        });

        if (risultatiTitoli.length > 0) {
            console.log("livello5");
            TabellaTitoli =
                <Table responsive striped hover size="sm">
                    <caption>Risultati per titolo</caption>
                    <thead>
                        <tr>
                            <th className="text-center">Descrizione</th>
                            <th className="text-center">Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        {risultatiTitoli}
                    </tbody></Table>;
        }

        if (risultatiArchivi.length > 0) {
            TabellaArchivi =
                <Table responsive striped hover size="sm">
                    <caption>Risultati per archivio</caption>
                    <thead>
                        <tr>
                            <th className="text-center">Descrizione</th>
                            <th className="text-center">Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        {risultatiArchivi}
                    </tbody></Table>;
        }

        if (risultatiPreferiti.length > 0) {
            TabellaPreferiti =
                <Table responsive striped hover size="sm">
                    <caption>Risultati per preferiti</caption>
                    <thead>
                        <tr>
                            <th className="text-center">Descrizione</th>
                            <th className="text-center">Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        {risultatiPreferiti}
                    </tbody></Table>;
        }

        if (risultatiTag.length > 0) {
            TabellaTag =
                <Table responsive striped hover size="sm">
                    <caption>Risultati per tag</caption>
                    <thead>
                        <tr>
                            <th className="text-center">Descrizione</th>
                            <th className="text-center">Link</th>
                        </tr>
                    </thead>
                    <tbody>
                        {risultatiTag}
                    </tbody></Table>;
        }

        var Risultato = <div>
            {TabellaTitoli}
            {TabellaArchivi}
            {TabellaPreferiti}
            {TabellaTag}</div>;

        if (risultatiTitoli.length === 0 && risultatiTag.length === 0
            && risultatiPreferiti.length === 0 && risultatiArchivi.length === 0) {
            Risultato = <div>
                Non sono presenti contenuti rispecchianti la ricerca effettuata</div>
        }
        return (Risultato);

    }



}
// #endregion

// #region Redux
const mapStateToProps = state => {
    return {
        loginToken: state.loginToken,
        userProfile: state.userProfile,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        effettuaLogout: () => dispatch(actions.effettuaLogout()),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(CercaNelsito);
