export const validEmail = new RegExp(
    '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
 );
 export const validPassword = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');

 export const validfilemp3 = new RegExp(
    '^.*\.(mp3|MP3)$'
 );

 export const validfilepdf = new RegExp(
    '^.*\.(pdf|PDF)$'
 );

 export const validfilemp4 = new RegExp(
   '^.*\.(mp4|MP4)$'
);

export const validfilevtt = new RegExp(
   '^.*\.(vtt|VTT)$'
);

 export const validfileimage = new RegExp(
    '^.*\.(jpg|JPG|jpeg|JEPG|png|PNG|gif|GIF)$'
 );

 export const validdocument = new RegExp(
   '^.*\.(doc|txt|pdf|rtf|xml|xls|docx|xlsx|p7m|odt|ods|ppt|pptx)$'
);