
import React from 'react';
import { format, parse } from "date-fns";
import { it } from 'date-fns/locale';

const ContenutiBlocchi = (props) => {

  if (props.risultati !== null && props.risultati !== undefined) {

    var htmlContenuto = [];

    document.head.querySelector('meta[property="og:url"]').setAttribute("content", window.location.href);

    if (props.descrizionePagina !== undefined) {
      document.head.querySelector('meta[property="og:description"]').setAttribute("content", props.descrizionePagina);
      document.head.querySelector('meta[name="Description"]').setAttribute("content", props.descrizionePagina);
      document.head.querySelector('meta[name="DC.Description"]').setAttribute("content", props.descrizionePagina);
    }

    if (props.titolopagina !== undefined) {
      document.head.querySelector('meta[property="og:title"]').setAttribute("content", props.titolopagina);
    }




    if (props.risultati.length > 0) {

      var nomecampoTitolo = props.nomecampoTitolo;

      var nomecampoAltImmagine = props.nomecampoAltImmagine;

      var nomecampodatainizio = props.nomecampodatainizio;

      var nomecampodatafine = props.nomecampodatafine;

      var nomecampofoto = props.nomecampofoto;

      var nomecampostrillo = props.nomecampostrillo;

      for (var y = 0; y < props.risultati.length; y++) {

        var url = "";

        if ((url === "" || url === undefined) && (props.risultati[y]["linkcontenuto"] !== "" && props.risultati[y]["linkcontenuto"] !== undefined)) {
          url = props.risultati[y]["linkcontenuto"];
        }

        if ((url === "" || url === undefined) && (props.risultati[y]["paginaurlparlante"] !== "" && props.risultati[y]["paginaurlparlante"] !== undefined)) {
          url = props.risultati[y]["paginaurlparlante"];
        }

        if ((url === "" || url === undefined) && (props.risultati[y]["linkservizio"] !== "" && props.risultati[y]["linkservizio"] !== undefined)) {
          url = props.risultati[y]["linkservizio"] + props.risultati[y]["id"];
        }

        var altimmagine = "";

        altimmagine = props.risultati[y][nomecampoAltImmagine];

        if (altimmagine === "" || altimmagine === undefined) {
          altimmagine = props.risultati[y][nomecampoTitolo];
        }

        var dataImmagine = "";
        var isOneDayEvent = ( (props.risultati[y][nomecampodatainizio]) == props.risultati[y][nomecampodatafine] );

               
        if (props.risultati[y][nomecampodatainizio] !== undefined && props.risultati[y][nomecampodatainizio] !== "" &&
          props.risultati[y][nomecampodatafine] !== undefined && props.risultati[y][nomecampodatafine] !== "") {
          dataImmagine = (

            <div className="card-calendar d-flex flex-column justify-content-center">
              <span className="card-date">{format(parse(props.risultati[y][nomecampodatainizio], 'dd/MM/yyyy HH:mm:ss', new Date()), "dd")} 
                { !isOneDayEvent && ( "-" + format(parse(props.risultati[y][nomecampodatafine], 'dd/MM/yyyy HH:mm:ss', new Date()), "dd") )  }
              </span>

              <span className="card-day">{format(parse(props.risultati[y][nomecampodatainizio], 'dd/MM/yyyy HH:mm:ss', new Date()), "MMM", { locale: it })} 
                { !isOneDayEvent && ( "-" + format(parse(props.risultati[y][nomecampodatafine], 'dd/MM/yyyy HH:mm:ss', new Date()), "MMM", { locale: it }) ) }
              </span>

            </div>
          );
        }

        var Immagine = "";

        var linkimage = "";

        if (props.risultati[y][nomecampofoto + "_allegatopresente"] === true) {

          linkimage = process.env.REACT_APP_PUBLIC_URL + props.risultati[y][nomecampofoto + "_link"].toString().replace('../../', '').replaceAll('&amp;', '&');

          Immagine = (
            <div className="img-responsive-wrapper">
              <div className="img-responsive img-responsive-panoramic">
                <figure className="img-wrapper">
                  <img src={linkimage} alt={altimmagine} title={altimmagine}></img>
                </figure>
                {dataImmagine}
              </div>
            </div>
          );
        }

        var dataSenzaImmagine = "";

        // if (props.risultati[y][nomecampofoto + "_allegatopresente"] !== true && props.risultati[y]["data"] !== "" && props.risultati[y]["data"] !== undefined) {
        //   dataSenzaImmagine = (<p className="card-text">
        //     {format(parse(props.risultati[y]["data"], 'dd/MM/yyyy HH:mm:ss', new Date()), "dd mm yyyy")}</p>);
        // }

        var titolo = "";

        var titoloscritta = "";

        if (props.risultati[y][nomecampoTitolo] !== "" && props.risultati[y][nomecampoTitolo] !== undefined) {
          titolo = (<h5 className="card-title">{props.risultati[y][nomecampoTitolo]}</h5>);
          titoloscritta = props.risultati[y][nomecampoTitolo];
        }

        var strillo = "";

        if (props.risultati[y][nomecampostrillo] !== "" && props.risultati[y][nomecampostrillo] !== undefined) {
          strillo = (<p className="card-text"> {props.risultati[y][nomecampostrillo]}</p>);
        }

        htmlContenuto.push(
          <div id={props.idelemento + "_" + y} key={props.idelemento + "_" + y} className="col-12 col-sm-6 col-lg-4">
            <article className="card-wrapper card-space">
              <div className="card card-bg card-big rounded shadow">
                {Immagine}
                <div className="card-body">
                  {titolo}
                  {dataSenzaImmagine}
                  {strillo}
                  <a className="read - more " href={url} title={"Leggi " + titoloscritta} aria-label={props.idelemento + " Leggi " + titoloscritta + y}>
                    <span className="text">Leggi</span>
                  </a>
                </div>
              </div>
            </article>
          </div>);

      }

    }


    return (
      <div className="row mx-0" id={props.idelemento}>{htmlContenuto}</div>
    );
  }
  else {
    return null;
  }
}
export default ContenutiBlocchi;