import React from 'react';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import TabellaGenerica from './TabellaGenerica';
import DocumentiAllegati from './DocumentiAllegati';
import GestioneDocumentale from './GestioneDocumentale';
import Mediateca from './Mediateca';
import ImmaginiAllegate from './ImmaginiAllegate';
import CoordinateContenuti from './CoordinateContenuti';

// install Swiper modules
SwiperCore.use([Navigation]);
SwiperCore.use([Pagination]);

class ArchiviAllegati {

    constructor(record) {
        this.record = record;
    }

    getId() {
        return this.record.id;
    }

    getTitle() {
        return this.record.titolomenu;
    }

    getRenderContenuto() {

        if (this.record.testomenu.length > 0) {
            return <p dangerouslySetInnerHTML={{ __html: this.record.testomenu }}></p>;
        }
        else {
            return null;
        }
    }

    getRenderTabelleAllegate() {
        let tabelleallegate = null;

        if (!!this.record.tabellafiltro && Object.keys(this.record.tabellafiltro).length > 0) {
            switch (this.record.tabellafiltro[0].tabellafiltro.toString().toLowerCase()) {
                case 't_immagini_allegate':
                    tabelleallegate = new ImmaginiAllegate(this.record.tabellafiltro);
                    break;
                case 't_documentiallegati':
                    tabelleallegate = new DocumentiAllegati(this.record.tabellafiltro);
                    break;
                case 't_coordinatecontenuti':
                    tabelleallegate = new CoordinateContenuti(this.record.tabellafiltro);
                    break;
                case 't_gestionedocumentale':
                    tabelleallegate = new GestioneDocumentale(this.record.tabellafiltro);
                    break;
                case 't_audiovideo':
                    tabelleallegate = new Mediateca(this.record.tabellafiltro);
                    break;
                case 't_galleriaaudiovideo':
                    tabelleallegate = new Mediateca(this.record.tabellafiltro);
                    break;
                default:
                    tabelleallegate = new TabellaGenerica(this.record.tabellafiltro);
                    break;
            }
        }

        if (tabelleallegate != null) {
            return tabelleallegate.getRender();
        }
        return null;

    }

}


export default ArchiviAllegati;
