import React, { Component } from 'react';
import { Link } from "react-router-dom";

class BackButton extends Component {

  render() {

    return (
      <Link to="#" title="Torna alla pagina precedente" aria-label="Torna alla pagina precedente" onClick={() => window.history.go(-1)}> Torna alla pagina precedente </Link>
    )
  }
}

export default BackButton;