var url = window.location.href;
var arr = url.split("/");


export const wsURLCliente = window.location.href.indexOf('localhost:3000') >= 0 ? 'https://pisacultura.progettidiimpresa.it/pdidefault.ashx' : (arr[0] + '//' + arr[2] + '/pdidefault.ashx');
export const urlPortale = window.location.href.indexOf('localhost:3000') >=0  ? 'https://pisacultura.progettidiimpresa.it/' :  arr[0] + '//' + arr[2];
export const urlSottoPortale = '';
export const debug = 4; // 4 è modalità silenziosa, 3 = errori, 2 = warning, 1 = info, 0 = verbose 
export const tokenGenerico = "68B100FC-41D5-4AEF-8BCE-87D95E29C748"; // 4 è modalità silenziosa, 3 = errori, 2 = warning, 1 = info, 0 = verbose 

console.log("wsURLCliente",wsURLCliente);
console.log("urlPortale",urlPortale);
