import React, { Component } from 'react';
import { Row, Button, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import "../css/datepicker.css";
import "../css/form.css";
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import * as globals from '../comuni/constants';
import Spinner from '../components/Spinner';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Form, InputGroup } from "react-html5-form";
import { GoogleApiWrapper } from 'google-maps-react';
import { validfileimage } from '../comuni/regex';
import { getBase64key } from '../comuni/funzioni';
import GalleriaImmaginiRicercaRecordAdmin from './GalleriaImmaginiRicercaRecordAdmin';
import DocumentiAllegatiRicercaRecordAdmin from './DocumentiAllegatiRicercaRecordAdmin';
import VideoAllegatiRicercaRecordAdmin from './VideoAllegatiRicercaRecordAdmin';
import ArchiviAllegatiRicercaRecordAdmin from './ArchiviAllegatiRicercaRecordAdmin';

class EventiGestioneRecordAdmin extends Component {

  state = {
    formreadonly: false,
    idContenuto: 0,
    actions: this.props.location.state === undefined ? "" : (this.props.location.state.actions === undefined
      ? "" : this.props.location.state.actions),
    idContenutoSelezionato: this.props.location.state === undefined ? 0 : (this.props.location.state.idContenutoSelezionato === undefined
      ? 0 : this.props.location.state.idContenutoSelezionato),

    nometabellacontenuto: 't_eventi',
    nometabellacontenutotipologia: 't_eventi_tipologia',
    nometabellacontenutocategoria: 't_eventi_categoria',

    //#region campifile

    file_campo_immaginecopertina: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Foto di anteprima dell'evento",
      link: "",
      dimensione: null
    },

    file_campo_fotopiccola: {
      nome: null,
      base64: null,
      inviato: false,
      errore: false,
      nometestualecampo: "Foto anteprima",
      link: "",
      dimensione: null
    },

    //#endregion

    //#region campicoordinate

    campo_luogo: {
      nomecampo: "luogo",
      campovalore: null,
      coordinatax: null,
      coordinatay: null,
      inviato: false,
      titolo: null,
    },

    //#endregion

    //#region campi      

    campo_Email: {
      nomecampo: "Email",
      campovalore: null,
    },

    campo_Telefono: {
      nomecampo: "Telefono",
      campovalore: null,
    },

    campo_Titolo: {
      nomecampo: "Titolo",
      campovalore: null,
    },

    campo_Strillo: {
      nomecampo: "Strillo",
      campovalore: null,
    },

    campo_DataInizio: {
      nomecampo: "DataInizio",
      campovalore: null,
    },

    campo_DataFine: {
      nomecampo: "DataFine",
      campovalore: null,
    },

    campo_titolocopertina: {
      nomecampo: "titolocopertina",
      campovalore: null,
    },

    campo_sottotitolocopertina: {
      nomecampo: "sottotitolocopertina",
      campovalore: null,
    },

    campo_testopulsantecopertina: {
      nomecampo: "testopulsantecopertina",
      campovalore: null,
    },

    campo_linkcopertina: {
      nomecampo: "linkcopertina",
      campovalore: null,
    },

    campo_orario: {
      nomecampo: "orario",
      campovalore: null,
    },

    campo_descrizione: {
      nomecampo: "descrizione",
      campovalore: null,
    },

    campo_eventi_tipologia: {
      nomecampo: "eventi_tipologia",
      campovalore: null,
    },

    campo_eventi_categoria: {
      nomecampo: "eventi_categoria",
      campovalore: null,
    },

    campo_associazioni1: {
      nomecampo: "associazioni",
      campovalore: this.props.location.state === undefined ? null : this.props.location.state.idAssociazione,
    },


    campo_homepage: {
      nomecampo: "homepage",
      campovalore: null,
    },

    //#endregion 

    //#region Gestione stato per ricerca Tipologia      
    risultatiRicercaTipologiaEventi: null,
    risultatiPerPaginaTipologiaEventi: 6,
    queryOffsetTipologiaEventi: 0,
    ricercaEseguitaTipologiaEventi: false,
    messaggioErroreTipologiaEventi: null,
    countdatidagraficareTipologiaEventi: null,

    //#region Gestione stato per ricerca Categoria      
    risultatiRicercaCategoriaEventi: null,
    risultatiPerPaginaCategoriaEventi: 50,
    queryOffsetCategoriaEventi: 0,
    ricercaEseguitaCategoriaEventi: false,
    messaggioErroreCategoriaEventi: null,
    countdatidagraficareCategoriaEventi: null,

    //#endregion

    //#region Gestione stato per ricerca Contenuto      
    risultatiRicercaContenuto: null,
    risultatiPerPaginaContenuto: 6,
    queryOffsetContenuto: 0,
    ricercaEseguitaContenuto: false,
    messaggioErroreContenuto: null,
    countdatidagraficareContenuto: null,
    //#endregion

    messaggioAggiornamento: null,

    testopulsante: "Inserisci evento",
  };

  //#region HandlerCambiamentiAllegati


  AllegatiCambiatoHandler = (event, inputGroup, key, state) => {
    if (event.target.files[0] !== null) {
      getBase64key(event.target.files[0], this.AllegatiCambiatoBase64Handler,
        event.target.files[0].name, inputGroup, key, state, event.target.files[0].size);
    }
    else {
      state[key].nome = null;
      state[key].base64 = null;
      state[key].dimensione = null;
      inputGroup.checkValidityAndUpdate();
    }

  }

  AllegatiCambiatoBase64Handler = (base64, nome, inputGroup, key, state, size) => {
    state[key].nome = nome;
    state[key].base64 = base64;
    state[key].dimensione = size;
    inputGroup.checkValidityAndUpdate();
  }


  //#endregion


  //#region HandlerCambiamenti Campi

  campiCambiatoHandler = (event, key, state) => {
    state[key].campovalore = event.target.value;
  }

  eventi_tipologiaCambiatoHandler = (event) => {

    var checkboxes = document.getElementsByName("eventi_tipologia");
    var checkboxvalue = "";

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        checkboxvalue += checkboxes[i].value + ","
      }
    }

    this.setState({
      campo_eventi_tipologia: {
        nomecampo: "eventi_tipologia",
        campovalore: checkboxvalue,
      },
    });
  }

  eventi_categoriaCambiatoHandler = (event) => {

    var checkboxes = document.getElementsByName("eventi_categoria");
    var checkboxvalue = "";

    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        checkboxvalue += checkboxes[i].value + ","
      }
    }

    this.setState({
      campo_eventi_categoria: {
        nomecampo: "eventi_categoria",
        campovalore: checkboxvalue,
      },
    });
  }


  homepageCambiatoHandler = (event) => {

    this.setState({
      
      campo_homepage: {
        nomecampo: "homepage",
        campovalore: !this.state.campo_homepage.campovalore,
      },


    });

    

    // for (var i = 0; i < checkboxes.length; i++) {
    //   if (checkboxes[i].checked) {
    //     checkboxvalue += checkboxes[i].value + ","
    //   }
    // }

    // this.setState({
    //   campo_eventi_tipologia: {
    //     nomecampo: "eventi_tipologia",
    //     campovalore: checkboxvalue,
    //   },
    // });

  }







  //#endregion

  // #region Render
  render() {

    

    if (this.props.loginToken === globals.tokenGenerico) {
      //Non sono loggato nel sistema ma sto accedendo come utente generico faccio logout
      // this.props.effettuaLogout();
    }

    if (this.props.userProfile.cf === "") {
      //Non sono loggato nel sistema ma sto accedendo come utente generico faccio logout
      // this.props.effettuaLogout();
    }

    if (this.props.location.state === undefined || this.props.location.state.idAssociazione === undefined
      || this.props.location.state.idAssociazione === null || parseInt(this.props.location.state.idAssociazione) < 0) {
      // return <Redirect to={{ pathname: "/AssociazioniRicercaAdmin" }} />
    }


    const handleSubmita = (form) => {

      console.log("form",form);

      var validataform = form.checkValidity();

      if (validataform === false) {
      }
      else {
        this.setState({
          formreadonly: true,
        });

        this.gestiscicoordinate(this.AggiornamentoDBCorretto, this.AggiornamentoDBSbagliato,
          this.AggiornamentoDBCorretto, this.errorDocumenti, this.AggiornamentoCompletato, this.state, this.creazioneContenuto, this.gestiscicoordinate, null, null, "");
      }
    };

    const onInput = (e, inputGroup) => {
      inputGroup.checkValidityAndUpdate();
    };

    const deleteItem = (e) => {
      e.preventDefault();
      this.gestiscicoordinate(this.AggiornamentoDBCorretto, this.AggiornamentoDBSbagliato,
        this.AggiornamentoDBCorretto, this.errorDocumenti, this.AggiornamentoCompletato, this.state, this.creazioneContenuto, this.gestiscicoordinate, null, null, "");
    };

    const deleteItemNo = (e) => {
      e.preventDefault();
    };

    const deleteFile = (e, chiave) => {
      e.preventDefault();
      this.EliminazioneFile(this.ContenutoEliminatoOK, this.ContenutoEliminatoKO,
        this.state, chiave);
    };

    const deleteFileNO = (e) => {
      e.preventDefault();
    };

    return (
      <div className="container px-4">
        {this.state.idContenutoSelezionato === 0 || (this.state.idContenutoSelezionato !== 0 &&
          this.state.ricercaEseguitaContenuto === true)
          ?
          <Form onSubmit={handleSubmita} >
            {({ error, valid, pristine, submitting, form }) => (
              <>
                {error && (<div className="alert alert-danger mx-0" role="alert">
                  <strong>Errori durante la compilazione del dato</strong> {error}
                </div>)
                }
                <Row className="my-2"><Col>
                  <h3 className="h1" id="ArchivioContenutiTitolo">Dati Evento</h3>
                </Col></Row>
                {this.state.messaggioAggiornamento}
                <InputGroup className="pb-3 clearfix "
                  validate={["Email"]}
                  translate={{
                    Email: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                      typeMismatch: "Quello inserito non è un indirizzo mail valido"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3 clearfix ">
                      <label htmlFor="EmailInput">Email *</label>
                      <input
                        readOnly={this.state.formreadonly}
                        required={true}
                        className={`form-control  ${!valid && "is-invalid"}`}
                        id="EmailInput"
                        name="Email"
                        defaultValue={this.state.campo_Email.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Email", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Inserisci un indirizzo email"
                        placeholder="Inserisci un indirizzo email"
                        type="email" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3 clearfix "
                  validate={["Telefono"]}
                  translate={{
                    Telefono: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3 clearfix ">
                      <label htmlFor="TelefonoInput">Recapito telefonico *</label>
                      <input
                        readOnly={this.state.formreadonly}
                        required={true}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="TelefonoInput"
                        name="Telefono"
                        defaultValue={this.state.campo_Telefono.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Telefono", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Inserisci un numero di telefono"
                        placeholder="Inserisci un numero di telefono" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3 clearfix "
                  validate={["Titolo"]}
                  translate={{
                    Titolo: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Questo campo è obbligatorio"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3 clearfix ">
                      <label htmlFor="TitoloInput">Titolo evento *</label>
                      <input
                        readOnly={this.state.formreadonly}
                        required={true}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="TitoloInput"
                        name="Titolo"
                        defaultValue={this.state.campo_Titolo.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Titolo", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Inserisci il titolo dell'evento"
                        placeholder="Inserisci il titolo dell'evento" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3 clearfix "
                  validate={["luogo"]}
                  translate={{
                    luogo: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                      tooShort: "Il campo deve contenere almeno 20 caratteri"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3 clearfix ">
                      <label htmlFor="luogoInput">luogo *</label>
                      <input
                        readOnly={this.state.formreadonly}
                        required={true}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="luogoInput"
                        name="luogo"
                        defaultValue={this.state.campo_luogo.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_luogo", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Inserisci il luogo dove si svolgerà l'evento"
                        placeholder="Inserisci il luogo dove si svolgerà l'evento" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3 clearfix "
                  validate={["Strillo"]}
                  translate={{
                    Strillo: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                      tooShort: "Il campo deve contenere almeno 20 caratteri"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3 clearfix ">
                      <label htmlFor="StrilloInput">Strillo *</label>
                      <textarea
                        readOnly={this.state.formreadonly}
                        required={true}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="StrilloInput"
                        defaultValue={this.state.campo_Strillo.campovalore}
                        name="Strillo"
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_Strillo", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Inserisci un breve strillo descrittivo dell'evento"
                        placeholder="Inserisci un breve strillo descrittivo dell'evento" minLength={20} maxLength={100} />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                {this.state.risultatiRicercaTipologiaEventi === null
                  ?
                  <Spinner />
                  :
                  <InputGroup className="pb-3" validate={{
                    "eventi_tipologia": () => {
                      var checkboxes = document.getElementsByName("eventi_tipologia");
                      var numberOfCheckedItems = 0;

                      for (var i = 0; i < checkboxes.length; i++) {
                        if (checkboxes[i].checked)
                          numberOfCheckedItems++;
                      }

                      if (numberOfCheckedItems === 0) {
                        return false;
                      }
                      else {
                        return true;
                      }
                    }
                  }}>
                    {({ error, valid }) => (
                      <div className="form-group">
                        <fieldset>
                          <legend>Ambito Eventi *</legend>
                          {this.renderRisultatiRicercaTipologiaEventi(valid)}
                        </fieldset>
                        {valid ? "" : (<div className="invalid-feedback alert alert-danger mx-0" style={{ display: 'block' }}>Devi selezionare almeno un valore tra quello presente</div>)}

                      </div>
                    )}
                  </InputGroup>
                }
                {this.state.risultatiRicercaCategoriaEventi === null
                  ?
                  <Spinner />
                  :
                  <InputGroup className="pb-3" validate={{
                    "eventi_categoria": () => {
                      var checkboxes = document.getElementsByName("eventi_categoria");
                      var numberOfCheckedItems = 0;

                      for (var i = 0; i < checkboxes.length; i++) {
                        if (checkboxes[i].checked)
                          numberOfCheckedItems++;
                      }

                      if (numberOfCheckedItems === 0) {
                        return false;
                      }
                      else {
                        return true;
                      }
                    }
                  }}>
                    {({ error, valid }) => (
                      <div className="form-group">
                        <fieldset>
                          <legend>Categoria Eventi *</legend>
                          {this.renderRisultatiRicercaCategoriaEventi(valid)}
                        </fieldset>
                        {valid ? "" : (<div className="invalid-feedback alert alert-danger mx-0" style={{ display: 'block' }}>Devi selezionare almeno un valore tra quello presente</div>)}

                      </div>
                    )}
                  </InputGroup>
                }
               
               
               {/* <InputGroup className="pb-3 clearfix "> */}
                  <div role="group" className="form-group" style={{marginBottom:"20px"}} >
                <input
                  
                  onChange={(event) => { this.homepageCambiatoHandler(event); }}
                  name="homepage"
                  type="checkbox"
                  className={`form-check-input `}
                  id={'inline-checkbox-homepage' }
                  validate={["homepage"]}
                  disabled={this.state.formreadonly}
                  value={1} />
                <label className="form-check-label" htmlFor={'inline-checkbox-homepage'}> Visibile nel calendario homepage	</label>
              </div>
               {/* </InputGroup> */}
               
               
               
               
               
               
               
               
               
               
               
                <InputGroup className="pb-3 clearfix "
                  validate={{
                    "DataInizio": (input) => {

                      var DataInizio = document.getElementById("DataInizioInput");
                      var DataFine = document.getElementById("DataFineInput");

                      // console.log("DataInizio", DataInizio);
                      // console.log("DataFine",DataFine);
                

                      if (DataInizio != null && DataFine != null && DataInizio.value !== "" && DataFine.value !== "" && ( Date.parse(DataInizio.value) > Date.parse(DataFine.value) ) ) {
                        input.setCustomValidity("La data di inizio non può essere minore della data di fine");
                        return false;
                      }
                      else {
                        return true;
                      }
                    },
                    "DataFine": (input) => {

                      var DataInizio = document.getElementById("DataInizioInput");
                      var DataFine = document.getElementById("DataFineInput");

                      if (DataInizio != null && DataFine != null && DataInizio.value !== "" && DataFine.value !== "" && DataInizio.value > DataFine.value) {
                        input.setCustomValidity("La data di inizio non può essere minore della data di fine");
                        return "false";
                      }
                      else {
                        return true;
                      }
                    }
                  }}
                  translate={{
                    DataInizio: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito nella data di inizio non rispecchia il formato richiesto dal campo - giorno/mese/anno 31/12/2021",
                      tooShort: "Il campo deve contenere almeno 20 caratteri"
                    },
                    DataFine: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito nella data di fine non rispecchia il formato richiesto dal campo - giorno/mese/anno 31/12/2021",
                      tooShort: "Il campo deve contenere almeno 20 caratteri"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group">
                      <div className="row">
                        <div className="col-6 float-left">
                          <label htmlFor="DataInizioInput">DataInizio *</label>
                          <input
                            readOnly={this.state.formreadonly}
                            required={true}
                            className={`form-control ${!valid && "is-invalid"}`}
                            id="DataInizioInput"
                            name="DataInizio"
                            defaultValue={this.state.campo_DataInizio.campovalore}
                            onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_DataInizio", this.state); }}
                            onInput={(e) => onInput(e, inputGroup, form)}
                            pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                            autoComplete="off"
                            aria-label="Data di inizio dell'evento nel formato giorno/mese/anno ad esempio 31/12/2021 "
                            placeholder="Data di inizio dell'evento nel formato giorno/mese/anno ad esempio 31/12/2021 " />
                        </div><div className="col-6 float-right">
                          <label htmlFor="DataFineInput">DataFine *</label>
                          <input
                            readOnly={this.state.formreadonly}
                            required={true}
                            className={`form-control ${!valid && "is-invalid"}`}
                            id="DataFineInput"
                            name="DataFine"
                            defaultValue={this.state.campo_DataFine.campovalore}
                            onInput={(e) => onInput(e, inputGroup, form)}
                            onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_DataFine", this.state); }}
                            pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
                            autoComplete="off"
                            aria-label="Data di fine dell'evento nel formato giorno/mese/anno ad esempio 31/12/2021"
                            placeholder="Data di fine dell'evento nel formato giorno/mese/anno ad esempio 31/12/2021" />

                        </div>   </div>{error && (<div className="col-12 clerfix alert alert-danger mx-0 my-2" style={{ clear: "both" }}>{error}</div>)}  </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3 clearfix "
                  validate={["orario"]}
                  translate={{
                    orario: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                      typeMismatch: "Quello inserito non è un indirizzo PEC valido"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3 clearfix ">
                      <label htmlFor="orarioInput">orario *</label>
                      <input
                        readOnly={this.state.formreadonly}
                        required={true}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="orarioInput"
                        name="orario"
                        defaultValue={this.state.campo_orario.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_orario", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Orario evento"
                        placeholder="Orario evento" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <div className="pb-3" style={{ width: "100%" }}>
                  <Row className="my-2"><Col>
                    <label>Descrizione</label>
                    <CKEditor
                      editor={ClassicEditor}
                      aria-label="Descrizione"
                      disabled={this.state.formreadonly}
                      data={this.state.campo_descrizione.campovalore}
                      config={{
                        toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'link', 'numberedList', 'bulletedList', '|', 'undo', 'redo']
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        this.setState({
                          campo_descrizione: {
                            nomecampo: "descrizione",
                            campovalore: data,
                          }
                        });
                      }}
                    />
                  </Col></Row></div>
                <Row className="my-2"><Col>
                  <h4>Immagini di anteprima e copertina con eventuali link</h4>
                </Col></Row>
                <InputGroup className="pb-3 clearfix "
                  validate={{
                    "allegato_fotopiccola": (input) => {

                      if (this.state.file_campo_fotopiccola.nome === null || this.state.file_campo_fotopiccola.nome === "") {
                        return true;
                      }
                      else {
                        if (!validfileimage.test(this.state.file_campo_fotopiccola.nome)) {
                          input.setCustomValidity("Il file caricato deve essere un'immagine");
                          return false;
                        } else {
                          if (this.state.file_campo_fotopiccola.dimensione > 10000 * 1024) {
                            input.setCustomValidity("Il file caricato non deve superare i 10 MB");
                            return false;
                          }
                          else {
                            return true;
                          }
                        }
                      }

                    }
                  }}
                  translate={{
                    allegato_fotopiccola: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3 clearfix ">
                      <label htmlFor="allegato_fotopiccolaInput">Foto di anteprima dell'evento</label>
                      <input
                        disabled={this.state.formreadonly}
                        className={`form-control-file ${!valid && "is-invalid"}`}
                        id="allegato_fotopiccolaInput"
                        name="allegato_fotopiccola"
                        onChange={(event) => { this.AllegatiCambiatoHandler(event, inputGroup, "file_campo_fotopiccola", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        placeholder="Inserisci una foto di anteprima per l'associazione"
                        accept="image/*"
                        aria-label="Inserisci una foto di anteprima per l'associazione"
                        type="file" />
                      {
                        this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                        && this.state.file_campo_fotopiccola.link !== ""
                        && (<div className="mx-0">{"Il file precedentemente caricato risulta essere "} <a target="_blank" rel="noreferrer" title={this.state.file_campo_fotopiccola.nometestualecampo + " " + this.state.file_campo_fotopiccola.link} aria-label={this.state.file_campo_fotopiccola.nometestualecampo + this.state.file_campo_fotopiccola.link} href={this.state.file_campo_fotopiccola.link.toString().replace('../../', '').replaceAll('&amp;', '&')}>Scarica file</a></div>)
                      }
                      {
                        this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0 && this.props.location.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                        && this.state.file_campo_fotopiccola.link !== ""
                        && (<Button className="btnlink" aria-label={"Elimina il file " + this.state.file_campo_fotopiccola.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                          title={"Elimina il file " + this.state.file_campo_fotopiccola.link.toString().replace('../../', '').replaceAll('&amp;', '&')} type="submit" onClick={(e) => { if (window.confirm('Sei sicuro di voler eliminare questo record?')) { deleteFile(e, "file_campo_fotopiccola") } else { deleteFileNO(e) } }}>
                          Elimina File
                        </Button>)
                      }
                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3 clearfix "
                  validate={{
                    "allegato_immaginecopertina": (input) => {

                      if (this.state.file_campo_immaginecopertina.nome === null || this.state.file_campo_immaginecopertina.nome === "") {
                        return true;
                      }
                      else {
                        if (!validfileimage.test(this.state.file_campo_immaginecopertina.nome)) {
                          input.setCustomValidity("Il file caricato deve essere un'immagine");
                          return false;
                        } else {
                          if (this.state.file_campo_immaginecopertina.dimensione > 10000 * 1024) {
                            input.setCustomValidity("Il file caricato non deve superare i 10 MB");
                            return false;
                          }
                          else {
                            return true;
                          }
                        }
                      }

                    }
                  }}
                  translate={{
                    allegato_immaginecopertina: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3 clearfix ">
                      <label htmlFor="allegato_immaginecopertinaInput">Foto di dettaglio dell'evento</label>
                      <input
                        disabled={this.state.formreadonly}
                        className={`form-control-file ${!valid && "is-invalid"}`}
                        id="allegato_immaginecopertinaInput"
                        name="allegato_immaginecopertina"
                        onChange={(event) => { this.AllegatiCambiatoHandler(event, inputGroup, "file_campo_immaginecopertina", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        aria-label="Inserisci una foto di anteprima per l'associazione"
                        placeholder="Inserisci una foto di anteprima per l'associazione"
                        accept="image/*"
                        type="file" />
                      {
                        this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                        && this.state.file_campo_immaginecopertina.link !== ""
                        && (<div className="mx-0">{"Il file precedentemente caricato risulta essere "} <a target="_blank" rel="noreferrer" title={this.state.file_campo_fotopiccola.nometestualecampo + " " + this.state.file_campo_fotopiccola.link} aria-label={this.state.file_campo_immaginecopertina.nometestualecampo + this.state.file_campo_immaginecopertina.link} href={this.state.file_campo_immaginecopertina.link.toString().replace('../../', '').replaceAll('&amp;', '&')}>Scarica file</a></div>)
                      }
                      {
                        this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0 && this.state.ricercaEseguitaContenuto === true
                        && this.state.file_campo_immaginecopertina.link !== ""
                        && (<Button className="btnlink" aria-label={"Elimina il file " + this.state.file_campo_immaginecopertina.link.toString().replace('../../', '').replaceAll('&amp;', '&')}
                          title={"Elimina il file " + this.state.file_campo_immaginecopertina.link.toString().replace('../../', '').replaceAll('&amp;', '&')} type="submit" onClick={(e) => { if (window.confirm('Sei sicuro di voler eliminare questo record?')) { deleteFile(e, "file_campo_immaginecopertina") } else { deleteFileNO(e) } }}>
                          Elimina File
                        </Button>)
                      }
                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3 clearfix "
                  validate={["titolocopertina"]}
                  translate={{
                    titolocopertina: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Questo campo è obbligatorio"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3 clearfix ">
                      <label htmlFor="titolocopertinaInput">Titolo Copertina</label>
                      <input
                        readOnly={this.state.formreadonly}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="titolocopertinaInput"
                        name="titolocopertina"
                        defaultValue={this.state.campo_titolocopertina.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_titolocopertina", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Titolo della copertina che rappresenta l'evento"
                        placeholder="Titolo della copertina che rappresenta l'evento" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3 clearfix "
                  validate={["sottotitolocopertina"]}
                  translate={{
                    sottotitolocopertina: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3 clearfix ">
                      <label htmlFor="sottotitolocopertinaInput">Sotto Titolo Copertina</label>
                      <input
                        readOnly={this.state.formreadonly}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="sottotitolocopertinaInput"
                        name="sottotitolocopertina"
                        defaultValue={this.state.campo_sottotitolocopertina.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_sottotitolocopertina", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Sottotitolo della copertina che rappresenta l'evento"
                        placeholder="Sottotitolo della copertina che rappresenta l'evento" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3 clearfix "
                  validate={["testopulsantecopertina"]}
                  translate={{
                    testopulsantecopertina: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3 clearfix ">
                      <label htmlFor="testopulsantecopertinaInput">Testo bottone presentazione</label>
                      <input
                        readOnly={this.state.formreadonly}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="testopulsantecopertinaInput"
                        name="testopulsantecopertina"
                        defaultValue={this.state.campo_testopulsantecopertina.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_testopulsantecopertina", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Testo link della copertina che rappresenta l'evento"
                        placeholder="Testo link della copertina che rappresenta l'evento" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <InputGroup className="pb-3 clearfix "
                  validate={["linkcopertina"]}
                  translate={{
                    linkcopertina: {
                      valueMissing: "Questo campo è obbligatorio",
                      patternMismatch: "Il valore inserito non rispecchia il formato richiesto dal campo",
                      typeMismatch: "Quello inserito non è un indirizzo PEC valido"
                    }
                  }}>
                  {({ error, valid, inputGroup }) => (
                    <div className="form-group pb-3 clearfix ">
                      <label htmlFor="linkcopertinaInput">Link  </label>
                      <input
                        readOnly={this.state.formreadonly}
                        className={`form-control ${!valid && "is-invalid"}`}
                        id="linkcopertinaInput"
                        name="linkcopertina"
                        defaultValue={this.state.campo_linkcopertina.campovalore}
                        onKeyUp={(event) => { this.campiCambiatoHandler(event, "campo_linkcopertina", this.state); }}
                        onInput={(e) => onInput(e, inputGroup, form)}
                        autoComplete="off"
                        aria-label="Link della copertina che rappresenta l'evento"
                        placeholder="Link link della copertina che rappresenta l'evento"
                        type="link" />

                      {error && (<div className="invalid-feedback alert alert-danger mx-0">{error}</div>)}
                    </div>
                  )}
                </InputGroup>
                <Row className="my-2"><Col>
                  {this.state.actions === "elimina" ?
                    <Button title="Elimina" aria-label="Elimina" type="submit" onClick={(e) => { if (window.confirm('Sei sicuro di voler eliminare questo record?')) { deleteItem(e) } else { deleteItemNo(e) } }}>
                      Elimina
                    </Button>
                    : ""}
                  {this.state.actions === "" || this.state.actions !== "elimina" ?
                    <Button title={this.state.testopulsante} aria-label={this.state.testopulsante} type="submit" disabled={this.state.formreadonly}>{this.state.testopulsante}</Button> : ""}
                </Col></Row>
                {this.state.messaggioAggiornamento}
              </>
            )}
          </Form>
          : <Spinner />
        }
        {this.state.idContenutoSelezionato !== 0 &&
          this.state.ricercaEseguitaContenuto === true && this.state.actions !== "copia" && this.state.actions !== "elimina"
          ? <div><GalleriaImmaginiRicercaRecordAdmin idContenutoCollegato={this.state.idContenutoSelezionato} nomefunzionetabellaallegata="t_eventi" />
            <DocumentiAllegatiRicercaRecordAdmin idContenutoCollegato={this.state.idContenutoSelezionato} nomefunzionetabellaallegata="t_eventi" />
            <VideoAllegatiRicercaRecordAdmin idContenutoCollegato={this.state.idContenutoSelezionato} nomefunzionetabellaallegata="t_eventi" />
            <ArchiviAllegatiRicercaRecordAdmin idContenutoCollegato={this.state.idContenutoSelezionato} nomefunzionetabellaallegata="t_eventi" /></div>
          : null
        }

      </div>
    );
  }

  componentDidMount = () => {

    if (this.state.idContenutoSelezionato !== 0 && this.state.idContenutoSelezionato > 0) {
      this.performSearchContenuto(this.setRichiedentiResultsContenuto, this.setErrorMessageContenuto, this.performSearchContenuto, this.state);
    }

    this.performSearchTipologiaEventi(this.setRichiedentiResultsTipologiaEventi, this.setErrorMessageTipologiaEventi, this.performSearchTipologiaEventi);
    this.performSearchCategoriaEventi(this.setRichiedentiResultsCategoriaEventi, this.setErrorMessageCategoriaEventi, this.performSearchCategoriaEventi);

  }
  //#endregion 

  // #region GestioneInvioDati Al Server
  /* In caso ci siano campi con indirizzi se compilati vengono tradotti in coordinate e inviati come campi al server*/
  gestiscicoordinate = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, successgestiscicoordinate, gestiscicoordinate, results, status, keyprec) => {

    var coordinatepresenti = false;
    var indirizzo = '';
    var coordinatakey = "";

    /*Se sono presenti lo status e la chiave, l'indirizzo è stato inviato a Google per essere tradotto in coordinate*/
    /*Mi segno che sono state tradotte e in caso lo stato sia OK imposto anche le coordinate*/
    if (keyprec !== "") {
      state[keyprec].inviato = true;
      if (status !== null) {
        state[keyprec].inviato = true;
        if (status === 'OK') {
          state[keyprec].coordinatax = results[0].geometry.location.lat();
          state[keyprec].coordinatay = results[0].geometry.location.lng();
          state[keyprec].titolo = state[keyprec].campovalore;
        }
      }
    }

    if (this.state.actions !== "elimina") {

      /*Cerco tra i vari campi dello se ci sono degli indirizzi per inviarle a google*/
      for (const [key, value] of Object.entries(state)) {
        if (key.indexOf("campo_") === 0) {
          if (value.coordinatax !== undefined && value.coordinatay !== undefined) {
            if (value.coordinatax === null &&
              value.coordinatay === null && value.campovalore !== null) {
              indirizzo = value.campovalore;
              coordinatepresenti = true;
              coordinatakey = key;
              break;
            }
          }
        }

      }
    }

    /*Se ci sono indirizzi da trasformare in coordinate le provo a trasformare in caso contrario passo alla fase di invio*/
    if (coordinatepresenti) {
      var geocoder = new this.props.google.maps.Geocoder();

      geocoder.geocode({ 'address': indirizzo }, function (results, status) {
        if (status === 'OK') {
          gestiscicoordinate(successFnDb,
            errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, successgestiscicoordinate, gestiscicoordinate, results, status, coordinatakey);
        } else {
          gestiscicoordinate(successFnDb,
            errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, successgestiscicoordinate, gestiscicoordinate, null, status, coordinatakey);
        }
      });
    }
    else {
      successgestiscicoordinate(successFnDb,
        errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state);
    }

  }

  /*Invio contenuto al db*/
  creazioneContenuto = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state) => {

    var TestoAggiornamento = (<div> <strong>Aggiornamento dei dati in corso si prega di attendere</strong></div>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
    });

    var campidaimpostare = [];

    var method = "";
    var eliminarecordacascata = null;

    if (state.idContenuto !== 0) {

      state["testopulsante"] = "Aggiorna Contenuto";

      if (this.state.actions === "" || this.state.actions === "modifica" || this.state.actions === "copia") {
        method = Buffer.from('getUpdateContenuto', 'utf8').toString('base64');
      }

      if (this.state.actions === "elimina") {
        method = Buffer.from('getDeleteContenuto', 'utf8').toString('base64');
        eliminarecordacascata = Buffer.from('false', 'utf8').toString('base64');
      }
    }
    else {
      method = Buffer.from('getinsertcontenuto', 'utf8').toString('base64');
    }

    for (const [key, value] of Object.entries(state)) {

      if (key.indexOf("campo_") === 0 && value !== null) {
        campidaimpostare.push(state[key]);
      }
    }


    console.log("campidaimpostare",campidaimpostare);

    var parametriserver = {
      nometabella: Buffer.from(this.state.nometabellacontenuto.toString(), 'utf8').toString('base64'),
      limiterecord: null,
      idulteriorecondizione: null,
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: null,
      elencocampi: null,
      queryoffset: null,
      querynextrows: null,
      listavaloricondizioni: null,
      contenutiindicizzati: null,
      idcamposelectcustom: null,
      elencotabellecorrelate: null,
      elencocampicorrelate: null,
      idcontenuto: null,
      campivaloridaimpostarequery: campidaimpostare,
      eliminarecordacascata: eliminarecordacascata,
    };

    if (state.idContenuto !== 0) {
      parametriserver.idcontenuto = Buffer.from(this.state.idContenuto.toString(), 'utf8').toString('base64');
    }

    const data = {
      token: this.props.loginToken,
      method: method,
      param: parametriserver,
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const dati = JSON.parse(response.data.data);

          var idinserito = dati;

          if (globals.debug < 3) console.log(dati);

          if (idinserito > 0) {
            successFnDb(successFnDb,
              errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, dati, "");
          }

        } else {

          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);

          if (parseInt(response.data.result.code) === 401) {
            loginErrorFn();
          } else {
            errorFnDb(successFnDb,
              errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, response.data.result.message);
          }


        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata eventi record admin error: " + error);
        errorFnDb(successFnDb,
          errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, error.message);
      });


    return;
  };

  /*In caso di aggiornamento corretto del DB vengono inviati al server uno alla volta i file*/
  AggiornamentoDBCorretto = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, keyprec) => {

    var allegatipresenti = false;
    var nomecampo = '';
    var file = "";
    var nomefile = "";

    var filekey = "";

    /*Il file che ho inviato in precedenza è stato caricato correttamente*/
    /*Segno che è stato inviato e che è stato inviato con successo*/
    if (keyprec !== "") {
      state[keyprec].inviato = true;
      state[keyprec].errore = false;
    }

    /* Ciclo per i campi dello stato che sono dei campi file e invio quelli che sono stati caricati dall'utente
    e non sono ancora stati inviati*/
    for (const [key, value] of Object.entries(state)) {
      if (key.indexOf("file_campo_") === 0) {
        if (value.base64 !== null && value.nome !== null && value.inviato === false) {
          nomecampo = key.replace("file_campo_", '');
          file = value.base64;
          nomefile = value.nome;
          allegatipresenti = true;
          filekey = key;
          break;
        }
      }
    }

    /* Se il nome del campo è stato compilato (ovvero ho un file da inviare)
    lo encodo in base 64*/
    if (nomecampo !== "") {
      nomecampo = Buffer.from(nomecampo, 'utf8').toString('base64')
    }

    /* Se ho degli allegati da inviare provo ad inviarli in caso contrario vado alla fase di conclusione dell'aggiornamento*/
    if (allegatipresenti) {

      var TestoAggiornamento = (<React.Fragment>{this.state.messaggioAggiornamento}
        <div>  <strong>Invio di un documento in corso si prega di attendere</strong></div></React.Fragment>);

      this.setState({
        messaggioAggiornamento: TestoAggiornamento,
      });

      var parametriserver = {
        nometabella: Buffer.from(this.state.nometabellacontenuto.toString(), 'utf8').toString('base64'),
        limiterecord: null,
        idulteriorecondizione: null,
        idordinamento: null,
        numeropaginazione: null,
        idselecustom: null,
        selectdistinct: null,
        usaselectwithnolock: null,
        elencocampi: null,
        queryoffset: null,
        querynextrows: null,
        listavaloricondizioni: null,
        contenutiindicizzati: null,
        idcamposelectcustom: null,
        elencotabellecorrelate: null,
        elencocampicorrelate: null,
        idcontenuto: Buffer.from(idDbAggiornato.toString(), 'utf8').toString('base64'),
        base64fileinputfieldname: nomecampo,
        base64fileinputfile: file,
        base64fileinputfilename: nomefile,
      };


      const data = {
        token: this.props.loginToken,
        method: Buffer.from('getinsercontenutoallegato', 'utf8').toString('base64'),
        param: parametriserver,
      };

      const loginErrorFn = () => {
        this.props.effettuaLogout();
      }

      axios.post(globals.wsURLCliente, data)
        .then(function (response) {

          if (response.data.result.success === true) {
            successFnDb(successFnDb,
              errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey);

          } else {
            if (globals.debug < 3) console.log(response.data.result.message);

            if (parseInt(response.data.result.code) === 401) {
              loginErrorFn();
            } else {
              errorFnDb(successFnDb,
                errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, response.data.result.message, idDbAggiornato, filekey);
            }


          }
        })
        .catch(function (error) {
          if (globals.debug < 3) console.log("chiamata eventi record admin error: " + error);
          errorFnDb(successFnDb,
            errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, error.message, idDbAggiornato, filekey);
        });

    }
    else {
      successFNAggiornamento(successFnDb,
        errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey);

    }

    return;
  }

  /* Se uno dei file va in errore segno l'invio fallito e procedo con il resto delle operazioni*/
  errorDocumenti = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, messaggioErrore, idDbAggiornato, keyprec) => {

    var TestoAggiornamento = (<React.Fragment>{this.state.messaggioAggiornamento}
      <div>  <strong>Invio di un documento in corso si prega di attendere</strong></div></React.Fragment>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
    });


    if (keyprec !== "") {
      state[keyprec].inviato = true;
      state[keyprec].errore = true;
    }

    successFnDb(successFnDb,
      errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato);

  }

  /*Se l'utente lo desidera appena aggiornato un record lo metto in modifica*/
  AbilitaModifica = (event) => {
    this.setState({
      formreadonly: false,
      messaggioAggiornamento: false,
      testopulsante: "Aggiorna Contenuto",
    });
  };

  /*Ho completato l'aggiornamento e do un informazione completa all'utente sullo stato di aggiornamento degli eventuali file*/
  AggiornamentoCompletato = (successFnDb,
    errorFnDb, successFnDoc, errorFnDoc, successFNAggiornamento, state, idDbAggiornato, filekey) => {

    if (this.state.actions === "elimina") {
      window.history.go(-1);
    }
    var DocumentiAllegati = [];

    for (const [key, value] of Object.entries(state)) {
      if (key.indexOf("file_campo_") === 0) {
        var nometestualecampo = value.nometestualecampo;
        switch (value.base64) {
          case null:
            if (value.link === undefined || value.link === "" || value.link === null)
              DocumentiAllegati.push(<p>Il documento {nometestualecampo} - è stato deciso di non caricarlo lo si potrà caricare durante l'aggiornamento dello stesso</p>);
            break;
          default:
            if (value.inviato === true && value.errore === false) {
              DocumentiAllegati.push(<p>Il documento {nometestualecampo} - è stato caricato correttamente</p>);
            }
            else {
              DocumentiAllegati.push(<p>Il documento {nometestualecampo} - ha avuto errore in fase di caricamento</p>);
            }
            break;
        }
      }

    }

    DocumentiAllegati.push(<Row><Button type="submit" onClick={this.AbilitaModifica}>Abilita Modifica</Button></Row>);

    var TestoAggiornamento = (<div> <strong>Aggiornamento avvenuto correttamente</strong>{DocumentiAllegati}</div>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
      idContenuto: idDbAggiornato,
    });
  }

  /*Ci sono errori nell'aggiornamento del record avverto l'utente e non provo neanche ad inviare i file*/
  AggiornamentoDBSbagliato = (messaggioErrore) => {

    var TestoAggiornamento = (<div> <strong>Aggiornamento dei dati non possibile si prega di riprovare più tardi</strong></div>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
      formreadonly: false,
    });

  }

  //#endregion 

  //#region Modifica/Copia/Delete contenuto
  /*Per mettere in modifica un record lo carico dal db ed imposto per i vari campi del form il valore di default*/
  performSearchContenuto = (successFn, errorFn, tokenScadutoFn, state) => {

    var parametriserver = {
      nometabella: Buffer.from(this.state.nometabellacontenuto.toString(), 'utf8').toString('base64'),
      limiterecord: null,
      idulteriorecondizione: null,
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: null,
      elencocampi: Buffer.from('id;associazioni;datafine;datainizio;descrizione;email;eventi_tipologia;fotogrande;fotopiccola;homepage;immaginecopertina;Link;linkcopertina;luogo;orario;sezionescroll;sottotitolocopertina;strillo;telefono;testopulsantecopertina;Titolo;titolocopertina;eventi_categoria;', 'utf8').toString('base64'),
      queryoffset: null,
      querynextrows: null,
      listavaloricondizioni: null,
      contenutiindicizzati: null,
      idcamposelectcustom: null,
      idcontenuto: Buffer.from(this.state.idContenutoSelezionato, 'utf8').toString('base64'),
    };

    const data = {
      token: this.props.loginToken,
      method: Buffer.from('getdaticontenuto', 'utf8').toString('base64'),
      param: parametriserver,
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const dati = JSON.parse(response.data.data);

          var countdatidagraficareContenuto = dati[0]["countelementi"];

          var paginaCorrenteContenuto = dati[0]["numeropagina"];

          if (globals.debug < 3) console.log(dati);

          dati.shift();

          successFn(dati, countdatidagraficareContenuto, paginaCorrenteContenuto, state);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);
          if (parseInt(response.data.result.code) === 401) {
            loginErrorFn();
          } else {
            errorFn(response.data.result.message);
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata eventi record admin error: " + error);
        errorFn(error.message);
      });


    return;
  }

  setRichiedentiResultsContenuto = (data, countdatidagraficareContenuto, paginaCorrenteContenuto, state) => {

    for (const [key, value] of Object.entries(state)) {

      var chiave = "";

      if (key.indexOf("campo_") === 0) {

        chiave = key.replace("campo_", "").toLocaleLowerCase();

        if (key === "campo_eventi_tipologia") {
          if (data[0]["eventi_tipologia_nn1"] !== undefined) {
            state[key].campovalore = data[0]["eventi_tipologia_nn1"];
          }
        }
        else {
          if (key === "campo_eventi_categoria") {
            if (data[0]["eventi_categoria_nn1"] !== undefined) {
              state[key].campovalore = data[0]["eventi_categoria_nn1"];
            }
          } else {

            if (data[0][chiave] !== undefined) {

              if (data[0][chiave].indexOf('00:00:00') >= 0) {
                state[key].campovalore = data[0][chiave].replace('00:00:00', '').trim();
              }
              else {
                state[key].campovalore = data[0][chiave];
              }

            }
          }


        }

        if (key.toLowerCase() === "campo_luogo".toLowerCase()) {
          state[key].coordinatax = null;
          state[key].coordinatay = null;
        }
      }

      if (key.indexOf("file_campo_") === 0) {
        chiave = key.replace("file_campo_", "").toLocaleLowerCase();
        if (data[0][chiave] !== undefined) {
          state[key].nometestualecampo = value.nometestualecampo;
          state[key].base64 = null;
          state[key].nome = data[0][chiave];
          if (data[0][chiave + "_link"] !== "" && data[0][chiave + "_link"] !== undefined && this.state.actions !== "copia") {
            state[key].link = process.env.REACT_APP_PUBLIC_URL + data[0][chiave + "_link"]
          }
          else {
            state[key].link = "";
          }
        }
      }
    }

    var idcontenuto = 0;
    var testopulsante = "Inserisci contenuto"

    if (this.state.actions === "copia") {
      idcontenuto = 0;
    }
    else {
      idcontenuto = this.state.idContenutoSelezionato;
      testopulsante = "Aggiorna contenuto"
    }

    this.setState({
      ricercaEseguitaContenuto: true,
      risultatiRicercaContenuto: data,
      idContenuto: idcontenuto,
      testopulsante: testopulsante,
    });


  }

  setErrorMessageContenuto = (errorMessage) => {
    this.setState({
      messaggioErroreContenuto: errorMessage,
      ricercaEseguitaContenuto: true,
    });
  }

  // #endregion    

  //#region Ricerca tipologia eventi
  performSearchTipologiaEventi = (successFn, errorFn, tokenScadutoFn) => {

    var parametriserver = {
      nometabella: Buffer.from(this.state.nometabellacontenutotipologia.toString(), 'utf8').toString('base64'),
      limiterecord: Buffer.from(this.state.risultatiPerPaginaTipologiaEventi.toString(), 'utf8').toString('base64'),
      idulteriorecondizione: null,
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
      elencocampi: Buffer.from('id;eventi_tipologia;', 'utf8').toString('base64'),
      queryoffset: Buffer.from(this.state.queryOffsetTipologiaEventi.toString(), 'utf8').toString('base64'),
      querynextrows: Buffer.from(this.state.risultatiPerPaginaTipologiaEventi.toString(), 'utf8').toString('base64'),
      listavaloricondizioni: null,
      contenutiindicizzati: Buffer.from("false", 'utf8').toString('base64'),
      idcamposelectcustom: null
    };

    const data = {
      token: this.props.loginToken,
      method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
      param: parametriserver,
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          // handle success
          const dati = JSON.parse(response.data.data);

          var countdatidagraficareTipologiaEventi = dati[0]["countelementi"];

          var paginaCorrenteTipologiaEventi = dati[0]["numeropagina"];

          if (globals.debug < 3) console.log(dati);

          dati.shift();

          successFn(dati, countdatidagraficareTipologiaEventi, paginaCorrenteTipologiaEventi);

        } else {
          // handle error
          if (globals.debug < 3) console.log(response.data.result.message);

          if (globals.debug < 3) console.log(response.data.result);

          if (parseInt(response.data.result.code) === 401) {
            loginErrorFn();
          } else {
            errorFn(response.data.result.message);
          }
        }
      })
      .catch(function (error) {
        // handle error
        if (globals.debug < 3) console.log("chiamata eventi record admin error: " + error);
        errorFn(error.message);
      });


    return;
  }

  setRichiedentiResultsTipologiaEventi = (data, countdatidagraficareTipologiaEventi, paginaCorrenteTipologiaEventi) => {

    this.setState({
      risultatiRicercaTipologiaEventi: data,
      messaggioErroreTipologiaEventi: null,
      paginaCorrenteTipologiaEventi: paginaCorrenteTipologiaEventi,
      ricercaEseguitaTipologiaEventi: true,
      countdatidagraficareTipologiaEventi: countdatidagraficareTipologiaEventi,
    });
  }

  setErrorMessageTipologiaEventi = (errorMessage) => {
    this.setState({
      messaggioErroreTipologiaEventi: errorMessage,
      ricercaEseguitaTipologiaEventi: true,
    });
  }

  renderRisultatiRicercaTipologiaEventi = (valid) => {

    const risultati = this.state.risultatiRicercaTipologiaEventi.map((obj, i) => {

      var checked = false;

      var listaselezionati = [];

      if (this.state.campo_eventi_tipologia.campovalore !== null) {
        listaselezionati = this.state.campo_eventi_tipologia.campovalore.split(',');
      }

      if (listaselezionati.length > 0 && listaselezionati.indexOf(obj.id) >= 0) {
        checked = true;
      }


      return (
        <div role="group" aria-label={obj.eventi_tipologia + obj.id} className="form-group" key={'divinline-checkbox-' + obj.id}>
          <input
            onChange={(event) => { this.eventi_tipologiaCambiatoHandler(event); }}
            name="eventi_tipologia"
            type="checkbox"
            className={`form-check-input ${!valid && "is-invalid"}`}
            id={'inline-checkbox-' + obj.id}
            checked={checked}
            disabled={this.state.formreadonly}
            value={obj.id} />
          <label className="form-check-label" htmlFor={'inline-checkbox-' + obj.id}>{obj.eventi_tipologia}</label>
        </div>
      );
    });

    return risultati;
  }

  // #endregion  

    //#region Ricerca categoria eventi
    performSearchCategoriaEventi = (successFn, errorFn, tokenScadutoFn) => {

      var parametriserver = {
        nometabella: Buffer.from(this.state.nometabellacontenutocategoria.toString(), 'utf8').toString('base64'),
        limiterecord: Buffer.from(this.state.risultatiPerPaginaCategoriaEventi.toString(), 'utf8').toString('base64'),
        idulteriorecondizione: null,
        idordinamento: null,
        numeropaginazione: null,
        idselecustom: null,
        selectdistinct: null,
        usaselectwithnolock: Buffer.from('true', 'utf8').toString('base64'),
        elencocampi: Buffer.from('id;eventi_categoria;', 'utf8').toString('base64'),
        queryoffset: Buffer.from(this.state.queryOffsetCategoriaEventi.toString(), 'utf8').toString('base64'),
        querynextrows: Buffer.from(this.state.risultatiPerPaginaCategoriaEventi.toString(), 'utf8').toString('base64'),
        listavaloricondizioni: null,
        contenutiindicizzati: Buffer.from("false", 'utf8').toString('base64'),
        idcamposelectcustom: null
      };
  
      const data = {
        token: this.props.loginToken,
        method: Buffer.from('getdatitabellaricerca', 'utf8').toString('base64'),
        param: parametriserver,
      };
  
      const loginErrorFn = () => {
        this.props.effettuaLogout();
      }
  
      axios.post(globals.wsURLCliente, data)
        .then(function (response) {
  
          if (response.data.result.success === true) {
            // handle success
            const dati = JSON.parse(response.data.data);
  
            var countdatidagraficareCategoriaEventi = dati[0]["countelementi"];
  
            var paginaCorrenteCategoriaEventi = dati[0]["numeropagina"];
  
            if (globals.debug < 3) console.log(dati);
  
            dati.shift();
  
            successFn(dati, countdatidagraficareCategoriaEventi, paginaCorrenteCategoriaEventi);
  
          } else {
            // handle error
            if (globals.debug < 3) console.log(response.data.result.message);
  
            if (globals.debug < 3) console.log(response.data.result);
  
            if (parseInt(response.data.result.code) === 401) {
              loginErrorFn();
            } else {
              errorFn(response.data.result.message);
            }
          }
        })
        .catch(function (error) {
          // handle error
          if (globals.debug < 3) console.log("chiamata eventi record admin error: " + error);
          errorFn(error.message);
        });
  
  
      return;
    }
  
    setRichiedentiResultsCategoriaEventi = (data, countdatidagraficareCategoriaEventi, paginaCorrenteCategoriaEventi) => {
  
      this.setState({
        risultatiRicercaCategoriaEventi: data,
        messaggioErroreCategoriaEventi: null,
        paginaCorrenteCategoriaEventi: paginaCorrenteCategoriaEventi,
        ricercaEseguitaCategoriaEventi: true,
        countdatidagraficareCategoriaEventi: countdatidagraficareCategoriaEventi,
      });
    }
  
    setErrorMessageCategoriaEventi = (errorMessage) => {
      this.setState({
        messaggioErroreCategoriaEventi: errorMessage,
        ricercaEseguitaCategoriaEventi: true,
      });
    }
  
    renderRisultatiRicercaCategoriaEventi = (valid) => {
  
      const risultati = this.state.risultatiRicercaCategoriaEventi.map((obj, i) => {
  
        var checked = false;
  
        var listaselezionati = [];
  
        if (this.state.campo_eventi_categoria.campovalore !== null) {
          listaselezionati = this.state.campo_eventi_categoria.campovalore.split(',');
        }
  
        if (listaselezionati.length > 0 && listaselezionati.indexOf(obj.id) >= 0) {
          checked = true;
        }
  
  
        return (
          <div role="group" aria-label={obj.eventi_categoria + obj.id} className="form-group" key={'divinline-checkbox-' + obj.id}>
            <input
              onChange={(event) => { this.eventi_categoriaCambiatoHandler(event); }}
              name="eventi_categoria"
              type="checkbox"
              className={`form-check-input ${!valid && "is-invalid"}`}
              id={'inline-checkbox-' + obj.id}
              checked={checked}
              disabled={this.state.formreadonly}
              value={obj.id} />
            <label className="form-check-label" htmlFor={'inline-checkbox-' + obj.id}>{obj.eventi_categoria}</label>
          </div>
        );
      });
  
      return risultati;
    }
  
    // #endregion  
  

  EliminazioneFile = (successEliminaFile,
    errorEliminaFile, state, key) => {

    var nomecampo = key.replace("file_campo_", '')
    var nomefile = state[key].nome;

    nomecampo = Buffer.from(nomecampo, 'utf8').toString('base64');

    var TestoAggiornamento = (<React.Fragment>{this.state.messaggioAggiornamento}
      <div>  <strong>Cancellazione di un documento in corso si prega di attendere</strong></div></React.Fragment>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
    });

    var parametriserver = {
      nometabella: Buffer.from(this.state.nometabellacontenuto.toString(), 'utf8').toString('base64'),
      limiterecord: null,
      idulteriorecondizione: null,
      idordinamento: null,
      numeropaginazione: null,
      idselecustom: null,
      selectdistinct: null,
      usaselectwithnolock: null,
      elencocampi: null,
      queryoffset: null,
      querynextrows: null,
      listavaloricondizioni: null,
      contenutiindicizzati: null,
      idcamposelectcustom: null,
      elencotabellecorrelate: null,
      elencocampicorrelate: null,
      idcontenuto: Buffer.from(this.state.idContenutoSelezionato.toString(), 'utf8').toString('base64'),
      base64fileinputfieldname: nomecampo,
      base64fileinputfile: null,
      base64fileinputfilename: nomefile,
    };


    const data = {
      token: this.props.loginToken,
      method: Buffer.from('getdeletecontenutoallegato', 'utf8').toString('base64'),
      param: parametriserver,
    };

    const loginErrorFn = () => {
      this.props.effettuaLogout();
    }

    axios.post(globals.wsURLCliente, data)
      .then(function (response) {

        if (response.data.result.success === true) {
          successEliminaFile(key, state);

        } else {
          if (globals.debug < 3) console.log(response.data.result.message);

          if (parseInt(response.data.result.code) === 401) {
            loginErrorFn();
          } else {
            errorEliminaFile();
          }


        }
      })
      .catch(function (error) {
        if (globals.debug < 3) console.log("chiamata eventi record admin error: " + error);
        errorEliminaFile();
      });

    return;
  }

  ContenutoEliminatoOK = (key, state) => {
    var TestoAggiornamento = "";

    state[key].base64 = null;
    state[key].nome = "";
    state[key].link = "";

    this.setState({
      ricercaEseguitaContenuto: false,
      messaggioAggiornamento: TestoAggiornamento,
    }, function () {
      this.ContenutoEliminatoOKAggiornamento();
    });
  }

  ContenutoEliminatoOKAggiornamento = () => {
    this.setState({
      ricercaEseguitaContenuto: true,
    });
  }

  ContenutoEliminatoKO = () => {
    var TestoAggiornamento = (<div> <strong>Aggiornamento dei dati non possibile si prega di riprovare più tardi</strong></div>);

    this.setState({
      messaggioAggiornamento: TestoAggiornamento,
      formreadonly: false,
    });
  }

}
// #endregion    

// #region Redux
const mapStateToProps = state => {
  return {
    loginToken: state.loginToken,
    userProfile: state.userProfile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    effettuaLogout: () => dispatch(actions.effettuaLogout()),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper(
  (props) => ({
    apiKey: "AIzaSyA-a0pZN7pE4naZqWMXz-alvrUgKkaJUDc",
    libraries: ['places', 'visualization'],
    language: "it",
  })
)(EventiGestioneRecordAdmin));
