import React from 'react';

const EmailTemplate = (props) => {

  return (
    <body>
      <section
        style={{
          backgroundColor: '#1a202c',
          color: '#ffffff',
          padding: '2rem',
        }}
      >
        <h1 style={{ textAlign: 'center', textTransform: 'capitalize', marginTop: 0 }}>
          {props.subject}
        </h1>

        <article
          style={{
            backgroundColor: '#308c7a4c',
            color: '#81e6d9',
            borderRadius: '0.5rem',
            padding: '1rem',
          }}
        >
          <p style={{ margin: 0, fontSize: '1rem' }}>{props.message}</p>
        </article>
      </section>
    </body>
  )
}
export default EmailTemplate;