import React from 'react';
import { Pagination } from 'react-bootstrap';

// props.risultati: Numero di risultati della query
// props.elementiPerPagina: Numero di elementi per singola pagina
// props.paginaCorrente: Numero di pagina attuale

const Paginazione = (props) => {

    let items = null;

    if (props.risultati > props.elementiPerPagina) {
        const numeroPagine = Math.ceil(props.risultati / props.elementiPerPagina);

        switch (numeroPagine) {

            case 1:
                return (
                    <nav className="pagination-wrapper justify-content-center" aria-label={props.labelpaginazione}>
                        <Pagination>
                            <Pagination.Item active title="1" aria-label="1">1</Pagination.Item>
                        </Pagination></nav>);

            case 2:
                return (
                    <nav className="pagination-wrapper justify-content-center" aria-label={props.labelpaginazione}>
                        <Pagination>
                            <Pagination.Item title="1" aria-label="1"
                                onClick={() => { props.paginaClicked(1); }}
                                active={props.paginaCorrente === 1 ? true : false}>1</Pagination.Item>
                            <Pagination.Item title="2" aria-label="2"
                                onClick={() => { props.paginaClicked(2); }}
                                active={props.paginaCorrente === 2 ? true : false}>2</Pagination.Item>
                        </Pagination></nav>);

            case 3:
                return (<nav className="pagination-wrapper justify-content-center" aria-label={props.labelpaginazione}> <Pagination>
                    <Pagination.Item title="2" aria-label="2"
                        onClick={() => { props.paginaClicked(1); }}
                        active={props.paginaCorrente === 1 ? true : false}>1</Pagination.Item>
                    <Pagination.Item title="2" aria-label="2"
                        onClick={() => { props.paginaClicked(2); }}
                        active={props.paginaCorrente === 2 ? true : false}>2</Pagination.Item>
                    <Pagination.Item title="3" aria-label="3"
                        onClick={() => { props.paginaClicked(3); }}
                        active={props.paginaCorrente === 3 ? true : false}>3</Pagination.Item>
                </Pagination></nav>);

            default:
                let minore = null;
                let maggiore = null;
                let ellissiSX = null;
                let ellissiDX = null;
                let pagPrecedente = null;
                let pagSuccessiva = null;
                let paginaCorrente = <Pagination.Item title={props.paginaCorrente} aria-label={props.paginaCorrente} active>{props.paginaCorrente}</Pagination.Item>;

                if (props.paginaCorrente > 1) {
                    minore = <Pagination.Prev title={"Vai a pagina " + (props.paginaCorrente - 1)} aria-label={props.paginaCorrente -1 } onClick={() => { props.paginaClicked(props.paginaCorrente - 1); }} />;
                    pagPrecedente = <Pagination.Item title={"Vai a pagina " + (props.paginaCorrente - 1)} aria-label={props.paginaCorrente - 1} onClick={() => { props.paginaClicked(props.paginaCorrente - 1); }}>{props.paginaCorrente - 1}</Pagination.Item>;
                }

                if (props.paginaCorrente < numeroPagine) {
                    maggiore = <Pagination.Next title={"Vai a pagina " + (props.paginaCorrente + 1)} aria-label={props.paginaCorrente +1 } onClick={() => { props.paginaClicked(props.paginaCorrente + 1); }} />;
                    pagSuccessiva = <Pagination.Item onClick={() => { props.paginaClicked(props.paginaCorrente + 1); }} aria-label={props.paginaCorrente +1 } title={"Vai a pagina " + (props.paginaCorrente + 1)} >{props.paginaCorrente + 1}</Pagination.Item>;
                }

                if (props.paginaCorrente > 2) {
                    ellissiSX = ( 
                        <React.Fragment>
                            <Pagination.Item onClick={() => { props.paginaClicked(1); }} title={"Vai a pagina 1"} aria-label={"1"} >{1}</Pagination.Item>
                            <Pagination.Ellipsis onClick={() => { props.paginaClicked(2); }} title={"Vai a pagina 2"} aria-label={"2"}  ></Pagination.Ellipsis>
                        </React.Fragment>
                    );
                }

                if (props.paginaCorrente < numeroPagine - 1) {
                    ellissiDX = (
                        <React.Fragment>
                            <Pagination.Ellipsis onClick={() => { props.paginaClicked(numeroPagine - 1); }} title={"Vai a pagina " + (numeroPagine - 1)} aria-label={numeroPagine - 1} ></Pagination.Ellipsis>
                            <Pagination.Item onClick={() => { props.paginaClicked(numeroPagine); }} title={"Vai a pagina " + (numeroPagine)} aria-label={numeroPagine} >{numeroPagine}</Pagination.Item>
                        </React.Fragment>
                    );
                }

                items = (
                    <nav className="pagination-wrapper justify-content-center" aria-label={props.labelpaginazione}>
                        <Pagination>
                            {minore} {ellissiSX} {pagPrecedente} {paginaCorrente} {pagSuccessiva} {ellissiDX} {maggiore}
                        </Pagination></nav>
                );

                return items;
        }
    } else {
        return null;
    }

}

export default Paginazione;